<div
  [class.composer--internal-message-mode]="composer.messageType === 'internal'"
  [class.composer--overlay-presentation]="composer.presentation !== 'inline'"
  class="composer"
>
  @if (composer.presentation !== 'inline') {
    <cc-composer-header
      [composer]="composer"
      class="composer__header"
    />
  }

  <div
    [@minimizeAnimation]="composer.presentation"
    [attr.inert]="disabled || null"
    [class.composer--collapsible-container--disabled]="disabled"
    [class.composer--collapsible-container--overlay-collapsed]="composer.presentation === 'overlay-collapsed'"
    [class.composer--collapsible-container--overlay-expanded]="composer.presentation === 'overlay-expanded'"
    class="composer--collapsible-container"
  >
    <cc-composer-message-type-selector
      [allowedMessageTypes]="allowedMessageTypes$ | async"
      [composer]="composer"
      [forceAllowOnlyInternal]="!currentUserIsAssignee"
      [replyForbidden]="composer.composerType === 'linkedEmail' && composer.replyForbidden"
      class="composer__message-type-selector"
    />

    <div class="composer__divider"></div>

    @if (composer.composerType === 'linkedEmail') {
      <cc-composer-reply-to-message-preview
        (jumpToReplyToMessage)="jumpToMessage(composer.replyToMessage)"
        [replyToMessage]="composer.replyToMessage"
        class="composer__reply-to-message-preview"
      />
    }

    @if (
      (composer.composerType === 'newEmail' || composer.composerType === 'linkedEmail') &&
      composer.presentation !== 'inline' &&
      composer.messageType !== 'internal'
    ) {
      <cc-composer-subject-input
        [composer]="composer"
        [subject]="composer.subject"
        class="composer__subject-input"
      />
    }

    <div class="composer__spacer"></div>

    <div
      cdkScrollable
      class="composer--scrollable-container"
    >
      <div class="composer--scrollable-container__content">
        <cc-composer-message-input
          [composer]="composer"
          [message]="composer.message"
          class="composer__message-input"
        />

        <div class="composer--inline-container">
          @if (
            (composer.composerType === 'newEmail' || composer.composerType === 'linkedEmail') &&
            composer.messageType !== 'internal'
          ) {
            <cc-composer-signature-input
              [composer]="composer"
              [signature]="composer.signature"
              class="composer__signature-input"
            />
          }

          @if (
            composer.composerType === 'linkedEmail' &&
            (composer.messageType === 'reply' ||
              composer.messageType === 'replyAll' ||
              composer.messageType === 'forward')
          ) {
            <cc-composer-quote-input
              [composer]="composer"
              [quote]="composer.quote"
              class="composer__quote-input"
            />
          }

          @if (composer.draft && (composer.draft.attachments.length > 0 || composer.activeFileUploads.length > 0)) {
            <cc-composer-attachments
              [activeUploads]="composer.activeFileUploads"
              [composerId]="composer.id"
              [draftAttachments]="composer.draft.attachments || []"
              class="composer__attachments"
            />
          }
        </div>
      </div>
    </div>

    <cc-composer-actions
      #actionsRef
      [composer]="composer"
      [editors]="[messageInputEditor(), signatureInputEditor()]"
      [newTaskCreationDisabled]="!currentUserIsAssignee"
      class="composer__actions"
    />
  </div>
</div>
