import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'cc-loader',
  standalone: true,
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  size = input(200);
  color = input('#dee1e3');
  animated = input(true);
}
