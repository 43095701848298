import { Injectable } from '@angular/core';
import * as DateFns from 'date-fns';

import { getBrowserTimezoneOffsetInHours } from '@core/helpers/get-browser-timezone-offset';
import { type DateOrder, type TimeRepresentation } from '@core/models/internationalSettings';

export interface FormatOptions {
  date?: DateFormat;
  time?: TimeFormat;
  dayOfWeek?: DayOfWeekFormat;
}

export interface FormatRelativeOptions {
  capitalizeFirstLetter?: boolean;
  locale?: Locale;
}

export interface FormatDistanceOptions {
  strict?: boolean;
  includeSeconds?: boolean;
  addSuffix?: boolean;
  capitalizeFirstLetter?: boolean;
  locale?: Locale;
}

type DateFormat = 'long' | 'short' | 'long-no-year' | 'short-no-year' | 'numeric';
type TimeFormat = 'default' | 'with-seconds' | 'short';
type DayOfWeekFormat = 'long' | 'short';

type DateFormatPatterns = Record<DateFormat, Record<DateOrder, string>>;
type TimeFormatPatterns = Record<TimeFormat, Record<TimeRepresentation, string>>;
type DayOfWeekFormatPatterns = Record<DayOfWeekFormat, string>;

@Injectable({
  providedIn: 'root',
})
export class DateService {
  private dateOrder: DateOrder = 'MonthDayYear';
  private timeRepresentation: TimeRepresentation = 'Hour12';
  private timeZoneOffsetHours: number | null = null;

  format(_date: string | number | Date, options: FormatOptions): string {
    const date = new Date(_date);

    const formatPattern = [
      this.getDayOfWeekFormatPattern(options.dayOfWeek),
      this.getDateFormatPattern(options.date),
      this.getTimeFormatPattern(options.time),
    ]
      .filter((pattern: string | null) => pattern !== null)
      .join(', ');

    return DateFns.format(date, formatPattern);
  }

  formatRelative(
    _date: string | number | Date,
    _baseDate: string | number | Date,
    options: FormatRelativeOptions,
  ): string {
    const date = new Date(_date);
    const baseDate = new Date(_baseDate);

    const formattedDate = DateFns.formatRelative(date, baseDate, { locale: options?.locale });

    return options?.capitalizeFirstLetter
      ? formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
      : formattedDate;
  }

  formatDistance(
    _date: string | number | Date,
    _baseDate: string | number | Date,
    options: FormatDistanceOptions,
  ): string {
    const date = new Date(_date);
    const baseDate = new Date(_baseDate);

    const formattedDate = options?.strict
      ? DateFns.formatDistanceStrict(date, baseDate, {
          addSuffix: options?.addSuffix || false,
          locale: options?.locale,
        })
      : DateFns.formatDistance(date, baseDate, {
          includeSeconds: options?.includeSeconds || false,
          addSuffix: options?.addSuffix || false,
          locale: options?.locale,
        });

    return options?.capitalizeFirstLetter
      ? formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
      : formattedDate;
  }

  /*
   * Represent the provided date from the "perspective" of the profile's timezone.
   *
   * For example, if browser timezone is UTC+2 and profile timezone is UTC-5 and the provided date is 2021-01-01T00:00,
   * then the result will be 2020-12-31T19:00
   *
   * This function should be used when formatting dates, as the UI should show dates in the profile's timezone.
   * */
  representLocalDateInProfileTimezoneDate(date: Date): Date {
    const browserTimezoneOffsetHours = getBrowserTimezoneOffsetInHours();
    const timezonesDiffHours = this.timeZoneOffsetHours - browserTimezoneOffsetHours;
    const timezonesDiffMilliseconds = timezonesDiffHours * 60 * 60 * 1000;
    return DateFns.addMilliseconds(date, timezonesDiffMilliseconds);
  }

  /*
   * Represent provided date as if it was in the profile's timezone.
   *
   * For example representDateInTimezone(new Date('2021-01-01T00:00')) will represent this date in the timezone of the profile.
   * */
  representProfileTimezoneDateInLocalDate(date: Date): Date {
    const browserTimezoneOffsetHours = getBrowserTimezoneOffsetInHours();
    const timezonesDiffHours = this.timeZoneOffsetHours - browserTimezoneOffsetHours;
    const timezonesDiffMilliseconds = timezonesDiffHours * 60 * 60 * 1000;
    return DateFns.subMilliseconds(date, timezonesDiffMilliseconds);
  }

  isSameDayInTimezone(dateLeft: Date, dateRight: Date): boolean {
    const timezonesDayLeft = this.representLocalDateInProfileTimezoneDate(dateLeft);
    const timezonesDateRight = this.representLocalDateInProfileTimezoneDate(dateRight);
    return DateFns.isSameDay(timezonesDayLeft, timezonesDateRight);
  }

  isSameMonthInTimezone(dateLeft: Date, dateRight: Date): boolean {
    const timezonesDayLeft = this.representLocalDateInProfileTimezoneDate(dateLeft);
    const timezonesDateRight = this.representLocalDateInProfileTimezoneDate(dateRight);
    return DateFns.isSameMonth(timezonesDayLeft, timezonesDateRight);
  }

  setDateOrder(dateOrder: DateOrder): void {
    this.dateOrder = dateOrder;
  }

  setTimeRepresentation(timeRepresentation: TimeRepresentation): void {
    this.timeRepresentation = timeRepresentation;
  }

  setTimeZoneOffsetHours(timeZoneOffsetHours: number): void {
    this.timeZoneOffsetHours = timeZoneOffsetHours;
  }

  private getDateFormatPattern(format: DateFormat | null): string | null {
    if (!format) return null;

    const dateFormatPatterns: DateFormatPatterns = {
      long: {
        MonthDayYear: 'MMMM do, y',
        DayMonthYear: 'do MMMM y',
        YearMonthDay: 'y MMMM do',
      },
      short: {
        MonthDayYear: 'MMM do, y',
        DayMonthYear: 'do MMM y',
        YearMonthDay: 'y MMM do',
      },
      'long-no-year': {
        MonthDayYear: 'MMMM do',
        DayMonthYear: 'do MMMM',
        YearMonthDay: 'MMMM do',
      },
      'short-no-year': {
        MonthDayYear: 'MMM do',
        DayMonthYear: 'do MMM',
        YearMonthDay: 'MMM do',
      },
      numeric: {
        MonthDayYear: 'MM/dd/y',
        DayMonthYear: 'dd/MM/y',
        YearMonthDay: 'y/MM/dd',
      },
    };

    return dateFormatPatterns[format][this.dateOrder];
  }

  private getTimeFormatPattern(format: TimeFormat | null): string | null {
    if (!format) return null;

    const timeFormatPatterns: TimeFormatPatterns = {
      default: {
        Hour12: 'h:mm a',
        Hour24: 'HH:mm',
      },
      'with-seconds': {
        Hour12: 'h:mm:ss a',
        Hour24: 'HH:mm:ss',
      },
      short: {
        Hour12: 'h:mm',
        Hour24: 'HH:mm',
      },
    };

    return timeFormatPatterns[format][this.timeRepresentation];
  }

  private getDayOfWeekFormatPattern(format: DayOfWeekFormat | null): string | null {
    if (!format) return null;

    const dayOfWeekFormatPatterns: DayOfWeekFormatPatterns = {
      long: 'EEEE',
      short: 'EEE',
    };

    return dayOfWeekFormatPatterns[format];
  }
}
