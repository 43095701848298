import type { NextPaginatedLoadingStatus } from '@clover/core/helpers/loading';
import { EMPTY_PAGING, PagingOrder, type OffsetPagingWrapper, type SortingOptions } from '@clover/core/helpers/paging';

import type { ProductsSortingProperty } from './products.service';

export interface ProductWorklist {
  id: number;
  name: string;
}

export interface Product<Metadata = ProductMetadata> {
  id: number;
  name: string;
  description: string;
  images: ProductImage[];
  vendor: ProductVendor;
  status: ProductStatus;
  access?: ProductAccessType;
  metadata: Metadata;
}

export type ProductPreview = Pick<
  Product<ProductPreviewMetadata>,
  'id' | 'name' | 'description' | 'vendor' | 'status' | 'access' | 'metadata'
> & {
  imageUrl?: string;
};

export interface ProductMetadata {
  brand?: string;
  internalItemNumber?: string;
  additionalProductId?: string;
  department?: string;
  categoryCodes?: string[];
  mpc?: string; // Manufacturer Product Code
  gpc?: ProductClassification; // Global Product Classification
  gtin?: string; // Global Trade Item Number
  packSize?: string;
  length?: ProductUnitOfMeasure<ProductDimensionsUnitOfMeasure>;
  height?: ProductUnitOfMeasure<ProductDimensionsUnitOfMeasure>;
  width?: ProductUnitOfMeasure<ProductDimensionsUnitOfMeasure>;
  temperatureMinimum?: ProductUnitOfMeasure<ProductTemperatureUnitOfMeasure>;
  temperatureMaximum?: ProductUnitOfMeasure<ProductTemperatureUnitOfMeasure>;
}

export type ProductPreviewMetadata = Pick<ProductMetadata, 'packSize' | 'brand' | 'gtin' | 'internalItemNumber'>;

export interface ProductVendor {
  companyId: number;
  companyName: string;
  internalCompanyId?: string;
  internalCompanyName?: string;
  deferredAliasId?: number;
}

export interface ProductClassification {
  segment: KeyTitle;
  family: KeyTitle;
  class: KeyTitle;
  category: KeyTitle;
}

export interface KeyTitle<KeyType = string> {
  key: KeyType;
  title: string;
  description?: string;
  sortOrder?: number;
}

export interface ProductImage {
  slotId: number;
  imageUrl: string;
  type: ProductImageType;
  modifiedAt: string;
}

export enum ProductImageType {
  Marketing = 'Marketing',
  Case = 'Case',
  OpenCase = 'OpenCase',
  InnerPack = 'InnerPack',
  Item = 'Item',
}

export enum ProductAccessType {
  Public = 'Public',
  Private = 'Private',
}

export enum ProductStatus {
  Active = 'Active',
  Deleted = 'Deleted',
}

export interface ProductUnitOfMeasure<UnitOfMeasureType = string> {
  value: number;
  unitOfMeasure: KeyTitle<UnitOfMeasureType>;
}

export enum ProductDimensionsUnitOfMeasure {
  Feet = 'Feet',
  Inches = 'Inches',
  Centimeters = 'Centimeters',
  Millimeters = 'Millimeters',
}

export enum ProductTemperatureUnitOfMeasure {
  DegreesCentigrade = 'DegreesCentigrade',
  DegreesFahrenheit = 'DegreesFahrenheit',
}

export enum ProductDeleteError {
  ProductHasTasks = 'product_has_tasks',
  ProductHasIncompleteTasks = 'product_has_incompleted_tasks',
}

export interface ProductsStateModel {
  products: OffsetPagingWrapper<ProductPreview>;
  worklists: ProductWorklist[] | undefined;
  loadingStatus: NextPaginatedLoadingStatus;
  query: string;
  worklistId: number | null | undefined; // null means "All products"
  sorting: SortingOptions<ProductsSortingProperty>;
}

export const defaultProductsState: ProductsStateModel = {
  products: EMPTY_PAGING,
  worklists: undefined,
  loadingStatus: 'void',
  query: '',
  worklistId: null,
  sorting: {
    orderBy: 'name',
    order: PagingOrder.Ascending,
  },
};
