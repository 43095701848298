import { Selector } from '@ngxs/store';

import type { FilterableTaskStatus, TaskPreview, TaskType } from '@clover/conversations-v4/tasks/tasks.model';
import type { TasksSortingProperty } from '@clover/conversations-v4/tasks/tasks.service';
import type { NextPaginatedLoadingStatus } from '@clover/core/helpers/loading';
import type { SortingOptions } from '@clover/core/helpers/paging';

import type { TasksStateModel } from './tasks-state-model';
import { TasksState } from './tasks.state';

export class TasksSelectors {
  @Selector([TasksState])
  static tasks(state: TasksStateModel): TaskPreview[] {
    return state.tasks?.data || [];
  }

  @Selector([TasksState])
  static tasksCount(state: TasksStateModel): number {
    return state.tasks?.total || 0;
  }

  @Selector([TasksState])
  static tasksLoadingStatus(state: TasksStateModel): NextPaginatedLoadingStatus {
    return state.loadingStatus;
  }

  @Selector([TasksState])
  static tasksQuery(state: TasksStateModel): string {
    return state.query;
  }

  @Selector([TasksState])
  static tasksType(state: TasksStateModel): TaskType {
    return state.type;
  }

  @Selector([TasksState])
  static tasksStatus(state: TasksStateModel): FilterableTaskStatus {
    return state.status;
  }

  @Selector([TasksState])
  static tasksSortingOptions(state: TasksStateModel): SortingOptions<TasksSortingProperty> {
    return state.sorting;
  }

  @Selector([TasksState])
  static usersIncompleteTasksCount(state: TasksStateModel): number {
    return state.usersIncompleteTasksCount;
  }
}
