import { inject, Injectable } from '@angular/core';
import { map, type Observable } from 'rxjs';

import { HttpService } from '@clover/core/services/http.service';
import { UserService } from '@clover/core/services/user.service';

import type { Conversation } from './conversations-state.model';
import { mapConversation, type ConversationResponse } from './conversations.service';

export enum CreateChannelErrorCode {
  NameAlreadyExists = 'chat_with_name_already_exists',
}

@Injectable({
  providedIn: 'root',
})
export class ChannelsService {
  private readonly http = inject(HttpService);
  private readonly userService = inject(UserService);

  createChannel(workspaceId: number, name: string, memberIds: number[]): Observable<Conversation> {
    return this.http
      .postV2<ConversationResponse>(`api/stream-conversations/chat`, {
        name,
        workspaceId,
        membersUserIds: memberIds,
      })
      .pipe(map((c) => mapConversation(c, this.userService.userProfile)));
  }
}
