// src/index.ts
var CrossEnvConfig = class {
  /**
   * Get a config value from either the environment or any registry overrides
   * @param prop
   */
  static get(prop) {
    return this.safeEnvLookup(prop);
  }
  /**
   * Set a value explicitly
   *
   * @param key
   * @param value
   */
  static set(key, value) {
    return this._overrides.set(key, value);
  }
  /**
   * Alias a key to another key if helpful. This is useful if you have different naming
   * constructs for different environments.
   *
   * @param from
   * @param to
   */
  static alias(from, to) {
    return this._aliases.set(from, to);
  }
  /**
   * Helpful if you've decided to store settings in another object and want to
   * make that available here. For example in client-side implementations you may reserve
   * a window.FLATFILE_CONFIG object to store settings.
   *
   * @param obj
   */
  static attachConfigRegistry(obj) {
    this._registry = obj;
  }
  /**
   * Use this to provide an override getter for config values. This is useful
   * if you need to dynamically look up values. Overrides will still take precedence.
   *
   * @param cb
   */
  static attachConfigFactory(cb) {
    this._factory = cb;
  }
  static reset() {
    this._overrides = /* @__PURE__ */ new Map();
    this._registry = void 0;
    this._factory = void 0;
  }
  /**
   * Internal function for traversing the possible environment sources for a value
   *
   * @param prop
   * @private
   */
  static safeEnvLookup(prop) {
    let values = [];
    if (this._overrides.get(prop)) {
      values.push(this._overrides.get(prop));
    }
    if (typeof this._registry === "object") {
      values.push(this._registry[prop]);
    }
    if (typeof this._factory === "function") {
      values.push(this._factory(prop));
    }
    if (typeof process === "object" && typeof process.env === "object") {
      values.push(process.env[prop]);
    }
    values.push(...this.checkForBrowserVariables(prop));
    const foundValue = values.find((v) => v !== void 0);
    if (foundValue !== void 0) {
      return foundValue;
    }
    const alias = this._aliases.get(prop);
    if (alias) {
      return this.safeEnvLookup(alias);
    }
    return void 0;
  }
  /**
   * Internal function for checking for stored variables in a browser-like environment
   *
   * @param prop
   * @private
   */
  static checkForBrowserVariables(prop) {
    let values = [];
    if (typeof window === "object") {
      const windowValue = window[`CROSSENV_${prop}`];
      if (windowValue !== void 0) {
        values.push(windowValue);
      }
      if (typeof sessionStorage === "object") {
        const storedValue = sessionStorage.getItem(`CROSSENV_${prop}`);
        if (storedValue !== null) {
          values.push(storedValue);
        }
      }
    }
    return values;
  }
};
CrossEnvConfig._overrides = /* @__PURE__ */ new Map();
/**
 * A map of aliases that can be used to look up config values.
 *
 * @private
 */
CrossEnvConfig._aliases = /* @__PURE__ */ new Map();
export {
  CrossEnvConfig
};
