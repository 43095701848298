import { Component, ViewEncapsulation, inject, OnInit, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { catchError, filter, map, take, takeWhile } from 'rxjs/operators';

import {
  DEFAULT_BODY_TEXT,
  DEFAULT_COLOR,
  DEFAULT_HEADER_TEXT,
  TEMPLATE_KEYS,
} from '@clover/settings/constants/landing-page-defaults';
import { type LandingPageSettings } from '@clover/settings/models/landing-page-settings';
import { CompanyLogoComponent } from '@core/components/company-logo/company-logo.component';
import { LegacyLoaderComponent } from '@core/components/loader/loader.component';
import { RichTextEditorComponent } from '@core/components/rich-text-editor/rich-text-editor.component';
import { ScrollableAreaComponent } from '@core/components/scrollable-area/scrollable-area.component';
import { type Company } from '@core/models/company';
import { type User } from '@core/models/user';
import { ModalService } from '@core/services/modal.service';
import { UserService } from '@core/services/user.service';

import { AuthService } from '../auth.service';
import { DeclineInvitationModalComponent } from './modals/decline-invitation-modal.component';

@UntilDestroy()
@Component({
  selector: 'connection-invitation-page',
  templateUrl: './connection-invitation-page.component.html',
  styleUrls: ['./connection-invitation-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ScrollableAreaComponent,
    LegacyLoaderComponent,
    CompanyLogoComponent,
    RichTextEditorComponent,
    FormsModule,
    RouterLink,
    TranslateModule,
  ],
})
export class ConnectionInvitationPageComponent implements OnInit, OnDestroy {
  private readonly authService = inject(AuthService);
  private readonly userService = inject(UserService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly modalService = inject(ModalService);
  public senderData: User;
  public senderCompanyData: Company;
  public isLoading = true;
  public mainColor: string;
  public headerText: string;
  public bodyText: string;
  public isConnectionDeclined = false;
  private invitationToken: string;
  private isAlive = true;
  private isPreview = false;

  get senderLogoUrl(): string | undefined {
    if (!this.senderData.logoUrl) return;
    const version = this.senderData.logoSettings?.modifiedAt || '';
    return `${this.senderData.logoUrl}${version ? `?v=${version}` : ''}`;
  }

  public ngOnInit(): void {
    if (this.activatedRoute.snapshot.data.preview) {
      this.isPreview = true;
      this.userService.isAuthenticated$.pipe(takeWhile((_) => this.isAlive)).subscribe((isAuth) => {
        if (isAuth === null) {
          return;
        }

        if (!isAuth) {
          this.router.navigate(['/']);
          return;
        }

        this.authService
          .getLandingPageSettings()
          .pipe(
            untilDestroyed(this),
            catchError((err) => {
              this.router.navigate(['/']);
              return err;
            }),
          )
          .subscribe((settings) => {
            this.isLoading = false;
            this.senderData = this.userService.userProfile;
            this.senderCompanyData = this.userService.userCompany;
            this.parsePageData(settings);
          });
      });
    } else {
      const params = this.activatedRoute.snapshot.queryParams;

      if (!params.invitation_token) {
        this.router.navigate(['404']);
      }

      this.authService
        .getInvitationInfo(params.invitation_token)
        .pipe(
          untilDestroyed(this),
          catchError((err) => {
            const errorCode = err?.error?.errors?.[0]?.errorCode;

            // Wait until auth status is resolved.
            // Used to determine where to navigate after error.
            return this.userService.isAuthenticated$.pipe(
              filter((isAuth) => isAuth !== null),
              take(1),
              map(() => {
                return fromPromise(this.authService.navigateAfterError(errorCode)).pipe(map(() => err));
              }),
            );
          }),
        )
        .subscribe((res) => {
          this.invitationToken = params.invitation_token;
          this.senderData = res.sender;
          this.senderCompanyData = res.senderCompany;
          this.parsePageData(res.page);
          this.isLoading = false;
        });
    }
  }

  public ngOnDestroy(): void {
    this.isAlive = false;
  }

  public signUp(): void {
    if (this.isPreview) {
      return;
    }
    this.router.navigate(['signup'], { queryParamsHandling: 'merge' });
  }

  public decline(): void {
    if (this.isPreview) {
      return;
    }

    this.modalService
      .open({
        content: DeclineInvitationModalComponent,
        inputs: {
          companyName: this.senderCompanyData.name,
          token: this.invitationToken,
        },
      })
      .result.then(() => (this.isConnectionDeclined = true))
      .catch(() => {});
  }

  private parsePageData(settings: LandingPageSettings): void {
    this.mainColor = settings?.color || DEFAULT_COLOR;
    this.headerText = settings?.header || DEFAULT_HEADER_TEXT;
    this.bodyText = settings?.body ? settings.body : JSON.stringify(DEFAULT_BODY_TEXT);

    this.bodyText = this.bodyText.replace(
      TEMPLATE_KEYS.SenderName,
      `${this.senderData.firstName} ${this.senderData.lastName}`,
    );
    this.bodyText = this.bodyText.replace(TEMPLATE_KEYS.CompanyName, `${this.senderCompanyData.name}`);

    this.bodyText = JSON.parse(this.bodyText);

    // fix for link colors inside rich text editor
    setTimeout(() => {
      const links = document.querySelectorAll('.ql-editor a');
      links.forEach((link: HTMLElement) => {
        link.style.color = this.mainColor;
      });
    }, 0);
  }
}
