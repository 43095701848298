import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, inject, output, input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
  ComposerRecipientsSelectorEditorRowComponent,
  ParticipantTransferEvent,
} from '@conversations/composer/composer-message-type-selector/composer-recipients-selector/composer-recipients-selector-editor/composer-recipients-selector-editor-row/composer-recipients-selector-editor-row.component';
import {
  EmailParticipant,
  Participants,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { CdkPortalService } from '@core/services/cdk-portal.service';
import { ToastType } from '@design/overlays/toast/toast';

@Component({
  selector: 'cc-composer-recipients-selector-editor',
  standalone: true,
  imports: [ComposerRecipientsSelectorEditorRowComponent, DragDropModule],
  templateUrl: './composer-recipients-selector-editor.component.html',
  styleUrls: ['./composer-recipients-selector-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComposerRecipientsSelectorEditorComponent {
  readonly recipients = input<Omit<Participants, 'from'>>(undefined);
  readonly contactType = input.required<'email' | 'clover'>();
  readonly recipientTypes = input<('to' | 'cc' | 'bcc')[]>(['to', 'cc', 'bcc']);

  recipientsChange = output<Omit<Participants, 'from'>>();

  private readonly portalService = inject(CdkPortalService);
  private readonly translate = inject(TranslateService);

  updateRecipients(type: 'to' | 'cc' | 'bcc', participants: EmailParticipant[]): void {
    const recipients = this.recipients();
    switch (type) {
      case 'to': {
        recipients.to = participants;
        break;
      }
      case 'cc': {
        recipients.cc = participants;
        break;
      }
      case 'bcc': {
        recipients.bcc = participants;
        break;
      }
    }

    this.recipientsChange.emit(recipients);
  }

  transferItem(event: ParticipantTransferEvent): void {
    if (event.from === event.to) return;

    const recipients = this.recipients();
    if (recipients[event.to].find((participant) => participant.email === event.participant.email)) {
      this.portalService.presentToast(
        this.translate.instant('conversations-v4.composer.recipientSelector.participantAlreadyInList'),
        ToastType.Error,
      );

      return;
    }

    recipients[event.from] = recipients[event.from].filter(
      (participant) => participant.email !== event.participant.email,
    );
    recipients[event.to] = [...recipients[event.to], event.participant];

    this.recipientsChange.emit(recipients);
  }
}
