import type { ContactType } from '@clover/conversations-v4/conversation/state/contacts/contacts.model';
import type { WorkspaceMember } from '@clover/conversations-v4/workspaces/state/workspaces/workspaces-state.model';
import type { NextPaginatedLoadingStatus } from '@clover/core/helpers/loading';
import { EMPTY_PAGING, PagingWrapper } from '@core/helpers/paging';

export interface ConversationsStateModel {
  conversations: PagingWrapper<Conversation>;
  loadingStatus: NextPaginatedLoadingStatus;
}

export const defaultConversationsState: ConversationsStateModel = {
  conversations: EMPTY_PAGING,
  loadingStatus: 'void',
};

interface BaseConversation {
  id: string;
  type: ConversationType;
  source: ConversationSource;
  workspaceId: number;
  members: WorkspaceMember[];
  createdAt: string;
  updatedAt: string;
}

interface BaseEmailConversation extends BaseConversation {
  source: ConversationSource.Email;
  externalAccountId: number;
  initialSender: ConversationPerformer;
  latestMessage: ConversationLastMessage;
  subject: string | undefined;
}

export interface EmailConversation extends BaseEmailConversation {
  type: 'existing';
  read: boolean;
  status: ConversationStatus;
  labelIds: number[];
  hasAttachments: boolean;
  prioritized: boolean;
  snoozedUntil: string | undefined;
}

export interface DraftEmailConversation extends BaseEmailConversation {
  type: 'draft';
  draftId: number;
}

export interface ChannelConversation extends BaseConversation {
  type: 'existing';
  source: ConversationSource.Chat;
  subject: string | undefined;
  read: boolean;
  latestMessage: ConversationLastMessage | undefined;
  status: ConversationStatus;
  labelIds: number[];
  hasAttachments: boolean;
  prioritized: boolean;
  snoozedUntil: string | undefined;
}

export interface DirectConversation extends BaseConversation {
  type: 'existing';
  source: ConversationSource.Direct;
  subject: string | undefined;
  read: boolean;
  latestMessage: ConversationLastMessage | undefined;
  status: ConversationStatus;
  labelIds: number[];
  hasAttachments: boolean;
  prioritized: boolean;
  snoozedUntil: string | undefined;
}

export type Conversation = EmailConversation | DraftEmailConversation | ChannelConversation | DirectConversation;
type ConversationType = 'existing' | 'draft';

export interface ConversationLastMessage {
  sender: ConversationPerformer;
  messageSnippet: string;
  draft: boolean;
  createdAt: string;
}

export interface ConversationPerformer {
  id: string;
  name: string;
  avatarUrl: string | undefined;
  type: ContactType;
}

export enum ConversationSource {
  Email = 'Email',
  Direct = 'Direct',
  Chat = 'Chat',
}

export enum ConversationStatus {
  Open = 'Open',
  Closed = 'Closed',
}
