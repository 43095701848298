import { NgClass } from '@angular/common';
import { Component, Input, ViewEncapsulation, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { EMPTY, of } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { type Address } from '@core/models/address';
import { type Company, ConnectionStatus } from '@core/models/company';
import { type ServerError } from '@core/models/serverError';
import { GeolocationService } from '@core/services/geolocation.service';
import { ModalService } from '@core/services/modal.service';
import { ToastrService } from '@core/services/toastr.service';
import { websiteValidator } from '@core/validators/website.validator';

import { RadioComponent } from '../../../../stories/forms/radio/radio.component';
import { AddressInputComponent } from '../../../core/components/address-input/address-input.component';
import { CompanyInfoComponent } from '../../../core/components/company-info/company-info.component';
import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { ExistingCompaniesSearchComponent } from '../../components/existing-companies-search/existing-companies-search.component';
import { type CompanySearchData, NetworkService } from '../../network.service';
import { InvitationSentModalComponent } from '../invitation-sent-modal/invitation-sent-modal.component';
import { PendingApprovalModalComponent } from '../pending-approval-modal/pending-approval-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-company-creation-modal',
  templateUrl: './company-creation-modal.component.html',
  styleUrls: ['./company-creation-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    FormErrorMessagesComponent,
    AddressInputComponent,
    ButtonLoadingDirective,
    RadioComponent,
    ExistingCompaniesSearchComponent,
    CompanyInfoComponent,
    TranslateModule,
  ],
})
export class CompanyCreationModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly networkService = inject(NetworkService);
  private readonly geolocationService = inject(GeolocationService);
  private readonly toastr = inject(ToastrService);
  private readonly modalService = inject(ModalService);
  private readonly router = inject(Router);
  @Input() strict = false;
  @Input() isSelecting = false;
  @Input() forceAddressLookup = false;
  @Input() connectionStatusIds: ConnectionStatus[] = [];
  @Input() nonConnectedCompanyAction: 'default' | 'connect' = 'default';

  public isLoading = false;
  public currentStep = 1;
  public steps = {
    companyCreation: 1,
    addressSelection: 2,
    companiesFound: 3,
    contactCreation: 4,
    contactFound: 5,
    addressInvalid: 6,
  };

  public companyForm: UntypedFormGroup;
  public contactForm: UntypedFormGroup;

  public foundContact: Company;
  public createdCompany: Company;

  public matchedAddress: Address;
  public enteredAddress: Address;
  public selectedAddress: Address;

  public companySearchData: CompanySearchData;

  public get maxScrollAreaHeight(): number {
    const modalHeaderHeight = document.querySelector('.modal-header').clientHeight;
    return document.body.clientHeight - modalHeaderHeight - 64;
  }

  public ngOnInit(): void {
    if (this.strict) {
      this.companyForm = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
        address: new UntypedFormControl(''),
        website: new UntypedFormControl('', [websiteValidator()]),
      });

      this.contactForm = new UntypedFormGroup({
        firstName: new UntypedFormControl('', [Validators.required]),
        lastName: new UntypedFormControl('', [Validators.required]),
        workEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
      });
    } else {
      this.companyForm = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
        alias: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
        address: new UntypedFormControl('', []),
        website: new UntypedFormControl('', [websiteValidator()]),
      });

      this.contactForm = new UntypedFormGroup({
        firstName: new UntypedFormControl('', []),
        lastName: new UntypedFormControl('', []),
        workEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
      });
    }

    if (this.strict || this.forceAddressLookup) {
      const addressField = this.companyForm.get('address');
      if (addressField) addressField.setValidators([Validators.required]);
    }
  }

  public checkAddress(): void {
    if (this.companyForm.invalid) {
      setTimeout(() => {
        const firstError = document.querySelector('.company-creation-modal_content .has-error');
        firstError.scrollIntoView();
      }, 0);
      return;
    }

    this.enteredAddress = this.companyForm.value.address;

    if (!this.enteredAddress && !this.forceAddressLookup && !this.strict) {
      this.checkExistingCompanies();
      return;
    }

    this.isLoading = true;
    this.geolocationService
      .lookupAddress(this.enteredAddress)
      .then((matchedAddress) => {
        if (!matchedAddress) {
          this.currentStep = this.steps.addressInvalid;
          this.matchedAddress = null;
          this.isLoading = false;
          return;
        }

        this.selectedAddress = matchedAddress;
        if (this.geolocationService.areAddressesEqual(this.enteredAddress, matchedAddress)) {
          this.checkExistingCompanies();
        } else {
          this.matchedAddress = matchedAddress;
          this.selectedAddress = this.matchedAddress;
          this.currentStep = this.steps.addressSelection;
          this.isLoading = false;
        }
      })
      .catch((res) => {
        this.isLoading = false;
        this.toastr.displayServerErrors(res);
      });
  }

  public finishAddressSelection(): void {
    this.companyForm.get('address').setValue({
      streetAddressLine1: this.selectedAddress.streetAddressLine1,
      streetAddressLine2: this.selectedAddress.streetAddressLine2 || '',
      city: this.selectedAddress.city,
      state: this.selectedAddress.state,
      zipCode: this.selectedAddress.zipCode,
    });
    this.checkExistingCompanies();
  }

  public checkExistingCompanies(): void {
    this.isLoading = true;
    const form = this.companyForm.value;
    const searchData: CompanySearchData = {
      name: form.name,
      website: form.website,
      address: form.address,
      connectionStatusIds: this.connectionStatusIds.length ? this.connectionStatusIds : undefined,
    };

    this.networkService
      .searchCompanies(searchData, 0, 10)
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe((res) => {
        if (res.count > 0) {
          this.companySearchData = searchData;
          this.currentStep = this.steps.companiesFound;
        } else {
          this.currentStep = this.steps.contactCreation;
        }
      });
  }

  public selectFoundCompany(company: Company): void {
    this.activeModal.close({ ...company, isDeferredCompany: false });
  }

  public ignoreFoundCompanies(): void {
    this.currentStep = this.steps.contactCreation;
  }

  public checkExistingContacts(): void {
    if (this.contactForm.invalid || this.isLoading) {
      setTimeout(() => {
        const firstError = document.querySelector('.company-creation-modal_content .has-error');
        firstError.scrollIntoView();
      }, 0);
      return;
    }
    this.isLoading = true;
    const workEmail = this.contactForm.value.workEmail;
    this.networkService
      .searchContacts({ workEmail }, 0, 10)
      .pipe(
        untilDestroyed(this),
        switchMap((res) => {
          if (res.count > 0) {
            if (!res.data[0]?.companyId) {
              this.toastr.error('companyCreation.errors.userExists', {
                email: workEmail,
              });
              return of(EMPTY);
            }

            return this.networkService.getCompany(res.data[0].companyId).pipe(
              untilDestroyed(this),
              map((company) => {
                this.foundContact = company;
                this.currentStep = this.steps.contactFound;
              }),
              catchError((err) => {
                this.toastr.displayServerErrors(err);
                return of(err);
              }),
              finalize(() => (this.isLoading = false)),
            );
          } else {
            this.createCompany();
          }
        }),
        catchError((err) => {
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  public sendInvite(): void {
    this.networkService
      .sendInvitation(this.createdCompany.id)
      .pipe(
        untilDestroyed(this),
        map(() => {
          this.createdCompany.connectionStatus = ConnectionStatus.Invited;
          this.finishCreation();
          this.modalService.open({
            content: InvitationSentModalComponent,
            inputs: {
              company: this.createdCompany,
            },
          });
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  public finishCreation(): void {
    this.createdCompany.isDeferred = !this.strict;
    if (this.strict) {
      this.activeModal.close(this.createdCompany);
    } else {
      this.activeModal.close({
        ...this.createdCompany,
        name: this.createdCompany.companyAliasName,
      });
    }
  }

  public selectFoundContact(): void {
    this.createCompany();
  }

  public setCompanyAndClose(): void {
    if (this.isSelecting) {
      this.activeModal.close(this.foundContact);
    } else {
      this.activeModal.close();
      this.router.navigate(['/network/company/' + this.foundContact.id]);
    }
  }

  public showPendingApprovalModal(): void {
    this.modalService
      .open({
        content: PendingApprovalModalComponent,
        options: {
          size: 'sm',
        },
      })
      .result.then(() => {
        this.isSelecting
          ? this.activeModal.close({
              name: this.companyForm.get('name')?.value,
            })
          : null;
      });
  }

  private createCompany(): void {
    const companyForm = this.companyForm.value;

    const observable = this.strict
      ? this.networkService.createCompanyByUser({
          company: {
            name: companyForm.name,
            websiteUrl: companyForm.website,
            address: this.selectedAddress,
          },
          contact: this.contactForm.value,
        })
      : this.networkService.createDeferredCompany({
          companyAliasName: companyForm.name,
          companyAliasId: companyForm.alias,
          websiteUrl: companyForm.website,
          address: this.companyForm.value.address,
          contact: this.contactForm.value,
        });

    this.isLoading = true;
    observable
      .pipe(
        untilDestroyed(this),
        map((createdCompany) => {
          this.createdCompany = createdCompany;
          if (this.strict) {
            this.sendInvite();
          } else {
            this.finishCreation();
            this.modalService.open({
              content: InvitationSentModalComponent,
              inputs: {
                company: {
                  name: this.createdCompany.companyAliasName,
                },
              },
            });
          }
        }),
        catchError((err) => {
          this.isLoading = false;
          if (!err.error?.errors) {
            this.toastr.error('common.errorMessages.unexpected');
            return;
          }

          err.error.errors.forEach((err: ServerError) => {
            if (err.property === 'contact.work_email') {
              const field = this.contactForm.get('workEmail');
              if (field) {
                field.setErrors({
                  ...field.errors,
                  [err.errorCode]: err.errorMessage,
                });
              }
              return;
            }

            if (err.property === 'CompanyDeferredAlias' && err.errorCode === 'entity_already_exists') {
              this.toastr.error('companyCreation.errors.tpidExists');
              return;
            }

            this.toastr.serverError(err);
          });
          return of(err);
        }),
      )
      .subscribe();
  }
}
