<div
  cdkDropListGroup
  class="composer-recipients-selector-editor"
>
  @if (recipientTypes().includes('to')) {
    <cc-composer-recipients-selector-editor-row
      (participantsUpdate)="updateRecipients('to', $event)"
      (transfer)="transferItem($event)"
      [participants]="recipients().to"
      [contactType]="contactType()"
      [dragAngDropDisabled]="recipientTypes().length === 1"
      class="composer-recipients-selector-editor__row"
      type="to"
    />
  }

  @if (recipientTypes().includes('cc')) {
    <cc-composer-recipients-selector-editor-row
      (participantsUpdate)="updateRecipients('cc', $event)"
      (transfer)="transferItem($event)"
      [participants]="recipients().cc"
      [contactType]="contactType()"
      [dragAngDropDisabled]="recipientTypes().length === 1"
      class="composer-recipients-selector-editor__row"
      type="cc"
    />
  }

  @if (recipientTypes().includes('bcc')) {
    <cc-composer-recipients-selector-editor-row
      (participantsUpdate)="updateRecipients('bcc', $event)"
      (transfer)="transferItem($event)"
      [participants]="recipients().bcc"
      [contactType]="contactType()"
      [dragAngDropDisabled]="recipientTypes().length === 1"
      class="composer-recipients-selector-editor__row"
      type="bcc"
    />
  }
</div>
