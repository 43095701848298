<cc-page-with-subnav
  [previewTriggerElementId]="'networkNavItem'"
  [subnavTemplate]="subnav"
  id="network"
>
  <router-outlet></router-outlet>
</cc-page-with-subnav>

<ng-template #subnav>
  <div class="network-subnav">
    <ng-scrollbar cdkScrollable>
      <cc-collapsible-group title="Network">
        @if (canViewConnections) {
          <cc-subnav-item
            #accountsLinkActive="routerLinkActive"
            [selected]="accountsLinkActive.isActive"
            iconClass="icon-suitcase"
            routerLink="accounts"
            routerLinkActive
            title="Accounts"
          />
        }

        @if (canViewInvites) {
          <cc-subnav-item
            #invitesLinkActive="routerLinkActive"
            [selected]="invitesLinkActive.isActive"
            iconClass="icon-invites"
            routerLink="invites"
            routerLinkActive
            title="Invites"
          />
        }

        @if (canViewLists) {
          <cc-subnav-item
            #listsLinkActive="routerLinkActive"
            [selected]="listsLinkActive.isActive"
            iconClass="icon-document-alt"
            routerLink="lists"
            routerLinkActive
            title="Lists"
          />
        }
      </cc-collapsible-group>
    </ng-scrollbar>
  </div>
</ng-template>
