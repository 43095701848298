import type { PendingConversationId } from '@clover/conversations-v4/conversation/state/conversation/conversation.helpers';
import {
  ComposerMessageType,
  ComposerPresentation,
} from '@conversations/composer/state/composers/composers-state.model';
import { SaveDraftPayload } from '@conversations/composer/state/composers/composers.service';
import {
  Draft,
  Participants,
  type ChannelConversationDetails,
  type CompactMessage,
  type DirectConversationDetails,
  type EmailConversationDetails,
} from '@conversations/conversation/state/conversation/conversation-state.model';

export class InitLinkedEmailComposer {
  static readonly type = '[Composers] Init linked email composer';

  constructor(
    public composerId: string,
    public conversation: EmailConversationDetails,
  ) {}
}

export class InitNewEmailComposer {
  static readonly type = '[Composers] Init new emails composer';

  constructor(
    public composerId: string,
    public workspaceId: number,
    public externalAccountId: number,
    public fromDraftId?: number,
  ) {}
}

export class InitChannelComposer {
  static readonly type = '[Composers] Init channel composer';

  constructor(
    public composerId: string,
    public conversation: ChannelConversationDetails,
  ) {}
}

export class InitNewDirectComposer {
  static readonly type = '[Composers] Init new direct composer';

  constructor(
    public composerId: string,
    public memberIds: number[] = [],
    public pendingConversationId: PendingConversationId,
  ) {}
}

export class InitDirectComposer {
  static readonly type = '[Composers] Init direct composer';

  constructor(
    public composerId: string,
    public conversation: DirectConversationDetails,
  ) {}
}

export class DestroyComposer {
  static readonly type = '[Composers] Destroy composer';

  constructor(public composerId: string) {}
}

export class ChangePresentation {
  static readonly type = '[Composers] Change presentation';

  constructor(
    public composerId: string,
    public presentation: ComposerPresentation,
  ) {}
}

export class ChangeSender {
  static readonly type = '[Composers] Change sender';

  constructor(
    public composerId: string,
    public workspaceId: number,
    public senderAccountId: number,
  ) {}
}

export class SaveDraft {
  static readonly type = '[Composers] Save draft';

  constructor(public composerId: string) {}
}

export class CreateDraft {
  static readonly type = '[Composers] Create draft';

  constructor(
    public composerId: string,
    public draftPayload: SaveDraftPayload,
  ) {}
}

export class UpdateDraft {
  static readonly type = '[Composers] Update draft';

  constructor(
    public composerId: string,
    public draftId: number,
    public draftPayload: SaveDraftPayload,
  ) {}
}

export class SetMessageType {
  static readonly type = '[Composers] Set message type';

  constructor(
    public composerId: string,
    public newMessageType: Exclude<ComposerMessageType, 'new'>,
    public newReplyToMessage: CompactMessage,
  ) {}
}

export class SetRecipients {
  static readonly type = '[Composers] Set recipients';

  constructor(
    public composerId: string,
    public recipients: Omit<Participants, 'from'>,
  ) {}
}

export class SetSubject {
  static readonly type = '[Composers] Set subject';

  constructor(
    public composerId: string,
    public subject: string,
  ) {}
}

export class SetMessage {
  static readonly type = '[Composers] Set message';

  constructor(
    public composerId: string,
    public message: string,
  ) {}
}

export class SetQuote {
  static readonly type = '[Composers] Set quote';

  constructor(
    public composerId: string,
    public quote: string,
  ) {}
}

export class SetSignature {
  static readonly type = '[Composers] Set signature';

  constructor(
    public composerId: string,
    public signature: string,
  ) {}
}

export class UploadFile {
  static readonly type = '[Composers] Upload file';

  constructor(
    public composerId: string,
    public file: File,
  ) {}
}

export class AttachFileFromCloverStorage {
  static readonly type = '[Composers] Attach file from clover storage';

  constructor(
    public composerId: string,
    public userFileIds: number[],
    public companyFileIds: number[],
  ) {}
}

export class RemoveActiveFileUpload {
  static readonly type = '[Composers] Remove active file upload';

  constructor(
    public composerId: string,
    public uploadId: string,
  ) {}
}

export class RemoveDraftAttachment {
  static readonly type = '[Composers] Remove draft attachment';

  constructor(
    public composerId: string,
    public attachmentId: number,
  ) {}
}

export class SendDraft {
  static readonly type = '[Composers] Send draft';

  constructor(public composerId: string) {}
}

export class ResetComposer {
  static readonly type = '[Composers] Reset composer';

  constructor(
    public composerId: string,
    public preselectedDraft: Draft | undefined = undefined,
  ) {}
}
