import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';

export function withBulletList() {
  return [
    BulletList.configure({
      HTMLAttributes: {
        style: ['margin: 0', 'padding-inline-start: 1em', 'padding-left: 1em'].join('; '),
      },
    }),
    ListItem,
  ];
}
