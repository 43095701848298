import { Component, Input, type OnInit, ViewEncapsulation, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { type Team } from '@clover/teams/models/team';
import { TeamsService } from '@clover/teams/teams.service';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { ToastrService } from '@core/services/toastr.service';

import { CheckboxComponent } from '../../../../stories/forms/checkbox/checkbox.component';
import { RadioComponent } from '../../../../stories/forms/radio/radio.component';
import { LegacyLoaderComponent } from '../../../core/components/loader/loader.component';
import { ScrollableAreaComponent } from '../../../core/components/scrollable-area/scrollable-area.component';
import { WorkflowsService } from '../../workflows.service';

const TEAMS_LOAD_LIMIT = 20;

@Component({
  selector: 'app-share-workflow-modal',
  templateUrl: './share-workflow-modal.component.html',
  styleUrls: ['./share-workflow-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ScrollableAreaComponent,
    FocusableDirective,
    RadioComponent,
    FormsModule,
    CheckboxComponent,
    LegacyLoaderComponent,
    ButtonLoadingDirective,
    TranslateModule,
  ],
})
export class ShareWorkflowModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly teamsService = inject(TeamsService);
  private readonly toastr = inject(ToastrService);
  private readonly workflowsService = inject(WorkflowsService);
  @Input() workflowId: number;
  @Input() isPublishing = false;

  public assignmentTypes = {
    teams: 'Teams',
    all: 'All',
  };

  public selectedType: string = this.assignmentTypes.all;
  public selectedTeamIds: number[] = [];
  public teams: Team[] = [];

  public totalTeams = 0;

  public isLoaded = false;
  public isLoading = false;
  public isSaving = false;

  public ngOnInit(): void {
    this.workflowsService
      .getWorkflow(this.workflowId)
      .then((res) => {
        this.selectedTeamIds = [...res.access?.teamIds];
        this.selectedType = res.access?.type || this.assignmentTypes.all;
      })
      .catch((err) => {
        this.toastr.displayServerErrors(err);
      })
      .finally(() => {
        this.loadTeams().then(() => {
          this.isLoaded = true;
        });
      });
  }

  async loadTeams(): Promise<any> {
    if (this.isLoading) {
      return;
    }

    if (this.totalTeams !== 0 && this.teams.length === this.totalTeams) {
      return;
    }

    this.isLoading = true;
    await this.teamsService
      .getTeams(this.teams.length, TEAMS_LOAD_LIMIT)
      .then((res) => {
        this.teams.push(...res.data);
        this.totalTeams = res.total;
      })
      .catch((err) => {
        this.toastr.displayServerErrors(err);
      })
      .finally(() => (this.isLoading = false));
  }

  public toggleSelection(team: Team): void {
    const isSelected = this.selectedTeamIds.includes(team.id);
    if (isSelected) {
      this.selectedTeamIds = this.selectedTeamIds.filter((id) => id !== team.id);
    } else {
      this.selectedTeamIds.push(team.id);
    }
  }

  public save(): void {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;
    this.workflowsService
      .updateWorkflowAccess(this.workflowId, {
        type: this.selectedType,
        teamIds: this.selectedType === this.assignmentTypes.all ? [] : this.selectedTeamIds,
      })
      .then(() => {
        this.activeModal.close();
      })
      .catch((err) => {
        this.toastr.displayServerErrors(err);
      })
      .finally(() => (this.isSaving = false));
  }
}
