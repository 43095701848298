import { Component, input } from '@angular/core';
import { LoaderComponent as NewLoaderComponent } from '../../../../stories/misc/loader/loader.component';

// Note: This is a legacy component. The new loader component should be used instead.

@Component({
  selector: 'loader',
  template: `<cc-loader [size]="size()" />`,
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  imports: [NewLoaderComponent],
})
export class LegacyLoaderComponent {
  size = input(100);
}
