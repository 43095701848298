<div class="composer-header">
  @if (composer.composerType === 'linkedEmail' || composer.composerType === 'newEmail') {
    @if (composer.presentation !== 'overlay-collapsed') {
      <cc-composer-sender-picker
        (senderChange)="changeSender($event)"
        [disabled]="composer.composerType !== 'newEmail'"
        [senderAccountId]="composer.senderAccountId"
      />
    } @else {
      <div class="composer-header__subject">
        @if (composer.subject) {
          {{ composer.subject }}
        } @else {
          {{
            composer.composerType === 'newEmail'
              ? ('conversations-v4.composer.header.newMessage' | translate)
              : ('conversations-v4.common.noSubject' | translate)
          }}
        }
      </div>
    }
  } @else {
    <div class="composer-header__subject">
      {{ 'conversations-v4.composer.header.newMessage' | translate }}
    </div>
  }

  <div class="composer-header__actions">
    <app-button
      (click)="togglePresentation()"
      [ccTooltip]="
        composer.presentation === 'overlay-collapsed'
          ? ('common.buttons.maximize' | translate)
          : ('common.buttons.minimize' | translate)
      "
      [size]="ButtonSize.Small"
      [type]="ButtonType.Ghost"
    >
      <i
        [class.icon-maximize]="composer.presentation === 'overlay-collapsed'"
        [class.icon-minimize]="composer.presentation === 'overlay-expanded'"
        slot="ghost-icon"
      ></i>
    </app-button>

    <app-button
      (click)="closeComposer()"
      [ccTooltip]="'common.buttons.close' | translate"
      [size]="ButtonSize.Small"
      [tooltipAlignment]="TooltipAlignment.End"
      [type]="ButtonType.Ghost"
    >
      <i
        class="icon-close"
        slot="ghost-icon"
      ></i>
    </app-button>
  </div>
</div>
