import { inject, Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { patch, removeItem } from '@ngxs/store/operators';
import { tap, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConversationService } from '@clover/conversations-v4/conversation/state/conversation/conversation.service';
import { ConversationsCategory } from '@clover/conversations-v4/conversations/categories';
import { CONVERSATIONS_BASE_URL } from '@clover/conversations-v4/routes';
import { ResetComposer } from '@conversations/composer/state/composers/composers.actions';
import { ComposersSelectors } from '@conversations/composer/state/composers/composers.selectors';
import { ConversationActionsDistributorService } from '@conversations/conversation/state/conversation/conversation-actions-distributor.service';
import {
  Draft,
  DraftMessageType,
  type DirectConversationDetails,
  type EmailMessageDraft,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { PatchConversationMessage } from '@conversations/conversation/state/conversation/conversation.actions';
import { upsertItem } from '@core/helpers/custom-state-operators';
import { ccPreventDuplicateSubscriptions } from '@core/helpers/prevent-duplicate-subscriptions';
import { HttpService } from '@core/services/http.service';

import type { NewDirectMessageComposerInstance } from './composers-state.model';

@Injectable({
  providedIn: 'root',
})
export class ComposersActionsDistributorService {
  private readonly http = inject(HttpService);
  private readonly store = inject(Store);
  private readonly conversationService = inject(ConversationService);
  private readonly conversationActionsService = inject(ConversationActionsDistributorService);

  @ccPreventDuplicateSubscriptions('first-wins')
  deleteDraft(draft: Draft) {
    return this.http
      .deleteV2(`api/stream-conversations/drafts/${draft.id}`)
      .pipe(
        tap(() => this.removeDraft(draft)),
        map(() => undefined),
      )
      .subscribe();
  }

  upsertDraft(draft: Draft): void {
    if (draft.type !== DraftMessageType.NewEmail) {
      this.conversationActionsService.handleConversationUpdate(draft.conversationId);
      if ((draft as EmailMessageDraft).replyToMessage) this.addDraftToMessage(draft as EmailMessageDraft);
    } else {
      this.conversationActionsService.handleConversationUpdate(undefined, draft.id);
    }
  }

  removeDraft(draft: Draft): void {
    if (draft.type !== DraftMessageType.NewEmail) {
      this.conversationActionsService.handleConversationUpdate(draft.conversationId);
      if ((draft as EmailMessageDraft).replyToMessage) this.removeDraftFromMessage(draft as EmailMessageDraft);
    } else {
      this.conversationActionsService.handleConversationUpdate(undefined, draft.id);
    }

    // Remove draft from composer
    const composerWithDraft = this.store.selectSnapshot(ComposersSelectors.composerByDraftId(draft.id));
    if (composerWithDraft) this.store.dispatch(new ResetComposer(composerWithDraft.id));
  }

  addDraftToMessage(draft: EmailMessageDraft): void {
    // Add draft reference to the message
    this.store.dispatch(
      new PatchConversationMessage(
        draft.replyToMessage.conversationId,
        draft.replyToMessage.id,
        patch({
          drafts: upsertItem((item) => item.id === draft.id, draft),
        }),
      ),
    );
  }

  removeDraftFromMessage(draft: EmailMessageDraft): void {
    // Remove draft reference from the message
    this.store.dispatch(
      new PatchConversationMessage(
        draft.replyToMessage.conversationId,
        draft.replyToMessage.id,
        patch({
          drafts: removeItem((item) => item.id === draft.id),
        }),
      ),
    );
  }

  sendNewDirectMessage(composer: NewDirectMessageComposerInstance): Observable<DirectConversationDetails> {
    return this.conversationService
      .createDirectConversation(
        composer.message,
        composer.participants.to.map((participant) => participant.id),
      )
      .pipe(
        tap((conversation) => {
          this.conversationActionsService.handleConversationCreation(conversation.id);

          this.store.dispatch(
            new Navigate([
              '/',
              CONVERSATIONS_BASE_URL,
              conversation.workspaceId,
              ConversationsCategory.DirectMessages,
              conversation.id,
            ]),
          );
        }),
      );
  }
}
