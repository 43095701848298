import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  input,
  computed,
  effect,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { SaveDraft, SetSubject } from '@conversations/composer/state/composers/composers.actions';

import type { ComposerInstance } from '../state/composers/composers-state.model';

@Component({
  selector: 'cc-composer-subject-input',
  standalone: true,
  templateUrl: './composer-subject-input.component.html',
  styleUrls: ['./composer-subject-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, TranslateModule],
})
export class ComposerSubjectInputComponent {
  readonly composer = input.required<ComposerInstance>();
  readonly subject = input<string>(undefined);

  protected readonly composerId = computed(() => this.composer().id);
  protected readonly draftId = computed(() => this.composer().draft?.id || undefined);

  private readonly draftId$ = toObservable(this.draftId);

  protected subjectFormControl = new FormControl<string>('');

  private readonly store = inject(Store);
  private readonly destroyRef = inject(DestroyRef);

  constructor() {
    this.initUpdateHandler();

    effect(() => {
      const subject = this.subject();

      untracked(() => {
        this.subjectFormControl.setValue(subject, { emitEvent: false });
      });
    });
  }

  private initUpdateHandler(): void {
    this.draftId$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(() =>
          this.subjectFormControl.valueChanges.pipe(
            distinctUntilChanged(),
            tap((subject) => this.store.dispatch(new SetSubject(this.composerId(), subject))),
            debounceTime(300),
            switchMap(() => this.store.dispatch(new SaveDraft(this.composerId()))),
          ),
        ),
      )
      .subscribe();
  }
}
