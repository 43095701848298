import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  output,
  input,
  computed,
  effect,
  signal,
} from '@angular/core';

import { AutoAnimateDirective } from '@clover/core/directives/auto-animate.directive';
import { ComposerRecipientsSelectorEditorComponent } from '@conversations/composer/composer-message-type-selector/composer-recipients-selector/composer-recipients-selector-editor/composer-recipients-selector-editor.component';
import { ComposerRecipientsSelectorPreviewComponent } from '@conversations/composer/composer-message-type-selector/composer-recipients-selector/composer-recipients-selector-preview/composer-recipients-selector-preview.component';
import { Participants } from '@conversations/conversation/state/conversation/conversation-state.model';

@Component({
  selector: 'cc-composer-recipients-selector',
  standalone: true,
  templateUrl: './composer-recipients-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ComposerRecipientsSelectorEditorComponent,
    ComposerRecipientsSelectorPreviewComponent,
    AutoAnimateDirective,
  ],
})
export class ComposerRecipientsSelectorComponent {
  readonly recipients = input<Omit<Participants, 'from'>, Partial<Omit<Participants, 'from'>>>(undefined, {
    transform: (participants) => ({
      to: participants.to || [],
      cc: participants.cc || [],
      bcc: participants.bcc || [],
    }),
  });
  readonly contactType = input.required<'email' | 'clover'>();
  readonly recipientTypes = input<('to' | 'cc' | 'bcc')[]>(['to', 'cc', 'bcc']);

  recipientsChange = output<Omit<Participants, 'from'>>();

  protected editorActive = signal(false);

  private readonly elementRef = inject(ElementRef);
  private readonly cdr = inject(ChangeDetectorRef);

  protected readonly hasRecipients = computed(() => {
    return this.recipients().to.length > 0 || this.recipients().cc.length > 0 || this.recipients().bcc.length > 0;
  });

  constructor() {
    effect(
      () => {
        const hasRecipients = this.hasRecipients();
        if (!hasRecipients) this.editorActive.set(true);
      },
      { allowSignalWrites: true },
    );
  }

  @HostListener('document:click', ['$event'])
  outsideClick(event: MouseEvent): void {
    const isInsideDropdown = (node: HTMLElement): boolean => {
      while (node.parentElement) {
        if (node.parentElement.classList.contains('cc-recipients-selector-dropdown')) return true;
        node = node.parentElement;
      }

      return false;
    };

    if (!this.elementRef.nativeElement.contains(event.target as Node) && !isInsideDropdown(event.target as HTMLElement))
      this.editorActive.set(false);
  }

  handleRecipientsChange(recipients: Partial<Omit<Participants, 'from'>>): void {
    this.recipientsChange.emit({
      to: recipients.to || [],
      cc: recipients.cc || [],
      bcc: recipients.bcc || [],
    });
  }
}
