import { ContactType } from '@clover/conversations-v4/conversation/state/contacts/contacts.model';
import type { PendingConversationId } from '@clover/conversations-v4/conversation/state/conversation/conversation.helpers';
import {
  ComposerMessageType,
  type ChannelComposerInstance,
  type DirectMessageComposerInstance,
  type LinkedEmailComposerInstance,
  type LinkedEmailComposerMessageType,
  type NewDirectMessageComposerInstance,
  type NewEmailComposerInstance,
} from '@conversations/composer/state/composers/composers-state.model';
import {
  Draft,
  DraftMessageType,
  EmailParticipant,
  Participants,
  MessageType,
  PendingMessage,
  type ChannelConversationDetails,
  type ChannelMessageDraft,
  type CompactMessage,
  type DirectConversationDetails,
  type DirectMessageDraft,
  type EmailConversationDetails,
  type EmailMessageDraft,
  type NewEmailDraft,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { ConversationPerformer } from '@conversations/conversations/state/conversations/conversations-state.model';
import { User } from '@core/models/user';

export const emptyEditorHtml = '<p></p>';

const getEmptyEmailParticipants = (): Omit<Participants, 'from'> => ({
  to: [],
  cc: [],
  bcc: [],
});

const getEmptyDirectParticipants = (): Pick<Participants, 'to'> => ({
  to: [],
});

export function createLinkedEmailComposerInstance(
  id: string,
  details: EmailConversationDetails,
  fromDraft?: EmailMessageDraft,
): LinkedEmailComposerInstance {
  return {
    id,
    composerType: 'linkedEmail',
    workspaceId: details.workspaceId,
    conversationId: details.id,
    messageType: (fromDraft?.type
      ? draftMessageTypeToComposerType(fromDraft.type)
      : 'internal') as LinkedEmailComposerMessageType,
    senderAccountId: details.externalAccountId,
    participants: fromDraft?.participants || getEmptyEmailParticipants(),
    subject: fromDraft?.subject || details.subject,
    message: fromDraft?.content || emptyEditorHtml,
    quote: fromDraft?.quote || emptyEditorHtml,
    signature: fromDraft?.signature || emptyEditorHtml,
    replyToMessage: fromDraft?.replyToMessage || details.lastReplyableMessage,
    replyForbidden: details.replyForbidden,
    activeFileUploads: [],
    draft: fromDraft || undefined,
    draftSaveStatus: fromDraft ? 'saved' : 'void',
    sendStatus: 'void',
    presentation: 'inline',
  };
}

export function createNewEmailComposerInstance(
  id: string,
  workspaceId: number,
  externalAccountId: number,
  draft?: NewEmailDraft,
): NewEmailComposerInstance {
  return {
    id,
    composerType: 'newEmail',
    workspaceId,
    messageType: 'new',
    senderAccountId: draft?.externalAccountId || externalAccountId,
    participants: draft?.participants || getEmptyEmailParticipants(),
    subject: draft?.subject || '',
    message: draft?.content || emptyEditorHtml,
    signature: draft?.signature || emptyEditorHtml,
    activeFileUploads: [],
    draft: draft || undefined,
    draftSaveStatus: draft ? 'saved' : 'void',
    sendStatus: 'void',
    presentation: 'overlay-expanded',
  };
}

export function createChannelComposerInstance(
  id: string,
  details: ChannelConversationDetails,
  draft?: ChannelMessageDraft,
): ChannelComposerInstance {
  return {
    id,
    composerType: 'channel',
    messageType: 'internal',
    workspaceId: details.workspaceId,
    conversationId: details.id,
    replyForbidden: details.replyForbidden,
    message: draft?.content || emptyEditorHtml,
    sendStatus: 'void',
    draft: draft || undefined,
    draftSaveStatus: draft ? 'saved' : 'void',
    activeFileUploads: [],
    presentation: 'inline',
  };
}

export function createNewDirectComposerInstance(
  id: string,
  workspaceId: number,
  pendingConversationId: PendingConversationId,
): NewDirectMessageComposerInstance {
  return {
    id,
    composerType: 'newDirect',
    messageType: 'internal',
    workspaceId: workspaceId,
    participants: getEmptyDirectParticipants(),
    replyForbidden: false,
    message: emptyEditorHtml,
    sendStatus: 'void',
    draft: undefined,
    draftSaveStatus: 'void',
    activeFileUploads: [],
    presentation: 'inline',
    pendingConversationId,
  };
}

export function createDirectComposerInstance(
  id: string,
  details: DirectConversationDetails,
  draft?: DirectMessageDraft,
): DirectMessageComposerInstance {
  return {
    id,
    composerType: 'direct',
    messageType: 'internal',
    workspaceId: details.workspaceId,
    conversationId: details.id,
    replyForbidden: details.replyForbidden,
    message: draft?.content || emptyEditorHtml,
    sendStatus: 'void',
    draft: draft || undefined,
    draftSaveStatus: draft ? 'saved' : 'void',
    activeFileUploads: [],
    presentation: 'inline',
  };
}

export function draftToPendingMessage(
  draft: Draft,
  conversationId: string,
  traceId: string,
  currentUser: User,
): PendingMessage {
  const emailSender: EmailParticipant = {
    name: currentUser.fullName,
    email: currentUser.email,
    avatarUrl: currentUser.logoUrl,
  };

  return {
    id: `pending-${traceId}`,
    conversationId,
    type: draftTypeToMessageType(draft.type),
    state: 'pending',
    content: draft.content,
    snippet: draft.content,
    quote:
      draft.type === DraftMessageType.Reply ||
      draft.type === DraftMessageType.ReplyAll ||
      draft.type === DraftMessageType.Forward
        ? draft.quote
        : '',
    sender: userToConversationPerformer(currentUser),
    participants: (draft as EmailMessageDraft | NewEmailDraft).participants && {
      ...(draft as EmailMessageDraft | NewEmailDraft).participants,
      from: emailSender,
    },
    systemMetadata: undefined,
    tasksIds: draft.tasksIds,
    attachments: draft.attachments,
    drafts: [],
    replyToMessage: (draft as EmailMessageDraft)?.replyToMessage,
    traceId,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
}

function userToConversationPerformer(user: User): ConversationPerformer {
  return {
    id: user.id.toString(),
    name: user.fullName,
    avatarUrl: user.logoUrl,
    type: ContactType.CloverUser,
  };
}

export function draftMessageTypeToComposerType(draftMessageType: DraftMessageType): ComposerMessageType {
  if (draftMessageType === DraftMessageType.NewEmail) return 'new';
  if (draftMessageType === DraftMessageType.Note) return 'internal';
  if (draftMessageType === DraftMessageType.Reply) return 'reply';
  if (draftMessageType === DraftMessageType.ReplyAll) return 'replyAll';
  if (draftMessageType === DraftMessageType.Forward) return 'forward';
}

export function composerTypeToDraftMessageType(composerMessageType: ComposerMessageType): DraftMessageType {
  switch (composerMessageType) {
    case 'internal':
      return DraftMessageType.Note;
    case 'new':
      return DraftMessageType.NewEmail;
    case 'reply':
      return DraftMessageType.Reply;
    case 'replyAll':
      return DraftMessageType.ReplyAll;
    case 'forward':
      return DraftMessageType.Forward;
  }
}

function draftTypeToMessageType(draftType: DraftMessageType): MessageType {
  switch (draftType) {
    case DraftMessageType.Note:
      return MessageType.Text;
    case DraftMessageType.NewEmail:
    case DraftMessageType.Reply:
    case DraftMessageType.ReplyAll:
    case DraftMessageType.Forward:
      return MessageType.Email;
  }
}

export function getAllowedMessageTypesByReplyToMessage(
  replyToMessage: CompactMessage | undefined,
  myEmail: string,
): Exclude<ComposerMessageType, 'new'>[] {
  const allowedMessageTypes: Exclude<ComposerMessageType, 'new'>[] = ['internal'];

  if (!replyToMessage || replyToMessage.type !== MessageType.Email) return allowedMessageTypes;

  // Reply and forward are always allowed for email messages.
  allowedMessageTypes.push('reply', 'forward');

  // For reply-all we need to check if the linked message would have multiple recipients.
  // We also need to exclude the sender from the list of recipients.
  const participants = replyToMessage.participants;

  const sender = participants.from;
  const recipients = participants.to || [];
  const carbonCopy = participants.cc || [];

  const replyAllRecipients = [sender, ...recipients, ...carbonCopy].filter((recipient) => recipient.email !== myEmail);
  if (replyAllRecipients.length > 1) allowedMessageTypes.push('replyAll');

  return allowedMessageTypes;
}

export function getInitialEmailRecipients(
  replyToMessage: CompactMessage,
  messageType: ComposerMessageType,
  myEmail: string,
): Omit<Participants, 'from'> | undefined {
  const participants = replyToMessage.participants;

  switch (messageType) {
    case 'reply':
      return {
        to: [participants.from],
        cc: [],
        bcc: [],
      };
    case 'replyAll':
      return {
        to: [participants.from],
        cc: [...(participants.to || []), ...(participants.cc || [])].filter((recipient) => recipient.email !== myEmail),
        bcc: [],
      };
    case 'new':
    case 'forward':
      return {
        to: [],
        cc: [],
        bcc: [],
      };
    case 'internal':
      return undefined;
  }
}
