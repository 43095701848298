import { ChangeDetectionStrategy, Component, computed, inject, input, signal } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { derivedAsync } from 'ngxtension/derived-async';
import { catchError, defer, of, tap } from 'rxjs';

import { WINDOW } from '@clover/core/helpers/global-objects';
import type { NextPaginatedLoadingStatus } from '@clover/core/helpers/loading';
import { Task } from '@conversations/tasks/tasks.model';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';

import { TaskResultsService } from './task-results.service';
import { ButtonComponent } from '../../../../../stories/buttons/button/button.component';

@Component({
  selector: 'cc-task-drawer-results',
  standalone: true,
  templateUrl: './task-drawer-results.component.html',
  styleUrls: ['./task-drawer-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent],
})
export class TaskDrawerResultsComponent {
  task = input.required<Task>();

  protected readonly tokenLoadingStatus = signal<Omit<NextPaginatedLoadingStatus, 'loading-next'>>('void');
  protected readonly taskId = computed(() => this.task().id);

  protected readonly ButtonSize = ButtonSize;
  protected readonly ButtonType = ButtonType;

  private readonly taskResultsService = inject(TaskResultsService);
  private readonly window = inject(WINDOW);
  private readonly router = inject(Router);
  private readonly urlSerializer = inject(UrlSerializer);

  private readonly token = derivedAsync(() => {
    const taskId = this.taskId();

    return defer(() => {
      this.tokenLoadingStatus.set('loading');
      return this.taskResultsService.getTaskShareToken(taskId);
    }).pipe(
      tap(() => this.tokenLoadingStatus.set('loaded')),
      catchError(() => {
        this.tokenLoadingStatus.set('error');
        return of('');
      }),
    );
  });

  openTaskResults(): void {
    const token = this.token();
    if (!token || this.tokenLoadingStatus() !== 'loaded') return;

    const urlTree = this.router.createUrlTree(['/task-info', this.task().id], {
      queryParams: { token },
    });
    const url = this.urlSerializer.serialize(urlTree);

    this.window.open(url, '_blank');
  }
}
