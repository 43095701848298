@if (!config.hideControls) {
  <div class="table-controls">
    <div class="d-flex">
      <div class="table-search">
        @if (!config.hideSearch) {
          <input
            [(ngModel)]="searchValue"
            placeholder="{{ 'common.controls.search.placeholder' | translate }}"
            type="text"
          />
        }
      </div>
      <div class="table-filters">
        @for (filter of config.filters; track filter) {
          <div>
            <app-select
              (valueChanged)="onFilterChange()"
              [(ngModel)]="filterValues[filter.name]"
              [options]="filter.options"
              class="table-filters-selector"
            >
            </app-select>
          </div>
        }
      </div>
      @if (config.bulkActions?.length > 0) {
        <div class="table-filters">
          <app-select
            (valueChanged)="onBulkActionClick($event)"
            [lockValue]="true"
            [options]="config.bulkActions"
            [returnFullOption]="true"
            class="table-filters-selector"
            placeholder="common.controls.select.bulkActions"
          >
          </app-select>
        </div>
      }
    </div>
    @if (config.buttons) {
      <div class="table-buttons">
        @if (withExport) {
          <button
            (click)="exportCsv()"
            class="table-buttons__export-csv"
            type="button"
          >
            <img
              alt=""
              appAssetSrc="/assets/svg/products/export-csv.svg"
            />
          </button>
        }
        @for (button of config.buttons; track button) {
          <button
            (click)="button.onClick()"
            [appBtnLoading]="button.isLoadingFunc ? button.isLoadingFunc() : false"
            [attr.disabled]="isButtonDisabled(button) ? true : null"
            [class]="button.class"
            type="button"
          >
            {{ button.label | translate }}
          </button>
        }
      </div>
    }
  </div>
}

<div
  [ngStyle]="{ 'grid-template-columns': gridColumns }"
  class="table-header"
>
  <div class="table-header-row">
    @if (config.selectable) {
      <div>
        <div
          [appFocusable]
          class="d-flex"
        >
          @if (!config.hideSelectAllBtn && !isSelectingAll) {
            <app-checkbox
              (change)="onSelectAllClick()"
              [checked]="areAllSelected"
            >
            </app-checkbox>
          }
          @if (isSelectingAll && !config.hideSelectAllBtn) {
            <div class="loader table-header_select-all-loader"></div>
          }
        </div>
      </div>
    }

    @for (column of config.columns; track column) {
      <div>
        <div
          (click)="onColumnClick(column, $event)"
          [class.clickable]="column?.sortable || column.onClick"
          [appFocusable]
          class="d-flex"
        >
          @if (column.icon) {
            <img [appAssetSrc]="column.icon" />
          }
          @if (column.label) {
            <span>{{ column.label | translate }}</span>
          }
          @if (column?.sortable) {
            <img
              [appAssetSrc]="getSortIcon(column)"
              alt="sort"
            />
          }
        </div>
      </div>
    }
  </div>
</div>

<scrollable-area
  #scrollArea
  (scrollDown)="onTableScroll()"
  [autoHeight]="autoHeight"
  [class.loading]="isLoading"
  [grow]="growHeight"
  [maxHeight]="maxHeight"
  [shrink]="shrinkHeight"
>
  @if (!hideContent && !isLoading && placeholderTemplate && entities.length === 0) {
    <div>
      <ng-container *ngTemplateOutlet="placeholderTemplate"></ng-container>
    </div>
  }

  @if (!hideContent && entities.length !== 0 && layout === TableLayout.Table) {
    <div
      [ngStyle]="{ 'grid-template-columns': gridColumns }"
      class="table-body_table-view"
    >
      @for (entity of entities; track entity) {
        <div
          (click)="onEntityClick(entity)"
          [class.selectable]="config.singleSelect"
          [class.selected]="config.singleSelect && selectedEntity === entity"
          class="table-row"
        >
          @if (config.selectable) {
            <div>
              <app-checkbox
                (change)="changeSelection(entity)"
                [checked]="isEntitySelected(entity)"
              ></app-checkbox>
            </div>
          }
          <ng-container *ngTemplateOutlet="template; context: { $implicit: entity }"></ng-container>
        </div>
      }
    </div>
  }

  @if (!hideContent && entities.length !== 0 && layout === TableLayout.Grid) {
    <div class="table-body_grid-view">
      @for (entity of entities; track entity) {
        <div
          (click)="onEntityClick(entity)"
          [class.selectable]="config.singleSelect"
          [class.selected]="config.singleSelect && selectedEntity === entity"
          class="table-grid-cell"
        >
          <ng-container *ngTemplateOutlet="template; context: { $implicit: entity }"></ng-container>
        </div>
      }
    </div>
  }

  @if (isLoading || hideContent) {
    <loader></loader>
  }
</scrollable-area>
