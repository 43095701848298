import { v4 as uuid } from 'uuid';

import { ConversationStatus } from '@clover/conversations-v4/conversations/state/conversations/conversations-state.model';
import type { WorkspaceMember } from '@clover/conversations-v4/workspaces/state/workspaces/workspaces-state.model';

import type { DirectConversationDetails } from './conversation-state.model';

export type PendingConversationId = `pending-direct-${string}`;

export function generatePendingDirectConversationId(): PendingConversationId {
  return `pending-direct-${uuid()}`;
}

export function createPendingDirectConversation(
  id: PendingConversationId,
  privateWorkspaceId: number,
  members: WorkspaceMember[],
): DirectConversationDetails {
  return {
    id: undefined,
    type: 'direct',
    subject: '',
    workspaceId: privateWorkspaceId,
    labelIds: [],
    lastMessage: null,
    lastDraft: null,
    status: ConversationStatus.Open,
    prioritized: false,
    snoozedUntil: null,
    members,
    replyForbidden: false,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    pending: 'newPending',
    pendingConversationId: id,
  };
}

export function mapExistingDirectConversationToPending(
  id: PendingConversationId,
  conversation: DirectConversationDetails,
): DirectConversationDetails {
  return {
    ...conversation,
    id: conversation.id,
    pending: 'existingPending',
    pendingConversationId: id,
  };
}
