import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  HostListener,
  inject,
  input,
  OnInit,
  output,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ContactSourceType } from '@clover/conversations-v4/conversation/state/contacts/contacts.model';
import { ContactsService } from '@clover/conversations-v4/conversation/state/contacts/contacts.service';
import { ComposerRecipientsSelectorContactChipComponent } from '@conversations/composer/composer-message-type-selector/composer-recipients-selector/composer-recipients-selector-contact-chip/composer-recipients-selector-contact-chip.component';
import { EmailParticipant } from '@conversations/conversation/state/conversation/conversation-state.model';
import { CoreModule } from '@core/core.module';
import { ResizeDirective } from '@core/directives/resize.directive';
import { generateGenericAvatar } from '@core/helpers/generateGenericAvatar';
import { DOCUMENT } from '@core/helpers/global-objects';
import { CdkPortalService } from '@core/services/cdk-portal.service';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownTextComponent } from '@design/overlays/dropdown/dropdown-text/dropdown-text.component';
import { DropdownTriggerDirective } from '@design/overlays/dropdown/dropdown-trigger.directive';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { ToastType } from '@design/overlays/toast/toast';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

export interface DropListData {
  participants: EmailParticipant[];
  type: 'to' | 'cc' | 'bcc';
}

export interface ParticipantTransferEvent {
  from: 'to' | 'cc' | 'bcc';
  to: 'to' | 'cc' | 'bcc';
  participant: EmailParticipant;
}

const EMAIL_SEPARATORS = [','];

@UntilDestroy()
@Component({
  selector: 'cc-composer-recipients-selector-editor-row',
  standalone: true,
  templateUrl: './composer-recipients-selector-editor-row.component.html',
  styleUrls: ['./composer-recipients-selector-editor-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ComposerRecipientsSelectorContactChipComponent,
    ReactiveFormsModule,
    CoreModule,
    DropdownActionComponent,
    DropdownComponent,
    OverlayModule,
    DropdownTextComponent,
    DragDropModule,
    TooltipDirective,
    ResizeDirective,
    TranslateModule,
    DropdownTriggerDirective,
  ],
  animations: [
    trigger('contactChipLeaveAnimation', [
      transition(':leave', [
        style({
          width: '*',
          opacity: 1,
        }),
        animate(
          '200ms ease-in-out',
          style({
            width: 0,
            opacity: 0,
          }),
        ),
      ]),
    ]),
  ],
})
export class ComposerRecipientsSelectorEditorRowComponent implements OnInit {
  readonly type = input<'to' | 'cc' | 'bcc'>(undefined);
  readonly participants = input<EmailParticipant[]>(undefined);
  readonly contactType = input.required<'email' | 'clover'>();
  readonly dragAngDropDisabled = input<boolean>(false);

  participantsUpdate = output<EmailParticipant[]>();
  transfer = output<ParticipantTransferEvent>();

  readonly inputField = viewChild('inputField', { read: ElementRef });
  readonly dropdownTrigger = viewChild('dropdownTrigger', { read: DropdownTriggerDirective });

  inputFormControl = new FormControl<string>('');
  suggestions = signal<EmailParticipant[]>([]);
  dragging = false;
  inputFocused = signal(false);

  protected inputContentWidth = 0;
  protected allowNextLeaveAnimation = false;

  protected readonly TooltipAlignment = TooltipAlignment;

  private readonly contactsService = inject(ContactsService);
  private readonly elementRef = inject(ElementRef);
  private readonly document = inject(DOCUMENT);
  private readonly portalService = inject(CdkPortalService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly translate = inject(TranslateService);

  displayDropdown = computed(() => this.inputFocused() && this.suggestions().length > 0);
  query = toSignal(this.inputFormControl.valueChanges.pipe(map((value) => value.trim())), {
    initialValue: this.inputFormControl.value,
  });

  get placeholder(): string {
    if (this.participants().length > 0 && !(this.participants().length === 1 && this.dragging)) return '';

    switch (this.type()) {
      case 'to': {
        return this.translate.instant('conversations-v4.composer.recipientSelector.toPlaceholder');
      }
      case 'cc': {
        return this.translate.instant('conversations-v4.composer.recipientSelector.ccPlaceholder');
      }
      case 'bcc': {
        return this.translate.instant('conversations-v4.composer.recipientSelector.bccPlaceholder');
      }
    }
  }

  get focusWithin(): boolean {
    return this.elementRef.nativeElement.contains(this.document.activeElement);
  }

  get dropListData(): DropListData {
    return { participants: this.participants(), type: this.type() };
  }

  constructor() {
    effect(() => {
      const displayDropdown = this.displayDropdown();

      // Trigger this effect on query change.
      // This is used to handle cases when user closes dropdown using ESC key.
      // In such cases, dropdown should be reopened when user starts typing again.
      this.query();

      untracked(() => {
        const dropdown = this.dropdownTrigger();
        displayDropdown ? dropdown.open() : dropdown.close();
      });
    });
  }

  ngOnInit(): void {
    if (this.type() === 'to') this.focusInput();

    this.inputFormControl.valueChanges
      .pipe(
        untilDestroyed(this),
        map((value) => value.trim()),
        switchMap((value) => {
          if (!value || value.length < 2) return of([] as EmailParticipant[]);

          return this.contactsService.searchContactsByQuery(
            value,
            50,
            this.contactType() === 'clover' && ContactSourceType.CloverUser,
          );
        }),
        map((contacts) =>
          contacts
            .map(
              (c) => ({ id: c.id, name: c.name, email: c.emails[0].email, avatarUrl: c.avatarUrl }) as EmailParticipant,
            )
            .filter((contact) => !this.participants().some((participant) => participant.id === contact.id))
            .filter((contact) => !this.participants().some((participant) => participant.email === contact.email)),
        ),
      )
      .subscribe((suggestions) => {
        this.suggestions.set(suggestions);
      });
  }

  @HostListener('click')
  focusInput() {
    this.inputField().nativeElement.focus();
  }

  @HostListener('keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent): void {
    if (!this.focusWithin) return;

    if (this.inputFocused()) {
      if (EMAIL_SEPARATORS.includes(event.key)) {
        event.preventDefault();

        const value = this.inputFormControl.value.trim();
        if (value) this.addParticipant(value);
      }

      if (['Backspace', 'ArrowLeft'].includes(event.key)) {
        const inputElement = this.inputField().nativeElement;

        const selectionStart = inputElement.selectionStart;
        const selectionEnd = inputElement.selectionEnd;

        if (selectionStart !== 0 || selectionStart !== selectionEnd) return;

        const previousElement = inputElement.previousElementSibling as HTMLDivElement;
        if (previousElement) previousElement.focus();
      }

      return;
    }

    const activeElement = this.document.activeElement as HTMLDivElement;
    const previousElement = activeElement.previousElementSibling as HTMLDivElement;
    const nextElement = activeElement.nextElementSibling as HTMLDivElement;

    const focusPrevious = () => {
      if (!previousElement) return;
      event.preventDefault();
      previousElement.focus();
    };

    const focusNext = () => {
      if (!nextElement) return;
      event.preventDefault();
      nextElement.focus();

      if (nextElement.tagName === 'INPUT') {
        const inputElement = nextElement as HTMLInputElement;
        inputElement.setSelectionRange(0, 0);
      }
    };

    if (event.key === 'ArrowLeft') return focusPrevious();
    if (event.key === 'ArrowRight') return focusNext();

    const index = activeElement.dataset['index'] ? Number(activeElement.dataset['index']) : -1;
    if (index === -1) return;

    if (event.key === 'Backspace') {
      this.removeParticipant(index);

      if (previousElement) return focusPrevious();
      if (nextElement) return focusNext();
    }

    if (event.key === 'Delete') {
      this.removeParticipant(index);

      if (nextElement) return focusNext();
      if (previousElement) return focusPrevious();
    }
  }

  addParticipant(email: string): void {
    if (this.contactType() !== 'email') return;

    const participants = this.participants();

    if (participants.some((participant) => participant.email === email)) {
      this.portalService.presentToast(
        this.translate.instant('conversations-v4.composer.recipientSelector.participantAlreadyInList'),
        ToastType.Error,
      );
      this.inputFormControl.setValue('');
      return;
    }

    const fromSuggestion = this.suggestions().find((suggestion) => suggestion.email === email);
    if (fromSuggestion) return this.addParticipantFromSuggestion(fromSuggestion);

    participants.push({ name: undefined, email, avatarUrl: undefined });
    this.participantsUpdate.emit(participants);
    this.inputFormControl.setValue('');
  }

  addParticipantFromSuggestion(contact: EmailParticipant): void {
    this.focusInput();
    this.participants().push(contact);
    this.participantsUpdate.emit(this.participants());
    this.inputFormControl.setValue('');
  }

  addPendingParticipant(): void {
    if (this.contactType() !== 'email') return;

    const value = this.inputFormControl.value.trim();
    if (value) this.addParticipant(value);
  }

  editParticipant(index: number): void {
    const participant = this.participants()[index];
    this.participants().splice(index, 1);
    this.inputFormControl.setValue(participant.email);
    this.focusInput();
  }

  removeParticipant(index: number): void {
    this.allowNextLeaveAnimation = true;
    this.cdr.detectChanges();
    this.participants().splice(index, 1);
    this.participantsUpdate.emit(this.participants());
  }

  handleRecipientDrop(event: CdkDragDrop<DropListData, DropListData, EmailParticipant>): void {
    const participant = event.item.data;
    const from = event.previousContainer.data.type;
    const to = event.container.data.type;

    this.transfer.emit({ participant, from, to });
  }

  getGenericAvatarUrl(suggestion: EmailParticipant): string {
    const nameTokens = suggestion.name?.trim()?.split(' ') || [];
    const firstName = nameTokens[0] || '—';
    const lastName = nameTokens[1] || '';

    return generateGenericAvatar(firstName, lastName, 64);
  }
}
