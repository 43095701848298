import { type Observable } from 'rxjs';

import { type SelectOption } from '../components/select/select.component';

export interface TableConfig {
  filters?: TableFilter[];
  bodyParamsFunc?: () => any;
  method?: 'POST' | 'GET';
  buttons?: TableButton[];
  columns: TableColumnConfig[];
  searchValue?: string;
  loadUrl?: string;
  loadLimit?: number;
  defaultSort?: string;
  defaultSortDirection?: string;
  layout?: TableLayout;
  hideControls?: boolean;
  hideSearch?: boolean;
  searchParamConfig?: TableSearchParamConfig;
  selectable?: boolean;
  hideSelectAllBtn?: boolean;
  singleSelect?: boolean;
  selectAllFunc?: (filtersString: string) => Observable<number[]>;
  bulkActions?: TableBulkAction[];
  compareFunc?: (ent1: any, ent2: any) => boolean;
  manualSearchBy?: string;
  tableExportType?: TableFeatureType;
}

export enum TableFeatureType {
  Tasks = 'Tasks',
  Campaigns = 'Campaigns',
  Workflows = 'Workflows',
}

export interface TableButton {
  label: string;
  class?: string;
  onClick: any;
  isDisabledFunc?: any;
  isLoadingFunc?: any;
}

export interface TableSearchParamConfig {
  name: string;
  isBodyParam?: boolean;
}

export interface TableBulkAction {
  label: string;
  value: any;
  disabled?: boolean;
  callback: () => any;
}

export interface TableFilter {
  name: string;
  queryParam?: string;
  options: SelectOption[];
  defaultValue?: any;
  toStringFunc?: (val: any) => string; // custom query param builder function
  useInBody?: boolean;
}
export interface TableColumnConfig {
  name: string;
  label?: string;
  icon?: string;
  onClick?: any;
  sortable?: boolean;
  minWidth?: string;
  maxWidth?: string;
  manualSortValueFunc?: (val: any) => string;
}

export enum TableLayout {
  Grid = 'grid',
  Table = 'table',
}
