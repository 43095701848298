import { HttpEventType } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { append, insertItem, patch, removeItem, updateItem, type StateOperator } from '@ngxs/store/operators';
import { defer, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { SignaturesService } from '@clover/conversations-v4/workspaces/state/signatures/signatures.service';
import { upsertItem } from '@clover/core/helpers/custom-state-operators';
import { ComposersActionsDistributorService } from '@conversations/composer/state/composers/composers-actions-distributor.service';
import {
  composerTypeToDraftMessageType,
  createChannelComposerInstance,
  createDirectComposerInstance,
  createLinkedEmailComposerInstance,
  createNewDirectComposerInstance,
  createNewEmailComposerInstance,
  draftToPendingMessage,
  emptyEditorHtml,
  getInitialEmailRecipients,
} from '@conversations/composer/state/composers/composers-state.helpers';
import {
  ComposersStateModel,
  defaultComposersState,
  FileUploadError,
  type ComposerMessageType,
} from '@conversations/composer/state/composers/composers-state.model';
import {
  AttachFileFromCloverStorage,
  ChangePresentation,
  ChangeSender,
  CreateDraft,
  DestroyComposer,
  InitChannelComposer,
  InitDirectComposer,
  InitLinkedEmailComposer,
  InitNewDirectComposer,
  InitNewEmailComposer,
  RemoveActiveFileUpload,
  RemoveDraftAttachment,
  ResetComposer,
  SaveDraft,
  SendDraft,
  SetMessage,
  SetMessageType,
  SetQuote,
  SetRecipients,
  SetSignature,
  SetSubject,
  UpdateDraft,
  UploadFile,
} from '@conversations/composer/state/composers/composers.actions';
import { ComposersSelectors } from '@conversations/composer/state/composers/composers.selectors';
import {
  ComposersService,
  QuoteType,
  SaveDraftPayload,
} from '@conversations/composer/state/composers/composers.service';
import {
  Draft,
  type ChannelMessageDraft,
  type EmailMessageDraft,
  type NewEmailDraft,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import {
  AddPendingMessage,
  LoadPendingDirectConversation,
} from '@conversations/conversation/state/conversation/conversation.actions';
import { ConversationSelectors } from '@conversations/conversation/state/conversation/conversation.selectors';
import {
  DraftAttachmentResponse,
  mapDraftAttachment,
} from '@conversations/conversation/state/conversation/conversation.service';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { CdkPortalService } from '@core/services/cdk-portal.service';
import { UserService } from '@core/services/user.service';
import { ToastType } from '@design/overlays/toast/toast';

import { SignatureType } from './../../../workspaces/state/signatures/signatures-state.model';

@State<ComposersStateModel>({
  name: 'composers',
  defaults: defaultComposersState,
})
@Injectable()
export class ComposersState {
  private readonly store = inject(Store);
  private readonly composersService = inject(ComposersService);
  private readonly composersActionsService = inject(ComposersActionsDistributorService);
  private readonly signaturesService = inject(SignaturesService);
  private readonly userService = inject(UserService);
  private readonly portalService = inject(CdkPortalService);
  private readonly translate = inject(TranslateService);

  @Action(InitLinkedEmailComposer)
  initLinkedEmailComposer(
    ctx: StateContext<ComposersStateModel>,
    { composerId, conversation }: InitLinkedEmailComposer,
  ): Observable<void> {
    const state = ctx.getState();

    // Prevent creating duplicate composers for the same conversation.
    const existingComposer = state.composers.find(
      (composer) => composer.composerType === 'linkedEmail' && composer.conversationId === conversation.id,
    );
    if (existingComposer) throw new Error(`Composer for conversation ${conversation.id} already exists.`);

    // Note: we don't need to load quoted content and signature here,
    //       because by default, the composer will be in internal message mode.
    return defer(() => of(null)).pipe(
      tap(() => {
        // Creating a new composer.
        ctx.setState(
          patch<ComposersStateModel>({
            composers: upsertItem(
              (composer) => composer.id === composerId,
              createLinkedEmailComposerInstance(composerId, conversation, conversation.lastDraft || undefined),
            ),
          }),
        );
      }),
    );
  }

  @Action(InitNewEmailComposer)
  initNewEmailComposer(
    ctx: StateContext<ComposersStateModel>,
    { composerId, workspaceId, externalAccountId, fromDraftId }: InitNewEmailComposer,
  ): Observable<void> {
    return defer(() => {
      return forkJoin({
        signature: this.loadSignature(workspaceId, 'new'),
        draft: fromDraftId ? this.composersService.getDraft(fromDraftId) : of(undefined),
      });
    }).pipe(
      tap(() => this.collapseAllComposers(ctx)),
      tap(({ signature, draft }) => {
        const composer = {
          ...createNewEmailComposerInstance(composerId, workspaceId, externalAccountId, draft as NewEmailDraft),
          signature: signature ? signature : emptyEditorHtml,
        };

        ctx.setState(
          patch<ComposersStateModel>({
            composers: upsertItem((composer) => composer.id === composerId, composer),
          }),
        );
      }),
      map(() => undefined),
    );
  }

  @Action(InitChannelComposer)
  initChannelComposer(ctx: StateContext<ComposersStateModel>, { composerId, conversation }: InitChannelComposer): void {
    const state = ctx.getState();

    // Prevent creating duplicate composers for the same conversation.
    const existingComposer = state.composers.find(
      (composer) => composer.composerType === 'channel' && composer.conversationId === conversation.id,
    );
    if (existingComposer) throw new Error(`Composer for conversation ${conversation.id} already exists.`);

    // Creating a new composer.
    ctx.setState(
      patch<ComposersStateModel>({
        composers: upsertItem(
          (composer) => composer.id === composerId,
          createChannelComposerInstance(composerId, conversation, conversation.lastDraft || undefined),
        ),
      }),
    );
  }

  @Action(InitNewDirectComposer)
  initNewDirectComposer(
    ctx: StateContext<ComposersStateModel>,
    { composerId, pendingConversationId }: InitNewDirectComposer,
  ): void {
    const privateWorkspaceId = this.store.selectSnapshot(WorkspacesSelectors.privateWorkspaceId);

    // Creating a new composer.
    ctx.setState(
      patch<ComposersStateModel>({
        composers: upsertItem(
          (composer) => composer.id === composerId,
          createNewDirectComposerInstance(composerId, privateWorkspaceId, pendingConversationId),
        ),
      }),
    );
  }

  @Action(InitDirectComposer)
  initDirectComposer(ctx: StateContext<ComposersStateModel>, { composerId, conversation }: InitDirectComposer): void {
    const state = ctx.getState();

    // Prevent creating duplicate composers for the same conversation.
    const existingComposer = state.composers.find(
      (composer) => composer.composerType === 'direct' && composer.conversationId === conversation.id,
    );
    if (existingComposer) throw new Error(`Composer for conversation ${conversation.id} already exists.`);

    // Creating a new composer.
    ctx.setState(
      patch<ComposersStateModel>({
        composers: upsertItem(
          (composer) => composer.id === composerId,
          createDirectComposerInstance(composerId, conversation, conversation.lastDraft || undefined),
        ),
      }),
    );
  }

  @Action(DestroyComposer)
  destroyComposer(ctx: StateContext<ComposersStateModel>, { composerId }: DestroyComposer): void {
    ctx.setState(
      patch<ComposersStateModel>({
        composers: removeItem((composer) => composer.id === composerId),
      }),
    );
  }

  @Action(ChangePresentation)
  changePresentation(ctx: StateContext<ComposersStateModel>, { composerId, presentation }: ChangePresentation): void {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));
    const activeConversationDetails = this.store.selectSnapshot(ConversationSelectors.details);

    // If we changing the presentation of the composer, which conversation is not active, to inline, we need to destroy it.
    if (
      presentation === 'inline' &&
      composer.composerType === 'linkedEmail' &&
      composer.conversationId !== activeConversationDetails?.id
    ) {
      ctx.dispatch(new DestroyComposer(composerId));
      return;
    }

    // When changing presentation to overlay-expanded, we need to collapse all other overlay composers.
    if (presentation === 'overlay-expanded') this.collapseAllComposers(ctx);

    // Finally, update the composer presentation.
    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem(
          (composer) => composer.id === composerId,
          patch({
            presentation,
          }),
        ),
      }),
    );
  }

  @Action(ChangeSender)
  changeSender(
    ctx: StateContext<ComposersStateModel>,
    { composerId, workspaceId, senderAccountId }: ChangeSender,
  ): Observable<void> {
    const state = ctx.getState();
    const composer = state.composers.find((composer) => composer.id === composerId);

    if (composer.composerType !== 'newEmail') throw new Error('Only new email composers can change sender.');

    return this.loadSignature(workspaceId, 'new').pipe(
      tap((signature) => {
        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem(
              (composer) => composer.id === composerId,
              patch({
                workspaceId,
                senderAccountId,
                signature,
              }),
            ),
          }),
        );
      }),
      switchMap(() => ctx.dispatch(new SaveDraft(composerId))),
    );
  }

  @Action(SaveDraft)
  saveDraft(ctx: StateContext<ComposersStateModel>, { composerId }: SaveDraft): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));

    if (composer.composerType === 'newDirect') return of(null);

    const existingDraftId = composer.draft?.id || undefined;

    // If user changed the replyToMessage, we need to remove the draft from the old message.
    const replyToMessageChanged =
      composer.composerType === 'linkedEmail' &&
      composer.draft &&
      composer.replyToMessage.id !== composer.draft.replyToMessage.id;

    if (replyToMessageChanged) this.composersActionsService.removeDraftFromMessage(composer.draft);

    const hasEmailCapabilities = composer.composerType === 'linkedEmail' || composer.composerType === 'newEmail';

    const draftPayload: SaveDraftPayload = {
      externalAccountId: hasEmailCapabilities ? composer.senderAccountId : undefined,
      conversationId: composer.composerType !== 'newEmail' ? composer.conversationId : undefined,
      replyToMessageId: composer.composerType === 'linkedEmail' ? composer.replyToMessage.id : undefined,
      subject: hasEmailCapabilities ? composer.subject || '' : undefined,
      content: composer.message ? composer.message.replace(/&nbsp;/g, ' ') : '',
      quotedContent: composer.composerType === 'linkedEmail' ? composer.quote || '' : undefined,
      signatureContent: hasEmailCapabilities ? composer.signature || '' : undefined,
      participants: hasEmailCapabilities ? composer.participants : undefined,
      type: composerTypeToDraftMessageType(composer.messageType),
    };

    if (existingDraftId) return ctx.dispatch(new UpdateDraft(composerId, existingDraftId, draftPayload));
    return ctx.dispatch(new CreateDraft(composerId, draftPayload));
  }

  @Action(CreateDraft)
  createDraft(ctx: StateContext<ComposersStateModel>, { composerId, draftPayload }: CreateDraft): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));

    if (composer.draftSaveStatus === 'saving') return of(null);

    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ draftSaveStatus: 'saving' })),
      }),
    );

    return this.composersService.saveDraft(undefined, draftPayload).pipe(
      tap((draft) => {
        this.composersActionsService.upsertDraft(draft);

        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem(
              (composer) => composer.id === composerId,
              patch({
                draft: draft as EmailMessageDraft & NewEmailDraft,
                draftSaveStatus: 'saved',
              }),
            ),
          }),
        );
      }),
      switchMap(() => ctx.dispatch(new SaveDraft(composerId))),
      catchError(() => {
        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem((composer) => composer.id === composerId, patch({ draftSaveStatus: 'error' })),
          }),
        );
        return of(null);
      }),
      map(() => undefined),
    );
  }

  @Action(UpdateDraft, { cancelUncompleted: true })
  updateDraft(
    ctx: StateContext<ComposersStateModel>,
    { composerId, draftId, draftPayload }: UpdateDraft,
  ): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));

    if (composer.draftSaveStatus === 'void' || !composer.draft) return of(null);

    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ draftSaveStatus: 'saving' })),
      }),
    );

    return this.composersService.saveDraft(draftId, draftPayload).pipe(
      tap((draft) => {
        this.composersActionsService.upsertDraft(draft);

        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem(
              (composer) => composer.id === composerId,
              patch({
                draft: draft as EmailMessageDraft & NewEmailDraft,
                draftSaveStatus: 'saved',
              }),
            ),
          }),
        );
      }),
      catchError(() => {
        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem((composer) => composer.id === composerId, patch({ draftSaveStatus: 'error' })),
          }),
        );
        return of(null);
      }),
      map(() => undefined),
    );
  }

  @Action(SetMessageType, { cancelUncompleted: true })
  setMessageType(
    ctx: StateContext<ComposersStateModel>,
    { composerId, newMessageType, newReplyToMessage }: SetMessageType,
  ): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));

    if (composer.composerType !== 'linkedEmail')
      throw new Error('Only linked email composers can change message type.');

    // Skip if the nothing has changed.
    if ((composer.messageType === newMessageType && composer.replyToMessage.id) === newReplyToMessage.id)
      return of(null);

    // Skip if conversation is unreplyable, and the message type is reply or reply all.
    if (composer.replyForbidden && (newMessageType === 'reply' || newMessageType === 'replyAll')) {
      this.portalService.presentToast(
        this.translate.instant('conversations-v4.composer.messageTypes.replyForbiddenNotice'),
        ToastType.Info,
      );

      return of(null);
    }

    // Determine new email participants.
    const myEmail = this.store.selectSnapshot(WorkspacesSelectors.externalAccountById(composer.senderAccountId)).email;
    const participants = getInitialEmailRecipients(newReplyToMessage, newMessageType, myEmail);

    return forkJoin({
      quote: this.loadQuotedContent(composer.senderAccountId, newReplyToMessage.id, newMessageType, {
        previousQuote: composer.quote,
        previousMessageType: composer.messageType,
      }),
      signature: this.loadSignature(composer.workspaceId, newMessageType, {
        previousSignature: composer.signature,
        previousMessageType: composer.messageType,
      }),
    }).pipe(
      tap(({ quote, signature }) => {
        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem(
              (composer) => composer.id === composerId,
              patch({
                messageType: newMessageType,
                quote,
                signature,
                participants,
                replyToMessage: newReplyToMessage,
              }),
            ),
          }),
        );
      }),
      switchMap(() => ctx.dispatch(new SaveDraft(composerId))),
    );
  }

  @Action(SetRecipients)
  setRecipients(ctx: StateContext<ComposersStateModel>, { composerId, recipients }: SetRecipients): void {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));

    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ participants: recipients })),
      }),
    );

    const activeConversationDetails = this.store.selectSnapshot(ConversationSelectors.details);
    if (
      composer.composerType === 'newDirect' &&
      activeConversationDetails?.pendingConversationId === composer.pendingConversationId
    )
      ctx.dispatch(
        new LoadPendingDirectConversation(
          composer.pendingConversationId,
          recipients.to.map((r) => r.id),
        ),
      );

    ctx.dispatch(new SaveDraft(composerId));
  }

  @Action(SetSubject)
  setSubject(ctx: StateContext<ComposersStateModel>, { composerId, subject }: SetSubject): void {
    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ subject })),
      }),
    );

    // Because subject is an input field that can be changed very frequently,
    // we don't want to save draft on every change. The same applies to the message, quote and signature fields.
    // Saving drafts for these fields should be handled in the composer components.
  }

  @Action(SetMessage)
  setMessage(ctx: StateContext<ComposersStateModel>, { composerId, message }: SetMessage): void {
    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ message })),
      }),
    );
  }

  @Action(SetQuote)
  setQuote(ctx: StateContext<ComposersStateModel>, { composerId, quote }: SetQuote): void {
    const state = ctx.getState();
    const composer = state.composers.find((composer) => composer.id === composerId);

    if (composer.composerType !== 'linkedEmail') throw new Error('Only linked email composers can set quote.');

    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ quote })),
      }),
    );
  }

  @Action(SetSignature)
  setSignature(ctx: StateContext<ComposersStateModel>, { composerId, signature }: SetSignature): void {
    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ signature })),
      }),
    );
  }

  @Action(UploadFile)
  uploadFile(ctx: StateContext<ComposersStateModel>, { composerId, file }: UploadFile): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));

    if (!composer.draft)
      return ctx
        .dispatch(new SaveDraft(composerId))
        .pipe(switchMap(() => ctx.dispatch(new UploadFile(composerId, file))));

    // Uploading the file.
    const fileId = uuid();
    const cancel$ = new Subject<void>();

    return this.composersService.uploadAttachment(composer.draft.id, file).pipe(
      tap((event) => {
        switch (event.type) {
          case HttpEventType.Sent:
            return ctx.setState(
              patch<ComposersStateModel>({
                composers: updateItem(
                  (composer) => composer.id === composerId,
                  patch({
                    activeFileUploads: insertItem({
                      id: fileId,
                      file,
                      status: 'uploading',
                      progress: 0,
                      cancel$,
                    }),
                  }),
                ),
              }),
            );

          case HttpEventType.UploadProgress:
            return ctx.setState(
              patch<ComposersStateModel>({
                composers: updateItem(
                  (composer) => composer.id === composerId,
                  patch({
                    activeFileUploads: updateItem(
                      (upload) => upload.id === fileId,
                      patch({
                        progress: event.loaded / event.total,
                      }),
                    ),
                  }),
                ),
              }),
            );

          case HttpEventType.Response:
            ctx.setState(
              patch<ComposersStateModel>({
                composers: updateItem(
                  (composer) => composer.id === composerId,
                  patch({
                    activeFileUploads: removeItem((upload) => upload.id === fileId),
                    draft: patch({
                      attachments: insertItem(mapDraftAttachment(event.body as DraftAttachmentResponse)),
                    }) as StateOperator<EmailMessageDraft> & StateOperator<NewEmailDraft>,
                  }),
                ),
              }),
            );

            return ctx.dispatch(new SaveDraft(composerId));
        }
      }),
      catchError(() => {
        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem(
              (composer) => composer.id === composerId,
              patch({
                activeFileUploads: updateItem(
                  (upload) => upload.id === fileId,
                  patch({
                    status: 'error',
                    error: FileUploadError.Unknown,
                  }),
                ),
              }),
            ),
          }),
        );
        return of(null);
      }),
      map(() => undefined),
      takeUntil(cancel$),
    );
  }

  @Action(AttachFileFromCloverStorage)
  attachFileFromCloverStorage(
    ctx: StateContext<ComposersStateModel>,
    { composerId, userFileIds, companyFileIds }: AttachFileFromCloverStorage,
  ): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));

    if (!composer.draft)
      return ctx
        .dispatch(new SaveDraft(composerId))
        .pipe(switchMap(() => ctx.dispatch(new AttachFileFromCloverStorage(composerId, userFileIds, companyFileIds))));

    return this.composersService.attachFileFromCloverStorage(composer.draft.id, userFileIds, companyFileIds).pipe(
      tap((attachments) => {
        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem(
              (composer) => composer.id === composerId,
              patch({
                draft: patch({
                  attachments: append(attachments),
                }) as StateOperator<EmailMessageDraft> & StateOperator<NewEmailDraft>,
              }),
            ),
          }),
        );

        ctx.dispatch(new SaveDraft(composerId));
      }),
      map(() => undefined),
    );
  }

  @Action(RemoveActiveFileUpload)
  removeActiveFileUpload(
    ctx: StateContext<ComposersStateModel>,
    { composerId, uploadId }: RemoveActiveFileUpload,
  ): void {
    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem(
          (composer) => composer.id === composerId,
          patch({
            activeFileUploads: removeItem((upload) => upload.id === uploadId),
          }),
        ),
      }),
    );
  }

  @Action(RemoveDraftAttachment)
  removeDraftAttachment(
    ctx: StateContext<ComposersStateModel>,
    { composerId, attachmentId }: RemoveDraftAttachment,
  ): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));

    const draft = composer.draft;
    if (!draft) return;

    return this.composersService.removeAttachment(draft.id, attachmentId).pipe(
      tap(() => {
        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem(
              (composer) => composer.id === composerId,
              patch({
                draft: patch({
                  attachments: removeItem((attachment) => attachment.id === attachmentId),
                }) as StateOperator<EmailMessageDraft> & StateOperator<NewEmailDraft>,
              }),
            ),
          }),
        );

        ctx.dispatch(new SaveDraft(composerId));
      }),
    );
  }

  @Action(SendDraft)
  sendDraft(ctx: StateContext<ComposersStateModel>, { composerId }: SendDraft): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));

    ctx.setState(
      patch<ComposersStateModel>({
        composers: updateItem((composer) => composer.id === composerId, patch({ sendStatus: 'sending' })),
      }),
    );

    return of(null).pipe(
      switchMap(() => {
        if (composer.composerType === 'newDirect')
          return this.composersActionsService.sendNewDirectMessage(composer).pipe(
            tap(() => {
              this.portalService.presentToast(
                this.translate.instant('conversations-v4.composer.toasts.internalMessageSent'),
                ToastType.Success,
              );

              ctx.dispatch(new DestroyComposer(composerId));
            }),
          );

        return defer(() => {
          if (composer.messageType === 'internal') return this.composersService.sendDraftInternalMessage(composer);
          return this.composersService.sendDraftEmail(composer);
        }).pipe(
          switchMap((traceId: string) => {
            this.portalService.presentToast(
              this.translate.instant(
                composer.messageType === 'internal'
                  ? 'conversations-v4.composer.toasts.internalMessageSent'
                  : 'conversations-v4.composer.toasts.emailSent',
              ),
              ToastType.Success,
            );

            if (composer.composerType === 'newEmail') {
              return ctx.dispatch(new DestroyComposer(composerId));
            }

            const pendingMessage = draftToPendingMessage(
              composer.draft as Draft,
              composer.conversationId,
              traceId,
              this.userService.userProfile,
            );

            return ctx
              .dispatch(new AddPendingMessage(composer.conversationId, pendingMessage))
              .pipe(tap(() => this.composersActionsService.removeDraft(composer.draft)));
          }),
        );
      }),
      tap(() => {
        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem((composer) => composer.id === composerId, patch({ sendStatus: 'void' })),
          }),
        );
      }),
      catchError(() => {
        this.portalService.presentToast(
          this.translate.instant(
            composer.messageType === 'internal'
              ? 'conversations-v4.composer.toasts.internalMessageFailed'
              : 'conversations-v4.composer.toasts.emailFailed',
          ),
          ToastType.Error,
        );

        return of(null);
      }),
      map(() => undefined),
    );
  }

  @Action(ResetComposer)
  resetComposer(
    ctx: StateContext<ComposersStateModel>,
    { composerId, preselectedDraft }: ResetComposer,
  ): Observable<void> {
    const composer = this.store.selectSnapshot(ComposersSelectors.composerById(composerId));

    const activeConversationDetails = this.store.selectSnapshot(ConversationSelectors.details);

    if (
      composer.composerType !== 'newEmail' &&
      composer.composerType !== 'newDirect' &&
      activeConversationDetails.id !== composer.conversationId
    )
      return ctx.dispatch(new DestroyComposer(composerId));

    return defer(() => {
      // The reason we reload the draft is because backend only includes the full draft response in details.lastDraft.
      // Drafts returned with a message does not have all the fields needed for the composer (e.g., attachments).
      if (preselectedDraft) return this.composersService.getDraft(preselectedDraft.id);
      return of(undefined);
    }).pipe(
      tap((draft: Draft | undefined) => {
        const emptyComposer = (() => {
          // New email
          if (composer.composerType === 'newEmail')
            return {
              ...createNewEmailComposerInstance(
                composerId,
                composer.workspaceId,
                composer.senderAccountId,
                draft as NewEmailDraft,
              ),
              presentation: composer.presentation,
            };

          // Existing email conversation
          if (composer.composerType === 'linkedEmail' && activeConversationDetails.type === 'email')
            return {
              ...createLinkedEmailComposerInstance(composerId, activeConversationDetails, draft as EmailMessageDraft),
              presentation: composer.presentation,
            };

          // Existing channel
          if (composer.composerType === 'channel' && activeConversationDetails.type === 'channel')
            return {
              ...createChannelComposerInstance(composerId, activeConversationDetails, draft as ChannelMessageDraft),
              presentation: composer.presentation,
            };

          // New direct conversation
          if (composer.composerType === 'newDirect') {
            // TODO: Implement direct composer reset
          }

          // Existing direct conversation
          if (composer.composerType === 'direct' && activeConversationDetails.type === 'direct')
            return {
              ...createDirectComposerInstance(composerId, activeConversationDetails, draft as ChannelMessageDraft),
              presentation: composer.presentation,
            };

          throw new Error('Invalid composer type');
        })();

        ctx.setState(
          patch<ComposersStateModel>({
            composers: updateItem((composer) => composer.id === composerId, patch(emptyComposer)),
          }),
        );
      }),
      map(() => undefined),
    );
  }
  collapseAllComposers(ctx: StateContext<ComposersStateModel>): void {
    ctx.setState(
      patch<ComposersStateModel>({
        // This will only update the first composer that matches the condition.
        // For now it's fine, because we only have one composer expanded at a time.
        // TODO: Implement a custom `updateItems` state operator
        composers: updateItem(
          (composer) => composer.presentation === 'overlay-expanded',
          patch({ presentation: 'overlay-collapsed' }),
        ),
      }),
    );
  }

  private loadQuotedContent(
    externalAccountId: number,
    messageId: string,
    messageType: ComposerMessageType,
    previousState?: {
      previousQuote: string;
      previousMessageType: ComposerMessageType;
    },
  ): Observable<string> {
    if (!['reply', 'replyAll', 'forward'].includes(messageType)) return of('');

    const messageTypesWithIdenticalQuotedContent = ['reply', 'replyAll'];
    if (
      previousState &&
      messageTypesWithIdenticalQuotedContent.includes(previousState.previousMessageType) &&
      messageTypesWithIdenticalQuotedContent.includes(messageType)
    ) {
      return of(previousState.previousQuote);
    }

    return this.composersService.getQuotedContent(
      externalAccountId,
      messageId,
      messageType === 'forward' ? QuoteType.Forward : QuoteType.Reply,
    );
  }

  private loadSignature(
    workspaceId: number,
    messageType: ComposerMessageType,
    previousState?: {
      previousSignature: string;
      previousMessageType: ComposerMessageType;
    },
  ): Observable<string> {
    if (!['new', 'reply', 'replyAll', 'forward'].includes(messageType)) return of('');

    const messageTypesWithIdenticalSignature = ['reply', 'replyAll', 'forward'];
    if (
      previousState &&
      messageTypesWithIdenticalSignature.includes(previousState.previousMessageType) &&
      messageTypesWithIdenticalSignature.includes(messageType)
    ) {
      return of(previousState.previousSignature);
    }

    return this.signaturesService.getDefaultSignatureOfType(workspaceId, SignatureType.ReplyEmail).pipe(
      map((signature) => signature && this.composersService.getPersonalizedSignature(signature)),
      map((signature) => (signature ? signature : emptyEditorHtml)),
    );
  }
}
