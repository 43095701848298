import { type CompanySearchModel } from '@core/models/company';

export interface Invitation {
  id: number;
  status: InvitationStatus;
  requestFrom?: CompanySearchModel;
  requestTo?: CompanySearchModel;
  companyId: number;
  createdAt: string;
  repliedAt: string;
}

export enum InvitationStatus {
  Open = 'Open',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}

export enum UserInvitationSource {
  TaskCustomForm = 1,
  TaskReassignment = 2,
  CompanyProfilePage = 3,
  CompanyConnectionInvitation = 4,
  UserProfilePage = 5,
  AdminUserList = 6,
}
