import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { GLOBAL_SEARCH_ROUTER_TOKEN, globalSearchProvider } from '@core/components/global-search/global-search.token';

import { type CompanySearchModel } from '../../models/company';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [globalSearchProvider],
  imports: [FormsModule, TranslateModule, AsyncPipe],
})
export class GlobalSearchComponent {
  @Input() disabled = false;

  @ViewChild('resultsContainer') resultsContainer: ElementRef;
  @ViewChild('input') input: ElementRef;

  isLoading = false;
  showResults = false;
  readonly event = inject(GLOBAL_SEARCH_ROUTER_TOKEN);

  private readonly router = inject(Router);
  private _searchValue: string;

  get searchValue(): string {
    return this._searchValue;
  }

  set searchValue(value: string) {
    this._searchValue = value;
  }

  get dropdownHeight(): number {
    if (!this.resultsContainer) {
      return 0;
    }
    const maxHeight = window.innerHeight - 46;
    const scrollHeight = this.resultsContainer.nativeElement.scrollHeight;
    return scrollHeight <= maxHeight ? scrollHeight : maxHeight;
  }

  onEnterKeydown(): void {
    if (!this.searchValue || this.searchValue.length < 3) {
      return;
    }
    this.redirectToAdvanced();
  }

  redirectToAdvanced(): void {
    this.router.navigate(['/search'], { queryParams: { term: this.searchValue } });
    this.showResults = false;
  }

  openCompany(company: CompanySearchModel): void {
    this.router.navigate([`/network/company/${company.id}`]);
    this.showResults = false;
  }
}
