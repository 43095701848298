import { createSelector, Selector } from '@ngxs/store';

import type { LoadingStatus } from '@clover/core/helpers/loading';
import {
  ExternalAccount,
  ExternalAccountStatus,
  Workspace,
  WorkspacesStateModel,
} from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { WorkspacesState } from '@conversations/workspaces/state/workspaces/workspaces.state';
import { WorkspaceAlias } from '@conversations/workspaces/workspaces';

export class WorkspacesSelectors {
  @Selector([WorkspacesState])
  static workspaces(state: WorkspacesStateModel): Workspace[] {
    return state.workspaces;
  }

  @Selector([WorkspacesState])
  static externalAccounts(state: WorkspacesStateModel): ExternalAccount[] {
    return state.externalAccounts.filter((account) => ![ExternalAccountStatus.Disconnected].includes(account.status));
  }

  @Selector([WorkspacesState])
  static syncingExternalAccounts(state: WorkspacesStateModel): ExternalAccount[] {
    return state.externalAccounts.filter((account) =>
      [ExternalAccountStatus.Syncing, ExternalAccountStatus.Initializing].includes(account.status),
    );
  }

  @Selector([WorkspacesState])
  static privateWorkspace(state: WorkspacesStateModel): Workspace {
    return state.workspaces.find((workspace) => workspace.ownerType === 'user');
  }

  @Selector([WorkspacesSelectors.privateWorkspace])
  static privateWorkspaceId(workspace: Workspace): number {
    return workspace.id;
  }

  @Selector([WorkspacesState])
  static teamWorkspaces(state: WorkspacesStateModel): Workspace[] {
    return state.workspaces.filter((workspace) => workspace.ownerType === 'team');
  }

  @Selector([WorkspacesState])
  static loadingStatus(state: WorkspacesStateModel): LoadingStatus {
    return state.loadingStatus;
  }

  static workspaceById(workspaceId: number) {
    return createSelector([WorkspacesSelectors.workspaces], (workspaces: Workspace[]) => {
      return workspaces.find((workspace) => workspace.id === workspaceId);
    });
  }

  static externalAccountsByWorkspaceId(workspaceId: number) {
    return createSelector([WorkspacesSelectors.externalAccounts], (externalAccounts: ExternalAccount[]) => {
      return externalAccounts.filter((account) => account.workspaceId === workspaceId);
    });
  }

  static workspaceAliasById(workspaceId: number) {
    return createSelector([WorkspacesSelectors.workspaces], (workspaces: Workspace[]) => {
      const workspace = workspaces.find((workspace) => workspace.id === workspaceId);
      return workspace.ownerType === 'team' ? workspace.id.toString() : WorkspaceAlias.Private;
    });
  }

  static externalAccountById(externalAccountId: number) {
    return createSelector([WorkspacesState], (state: WorkspacesStateModel) => {
      return state.externalAccounts.find((account) => account.id === externalAccountId);
    });
  }

  static externalAccountsByStatuses(statuses: ExternalAccountStatus[]) {
    return createSelector([WorkspacesSelectors.externalAccounts], (externalAccounts: ExternalAccount[]) => {
      return externalAccounts.filter((account) => statuses.includes(account.status));
    });
  }

  static workspaceSyncingExternalAccounts(workspaceId: number) {
    return createSelector(
      [WorkspacesSelectors.syncingExternalAccounts],
      (syncingExternalAccounts: ExternalAccount[]) => {
        if (!workspaceId) return syncingExternalAccounts;
        return syncingExternalAccounts.filter((account) => account.workspaceId === workspaceId);
      },
    );
  }
}
