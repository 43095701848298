import type { WorkspaceMember } from '@clover/conversations-v4/workspaces/state/workspaces/workspaces-state.model';
import type { LoadingStatus, PaginatedLoadingStatus } from '@clover/core/helpers/loading';
import {
  ConversationPerformer,
  ConversationStatus,
} from '@conversations/conversations/state/conversations/conversations-state.model';
import { EMPTY_PAGING, PagingWrapper } from '@core/helpers/paging';

import type { PendingConversationId } from './conversation.helpers';

export interface ConversationStateModel {
  details: ConversationDetails | undefined;
  messages: PagingWrapper<Message>;
  pendingMessages: PendingMessage[];
  loadingStatus: LoadingStatus;
  messagesLoadingStatus: PaginatedLoadingStatus;
}

export const defaultConversationState: ConversationStateModel = {
  details: undefined,
  messages: EMPTY_PAGING,
  pendingMessages: [],
  loadingStatus: 'void',
  messagesLoadingStatus: 'void',
};

interface BaseConversationDetails {
  id: string;
  type: ConversationDetailsType;
  subject: string;
  workspaceId: number;
  labelIds: number[];
  lastMessage: CompactMessage;
  lastDraft: Draft | undefined;
  status: ConversationStatus;
  prioritized: boolean;
  snoozedUntil: string | undefined;
  members: WorkspaceMember[];
  replyForbidden: boolean;
  pending?: 'existingPending' | 'newPending';
  pendingConversationId?: PendingConversationId;
  createdAt: string;
  updatedAt: string;
}

export interface EmailConversationDetails extends BaseConversationDetails {
  type: 'email';
  externalAccountId: number;
  lastDraft: EmailMessageDraft | undefined;
  lastReplyableMessage: CompactMessage;
  folder: ConversationFolder;
  assignee: WorkspaceMember | undefined;
}

export interface ChannelConversationDetails extends BaseConversationDetails {
  type: 'channel';
  lastDraft: ChannelMessageDraft | undefined;
}

export interface DirectConversationDetails extends BaseConversationDetails {
  id: string | undefined;
  type: 'direct';
  lastDraft: DirectMessageDraft | undefined;
}

export type ConversationDetails = EmailConversationDetails | ChannelConversationDetails | DirectConversationDetails;
type ConversationDetailsType = 'email' | 'channel' | 'direct';

export enum ConversationFolder {
  Inbox = 'inbox',
  Archive = 'archive',
  Trash = 'trash',
  Spam = 'spam',
}

interface BaseMessage {
  id: string;
  conversationId: string;
  type: MessageType;
  state: MessageState;
  content: string;
  snippet: string;
  quote: string;
  sender: ConversationPerformer | undefined;
  participants: Participants | undefined;
  systemMetadata: SystemMetadata | undefined;
  tasksIds: number[];
  drafts: EmailMessageDraft[]; // For now, only email messages can have child drafts
  replyToMessage: CompactMessage | undefined;
  createdAt: string;
  updatedAt: string;
}

export interface Message extends BaseMessage {
  state: 'sent';
  attachments: MessageAttachment[];
}

export interface PendingMessage extends BaseMessage {
  state: 'pending';
  attachments: DraftAttachment[];
  traceId: string;
}

type MessageState = 'sent' | 'pending';

export interface Participants {
  from: EmailParticipant;
  to: EmailParticipant[];
  cc: EmailParticipant[];
  bcc: EmailParticipant[];
}

export interface SystemMetadata {
  type: SystemMessageType;
  performer: ConversationPerformer | undefined;
  assigneeUser: ConversationPerformer | undefined;
  members: ConversationPerformer[] | undefined;
}

export enum SystemMessageType {
  ConversationAssigned = 'conversation_assigned',
  ConversationUnassigned = 'conversation_unassigned',
  TeamMembersAdded = 'team_members_added',
  TeamMembersRemoved = 'team_members_removed',
}

export interface EmailParticipant {
  id?: number;
  name: string | undefined;
  email: string;
  avatarUrl: string | undefined;
}

export interface MessageAttachment {
  id: string;
  name: string;
  size: number;
  mimeType: string;
}

export interface DraftAttachment {
  id: number;
  name: string;
  size: number;
  mimeType: string;
}

interface BaseDraft {
  id: number;
  conversationId: string;
  type: DraftMessageType;
  content: string;
  tasksIds: number[];
  attachments: DraftAttachment[];
  createdAt: string;
  updatedAt: string;
}

export interface NewEmailDraft extends BaseDraft {
  externalAccountId: number;
  subject: string;
  type: DraftMessageType.NewEmail;
  signature: string;
  participants: Omit<Participants, 'from'> | undefined;
}

export interface EmailMessageDraft extends BaseDraft {
  conversationId: string;
  externalAccountId: number;
  subject: string;
  type: Extract<
    DraftMessageType,
    DraftMessageType.Note | DraftMessageType.Reply | DraftMessageType.ReplyAll | DraftMessageType.Forward
  >;
  signature: string;
  participants: Omit<Participants, 'from'> | undefined;
  quote: string;
  replyToMessage: CompactMessage;
}

export interface ChannelMessageDraft extends BaseDraft {
  type: DraftMessageType.Note;
}

export interface DirectMessageDraft extends BaseDraft {
  type: DraftMessageType.Note;
}

export type Draft = NewEmailDraft | EmailMessageDraft | ChannelMessageDraft | DirectMessageDraft;

export interface CompactMessage {
  id: string;
  conversationId: string;
  type: MessageType;
  snippet: string;
  sender: ConversationPerformer | undefined;
  participants: Participants | undefined;
  createdAt: string;
}

export interface ConversationAssignment {
  assignee: ConversationPerformer | undefined;
  availableAssignees: ConversationPerformer[];
}

export enum MessageType {
  Email = 'email',
  Text = 'text',
  System = 'system',
}

export enum DraftMessageType {
  NewEmail = 'NewEmail',
  Reply = 'Reply',
  ReplyAll = 'ReplyAll',
  Forward = 'Forward',
  Note = 'Note',
}

export interface ConversationUpdateEvent {
  workspaceId: number;
  conversationId: string;
  reason: ConversationUpdateReason;
}

export interface ConversationReadStateChangedEvent {
  conversationId: string;
  isInbox: boolean;
  isRead: boolean;
  workspaceId: number;
}

export type ConversationUpdateReason =
  | 'FullyUpdated'
  | 'LastUpdateDateChange'
  | 'StatusChanged'
  | 'RemoteStatusChanged'
  | 'SnoozeStatusChanged'
  | 'LabelsChanged'
  | 'AssigneeChanged'
  | 'MembersChanged'
  | 'PrioritizedChanged';

export interface NewConversationEvent {
  workspaceId: number;
  eventType: NewConversationEventType;
  conversationId: string;
  messageId: string;
  externalAccountId: number;
}

export enum NewConversationEventType {
  NewMessage = 'message.new',
}

export interface NewMessagePublishedEvent {
  workspaceId: number;
  conversationId: string;
  messageId: string;
  traceId: string;
}

export enum ConversationAssignmentErrorCode {
  AssigneeHasDraft = 'assignee_has_draft',
}
