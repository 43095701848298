import { Component, type OnInit, inject, DestroyRef, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { defer, from, of, type Observable } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { AutoAnimateDirective } from '@clover/core/directives/auto-animate.directive';
import { WINDOW } from '@clover/core/helpers/global-objects';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { type ServerError } from '@core/models/serverError';
import { ModalService } from '@core/services/modal.service';
import { ToastrService } from '@core/services/toastr.service';
import { UserService } from '@core/services/user.service';

import { FormErrorMessagesComponent } from '../../core/components/form-error-messages/form-error-messages.component';
import { AuthService, LoginErrorCode } from '../auth.service';
import { TfaModalComponent } from '../modals/tfa-modal/tfa-modal.component';

const SSO_REDIRECT_URI = 'login/sso/callback';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  standalone: true,
  imports: [
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    FormErrorMessagesComponent,
    RouterLink,
    ButtonLoadingDirective,
    TranslateModule,
    AutoAnimateDirective,
  ],
})
export class AuthSigninComponent implements OnInit {
  isLoading = signal(false);
  showResendEmail = signal(false);

  signInForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl(
      {
        value: '',
        disabled: true,
      },
      [Validators.required],
    ),
  });

  private readonly authService = inject(AuthService);
  private readonly modalService = inject(ModalService);
  private readonly userService = inject(UserService);
  private readonly toastr = inject(ToastrService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly window = inject(WINDOW);

  get emailControl(): FormControl<string> {
    return this.signInForm.controls.email;
  }

  get passwordControl(): FormControl<string> {
    return this.signInForm.controls.password;
  }

  get isTimedOut(): boolean {
    return this.userService.isTimedOut;
  }

  ngOnInit(): void {
    this.emailControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.showResendEmail.set(false);
      this.passwordControl.disable();
      this.passwordControl.reset();
    });

    this.passwordControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.emailControl.errors[LoginErrorCode.InvalidCredentials]) {
        this.emailControl.setErrors({
          ...this.emailControl.errors,
          [LoginErrorCode.InvalidCredentials]: null,
        });
      }
    });
  }

  handleFormSubmit(): void {
    if (this.signInForm.invalid) return;

    this.isLoading.set(true);

    defer(() => {
      if (this.passwordControl.disabled) {
        return this.signInWithSSO(this.emailControl.value).pipe(
          tap((success) => {
            if (success) return;

            // If failed to login with SSO, enable password field, so user can login with credentials
            this.passwordControl.enable();
            this.passwordControl.markAsUntouched();

            this.isLoading.set(false);
          }),
        );
      }

      return this.signInWithCredentials(this.emailControl.value, this.passwordControl.value).pipe(
        tap((success) => {
          if (success) return;

          this.isLoading.set(false);
        }),
      );
    })
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  private signInWithSSO(email: string): Observable<boolean> {
    return this.authService.signInWithSSO(email, this.getRedirectURI()).pipe(
      map(({ redirectUrl }) => {
        this.window.location.replace(redirectUrl);
        return true;
      }),
      catchError(() => {
        return of(false);
      }),
    );
  }

  private signInWithCredentials(email: string, password: string): Observable<boolean> {
    return this.authService.signInWithCredentials(email, password).pipe(
      switchMap((loginData) => from(this.authService.handleSuccessfullLogin(loginData))),
      catchError((errorResponse) => {
        this.handleLoginWithCredentialsError(errorResponse);
        return of(false);
      }),
    );
  }

  public resendEmail(event: Event): void {
    event.preventDefault();

    if (this.isLoading()) return;
    this.isLoading.set(true);

    this.authService
      .resendEmail(this.signInForm.value.email)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => this.isLoading.set(false)),
      )
      .subscribe(() => {
        this.toastr.success('emailSent.messages.resendSuccess');
      });
  }

  private handleLoginWithCredentialsError(errorResponse): void {
    if (errorResponse?.errors?.length) {
      this.toastr.displayServerErrors(errorResponse);
      return;
    }

    errorResponse.error.errors.forEach((error: ServerError) => {
      if (error.property === 'general') {
        this.handleGeneralError(error);
        return;
      }

      const field = this.signInForm.get(error.property);

      if (field)
        field.setErrors({
          ...field.errors,
          [error.errorCode]: error.errorMessage,
        });

      if (error.errorCode === LoginErrorCode.EmailNotConfirmed) this.showResendEmail.set(true);
    });

    return;
  }

  private handleGeneralError(error: ServerError): void {
    if (error.errorCode === LoginErrorCode.InvalidCredentials) {
      this.emailControl.setErrors({
        [error.errorCode]: error.errorMessage,
      });
    }

    if (error.errorCode === LoginErrorCode.TfaRequired) {
      this.modalService
        .open({
          content: TfaModalComponent,
          inputs: {
            data: error.data,
          },
        })
        .result.then((res) => {
          this.authService.handleSuccessfullLogin(res);
        });
    }
  }

  private getRedirectURI(): string {
    return `${this.window.location.origin}/${SSO_REDIRECT_URI}`;
  }
}
