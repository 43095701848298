import { type Routes } from '@angular/router';

import { CONVERSATIONS_BASE_URL } from '@conversations/routes';
import { AuthGuard } from '@core/guards/auth.guard';
import { AppRoutes } from '@core/models/routes';

import { CAMPAIGNS_BASE_URL } from './campaigns-v2/routes';
import { OopsPageComponent } from './error-pages/oops-page/oops-page.component';
import { NETWORK_BASE_URL } from './network-v2/routes';
import { PRODUCTS_BASE_URL } from './products-v2/routes';
import { TASKS_BASE_URL } from './tasks-v2/routes';
import { WORKFLOWS_BASE_URL } from './workflows-v2/routes';

export const appRoutes: Routes = [
  {
    path: AppRoutes.Dashboard,
    loadChildren: async () => await import('./dashboard/dashboard.module').then((mod) => mod.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard-v2',
    loadChildren: async () => await import('./dashboard-v2/routes').then(({ dashboardRoutes }) => dashboardRoutes),
    canActivate: [AuthGuard],
  },
  {
    path: NETWORK_BASE_URL,
    loadChildren: async () => await import('./network-v2/routes').then(({ networkPageRoutes }) => networkPageRoutes),
    canActivate: [AuthGuard],
  },
  {
    // New tasks routes
    path: TASKS_BASE_URL,
    loadChildren: async () => await import('./tasks-v2/routes').then(({ tasksPageRoutes }) => tasksPageRoutes),
    canActivate: [AuthGuard],
  },
  {
    // Legacy tasks routes
    path: AppRoutes.Tasks,
    loadChildren: async () => await import('./tasks/tasks.module').then((mod) => mod.TasksModule),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.Files,
    loadChildren: async () => await import('./files/files.module').then((mod) => mod.FilesModule),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.Settings,
    loadChildren: async () => await import('./settings/settings.module').then((mod) => mod.SettingsModule),
    canActivate: [AuthGuard],
  },
  {
    // New campaigns routes
    path: CAMPAIGNS_BASE_URL,
    loadChildren: async () =>
      await import('./campaigns-v2/routes').then(({ campaignsPageRoutes }) => campaignsPageRoutes),
    canActivate: [AuthGuard],
  },
  {
    // Legacy campaigns routes
    path: AppRoutes.Campaigns,
    loadChildren: async () => await import('./campaigns/campaigns.module').then((mod) => mod.CampaignsModule),
    canActivate: [AuthGuard],
  },
  {
    // New workflows routes
    path: WORKFLOWS_BASE_URL,
    loadChildren: async () =>
      await import('./workflows-v2/routes').then(({ workflowsPageRoutes }) => workflowsPageRoutes),
    canActivate: [AuthGuard],
  },
  {
    // Legacy workflows routes
    path: AppRoutes.Workflows,
    loadChildren: async () => await import('./workflows/workflows.module').then((mod) => mod.WorkflowsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'search',
    loadChildren: async () =>
      await import('./advanced-search/advanced-search.module').then((mod) => mod.AdvancedSearchModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'conversations',
    loadChildren: async () =>
      await import('./conversations/conversations.module').then((mod) => mod.ConversationsModule),
    canActivate: [AuthGuard],
  },
  {
    path: CONVERSATIONS_BASE_URL,
    loadChildren: async () =>
      await import('./conversations-v4/routes').then(({ conversationsPageRoutes }) => conversationsPageRoutes),
    canActivate: [AuthGuard],
  },
  {
    path: 'companyMatching',
    loadChildren: async () =>
      await import('./company-matching/company-matching.module').then((mod) => mod.CompanyMatchingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'companyApproval',
    loadChildren: async () =>
      await import('./company-approval/company-approval.module').then((mod) => mod.CompanyApprovalModule),
    canActivate: [AuthGuard],
  },
  {
    path: AppRoutes.Products,
    loadChildren: async () => await import('./products/products.module').then((mod) => mod.ProductsModule),
    canActivate: [AuthGuard],
  },
  {
    path: PRODUCTS_BASE_URL,
    loadChildren: async () => await import('./products-v2/routes').then(({ productsPageRoutes }) => productsPageRoutes),
    canActivate: [AuthGuard],
  },
  {
    path: 'reporting',
    loadChildren: async () => await import('./reporting/reporting.module').then((mod) => mod.ReportingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'companyAccessRequests',
    loadChildren: async () =>
      await import('./company-access-requests/company-access-requests.module').then(
        (mod) => mod.CompanyAccessRequestsModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'task-info',
    loadChildren: async () => await import('./task-info/task-info.module').then((mod) => mod.TaskInfoModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'bpi',
    loadChildren: () => import('./bpi/bpi.module').then((mod) => mod.BpiModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'error',
    component: OopsPageComponent,
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
];
