<cc-subnav-item
  #inboxLink="routerLinkActive"
  [routerLinkActiveOptions]="routerLinkActiveOptions"
  [routerLink]="[workspaceAlias, ConversationsCategory.Inbox]"
  [selected]="inboxLink.isActive && !hasFilteringParams"
  [title]="'conversations-v4.categories.inbox' | translate"
  [counter]="unreadInboxConversations() !== 0 ? (unreadInboxConversations() | ccLimitNumber: 2) : null"
  iconClass="icon-inbox"
  routerLinkActive
/>

@if (workspaceAlias !== WorkspaceAlias.Private) {
  <cc-subnav-item
    #assignedToMeLink="routerLinkActive"
    [routerLinkActiveOptions]="routerLinkActiveOptions"
    [routerLink]="[workspaceAlias, ConversationsCategory.AssignedToMe]"
    [selected]="assignedToMeLink.isActive && !hasFilteringParams"
    [title]="'conversations-v4.categories.assignedToMe' | translate"
    iconClass="icon-user"
    routerLinkActive
  />
}

<cc-subnav-item
  #mentionsLink="routerLinkActive"
  [routerLinkActiveOptions]="routerLinkActiveOptions"
  [routerLink]="[workspaceAlias, ConversationsCategory.Mentions]"
  [selected]="mentionsLink.isActive && !hasFilteringParams"
  [title]="'conversations-v4.categories.mentions' | translate"
  iconClass="icon-at"
  routerLinkActive
/>

@if (labels$ | async; as labels) {
  @if (labels.length) {
    <cc-subnav-item
      [selectable]="false"
      iconClass="icon-tag-alt"
      [title]="'conversations-v4.categories.labels' | translate"
    >
      @for (label of visibleLabels$ | async; track label.id) {
        <cc-subnav-item
          #rla="routerLinkActive"
          [routerLinkActiveOptions]="routerLinkActiveOptions"
          [routerLink]="[workspaceAlias, ConversationsCategory.Inbox, getLabelRouteParams(label)]"
          [selected]="rla.isActive"
          [title]="label.name"
          routerLinkActive
        >
          <cc-label-icon
            [label]="label"
            slot="subnav-item-icon"
          />
        </cc-subnav-item>
      }

      @if (activeHiddenLabel$ | async; as label) {
        <ng-container>
          <cc-subnav-item
            [routerLink]="[workspaceAlias, ConversationsCategory.Inbox, getLabelRouteParams(label)]"
            [selected]="true"
            [title]="label.name"
          >
            <cc-label-icon
              [label]="label"
              slot="subnav-item-icon"
            />
          </cc-subnav-item>
        </ng-container>
      }

      @if (showMoreLabelsDropdown$ | async) {
        <cc-subnav-item
          iconClass="icon-more"
          [selectable]="false"
          [title]="'common.strings.more' | translate"
          #dropdownTrigger="ccDropdownTrigger"
          [ccDropdownTrigger]="dropdown"
          [ccDropdownPositions]="{
            offsetY: 4,
            positions: ['bottom-start', 'top-start'],
          }"
          [ccDropdownToggleOnClick]="false"
          [dropdownState]="dropdownTrigger.isOpen() ? 'open' : 'closed'"
          (headerClick)="dropdownTrigger.toggle()"
        >
          <ng-template #dropdown>
            <cc-dropdown
              [shadow]="false"
              width="232px"
            >
              <cc-label-picker
                (createLabel)="createLabel($event); dropdownTrigger.close()"
                (manageLabels)="openWorkspaceSettingsLabelsTab(); dropdownTrigger.close()"
                (selectLabel)="goToLabel($event); dropdownTrigger.close()"
                [omittedLabelIds]="omittedInMoreDropdownLabelIds$ | async"
                [primaryWorkspaceId]="primaryWorkspaceId"
                [selectedLabelIds]="activeLabelIds$ | async"
                [showManageLabels]="workspaceAlias !== WorkspaceAlias.Aggregated"
                [workspaceIds]="workspaceIds"
                listMaxHeight="176px"
                selectionMode="single"
              />
            </cc-dropdown> </ng-template
        ></cc-subnav-item>
      }
    </cc-subnav-item>
  }
}

<cc-subnav-item
  #sentLink="routerLinkActive"
  [routerLinkActiveOptions]="routerLinkActiveOptions"
  [routerLink]="[workspaceAlias, ConversationsCategory.Sent]"
  [selected]="sentLink.isActive && !hasFilteringParams"
  [title]="'conversations-v4.categories.sent' | translate"
  iconClass="icon-send-plane"
  routerLinkActive
/>

<cc-subnav-item
  #draftsLink="routerLinkActive"
  [routerLinkActiveOptions]="routerLinkActiveOptions"
  [routerLink]="[workspaceAlias, ConversationsCategory.Drafts]"
  [selected]="draftsLink.isActive && !hasFilteringParams"
  [title]="'conversations-v4.categories.drafts' | translate"
  iconClass="icon-document"
  routerLinkActive
/>

@if (activeMoreDropdownLink; as link) {
  <cc-subnav-item
    [iconClass]="link.icon"
    [routerLink]="mergeArrays([workspaceAlias], link.commands)"
    [selected]="true"
    [title]="link.titleTranslationKey | translate"
  />
}

@if (nonActiveMoreDropdownLinks.length) {
  <cc-subnav-item
    iconClass="icon-more"
    [selectable]="false"
    [title]="'common.strings.more' | translate"
    #dropdownTrigger="ccDropdownTrigger"
    [ccDropdownTrigger]="dropdown"
    [ccDropdownPositions]="{
      offsetY: 4,
      positions: ['bottom-start', 'top-start'],
    }"
    [ccDropdownToggleOnClick]="false"
    [dropdownState]="dropdownTrigger.isOpen() ? 'open' : 'closed'"
    (headerClick)="dropdownTrigger.toggle()"
  >
    <ng-template #dropdown>
      <cc-dropdown
        [shadow]="false"
        width="232px"
      >
        @for (link of nonActiveMoreDropdownLinks; track link) {
          <cc-dropdown-action
            (click)="dropdownTrigger.close()"
            [leadingIcon]="link.icon"
            [routerLink]="mergeArrays([workspaceAlias], link.commands)"
          >
            <ng-container slot="title">{{ link.titleTranslationKey | translate }}</ng-container>
          </cc-dropdown-action>
        }
      </cc-dropdown>
    </ng-template>
  </cc-subnav-item>
}

<cc-dropdown-divider />

@if (workspaceAlias === WorkspaceAlias.Aggregated || workspaceAlias === WorkspaceAlias.Private) {
  <cc-subnav-item
    #directLink="routerLinkActive"
    [routerLinkActiveOptions]="routerLinkActiveOptions"
    [routerLink]="[workspaceAlias, ConversationsCategory.DirectMessages]"
    [selected]="directLink.isActive && !hasFilteringParams"
    [title]="'conversations-v4.categories.directMessages' | translate"
    [counter]="unreadDirectConversations() !== 0 ? (unreadDirectConversations() | ccLimitNumber: 2) : null"
    iconClass="icon-user"
    routerLinkActive
  >
    <ng-container slot="subnav-item-action">
      <app-button
        [routerLink]="[workspaceAlias, ConversationsCategory.DirectMessages, 'new']"
        (click)="$event.stopPropagation()"
        [ccTooltip]="'conversations-v4.actions.newDirectMessage' | translate"
        [type]="directLink.isActive ? ButtonType.GhostInvert : ButtonType.Ghost"
        [size]="ButtonSize.Small"
      >
        <i
          class="icon-plus-circle"
          slot="ghost-icon"
        ></i>
      </app-button>
    </ng-container>
  </cc-subnav-item>
}

@if (workspaceAlias !== WorkspaceAlias.Private) {
  <cc-subnav-item
    #channelsLink="routerLinkActive"
    [routerLinkActiveOptions]="routerLinkActiveOptions"
    [routerLink]="[workspaceAlias, ConversationsCategory.Channels]"
    [selected]="channelsLink.isActive && !hasFilteringParams"
    [title]="'conversations-v4.categories.channels' | translate"
    [counter]="unreadChannelConversations() !== 0 ? (unreadChannelConversations() | ccLimitNumber: 2) : null"
    iconClass="icon-users"
    routerLinkActive
  >
    <ng-container slot="subnav-item-action">
      <app-button
        (click)="$event.stopPropagation(); createChannel()"
        [ccTooltip]="'conversations-v4.actions.newChannel' | translate"
        [type]="channelsLink.isActive ? ButtonType.GhostInvert : ButtonType.Ghost"
        [size]="ButtonSize.Small"
      >
        <i
          class="icon-plus-circle"
          slot="ghost-icon"
        ></i>
      </app-button>
    </ng-container>
  </cc-subnav-item>
}
