import { FilterableTaskStatus, TaskType, type TaskPreview } from '@clover/conversations-v4/tasks/tasks.model';
import type { TasksSortingProperty } from '@clover/conversations-v4/tasks/tasks.service';
import type { NextPaginatedLoadingStatus } from '@clover/core/helpers/loading';
import { EMPTY_PAGING, PagingOrder, type OffsetPagingWrapper, type SortingOptions } from '@clover/core/helpers/paging';

import { defaultTaskStatus, defaultTaskType } from '../tasks-list.guard';

export interface TasksStateModel {
  tasks: OffsetPagingWrapper<TaskPreview>;
  loadingStatus: NextPaginatedLoadingStatus;
  query: string;
  type: TaskType;
  status: FilterableTaskStatus;
  sorting: SortingOptions<TasksSortingProperty>;
  usersIncompleteTasksCount: number;
}

export const defaultTasksState: TasksStateModel = {
  tasks: EMPTY_PAGING,
  loadingStatus: 'void',
  query: '',
  type: defaultTaskType,
  status: defaultTaskStatus,
  sorting: {
    orderBy: 'createdAt',
    order: PagingOrder.Descending,
  },
  usersIncompleteTasksCount: 0,
};
