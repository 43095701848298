import { CdkScrollable } from '@angular/cdk/overlay';
import { Component, inject } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { NgScrollbar, ScrollViewport } from 'ngx-scrollbar';

import { UserService } from '@clover/core/services/user.service';
import { PageWithSubnavComponent } from '@design/layout/page-with-subnav/page-with-subnav.component';
import { CollapsibleGroupComponent } from '@design/navigation/collapsible-group/collapsible-group.component';
import { SubnavItemComponent } from '@design/navigation/subnav-item/subnav-item.component';

@Component({
  selector: 'cc-network-v2',
  templateUrl: './network-v2.component.html',
  styleUrls: ['./network-v2.component.scss'],
  standalone: true,
  imports: [
    PageWithSubnavComponent,
    CollapsibleGroupComponent,
    SubnavItemComponent,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    CdkScrollable,
    ScrollViewport,
    NgScrollbar,
  ],
})
export class NetworkV2Component {
  private readonly userService = inject(UserService);

  get canViewConnections(): boolean {
    return this.userService.permissions['CompanyConnections_View'];
  }

  get canViewLists(): boolean {
    return this.userService.permissions['CompanyList_View'];
  }

  get canViewInvites(): boolean {
    return this.userService.permissions['CompanyInvitations_View'];
  }
}
