import { DialogRef } from '@angular/cdk/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import type { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  signal,
  effect,
  ChangeDetectorRef,
} from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
  type ValidationErrors,
  type ValidatorFn,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { select } from '@ngxs/store';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { effectOnceIf } from 'ngxtension/effect-once-if';
import { catchError, distinctUntilChanged, map, of, startWith, Subject, switchMap, take, tap } from 'rxjs';

import {
  ChannelsService,
  CreateChannelErrorCode,
} from '@clover/conversations-v4/conversations/state/conversations/channels.service';
import type { Conversation } from '@clover/conversations-v4/conversations/state/conversations/conversations-state.model';
import { AutoAnimateDirective } from '@clover/core/directives/auto-animate.directive';
import { NextPaginatedLoadingStatus } from '@clover/core/helpers/loading';
import { EMPTY_PAGING, type OffsetPagingWrapper } from '@clover/core/helpers/paging';
import { CdkPortalService } from '@clover/core/services/cdk-portal.service';
import { UserService } from '@clover/core/services/user.service';
import { ButtonType, ButtonSize } from '@design/buttons/button/types';
import type { SelectItem } from '@design/forms/select/select-item/select-item';
import { TextboxType } from '@design/forms/textbox/textbox.types';
import { injectDialogData } from '@design/misc/dialog.helpers';
import { ToastType } from '@design/overlays/toast/toast';
import { TdComponent } from '@design/table/td/td.component';
import { ThComponent } from '@design/table/th/th.component';
import { TrComponent } from '@design/table/tr/tr.component';

import { ButtonComponent } from '../../../../../stories/buttons/button/button.component';
import { CheckboxComponent } from '../../../../../stories/forms/checkbox/checkbox.component';
import { SelectItemComponent } from '../../../../../stories/forms/select/select-item/select-item.component';
import { SelectComponent } from '../../../../../stories/forms/select/select.component';
import { TextboxComponent } from '../../../../../stories/forms/textbox/textbox.component';
import { UserAvatarComponent } from '../../../../../stories/misc/user-avatar/user-logo.component';
import { DropdownTextComponent } from '../../../../../stories/overlays/dropdown/dropdown-text/dropdown-text.component';
import { TableComponent } from '../../../../../stories/table/table.component';
import type { Workspace, WorkspaceMember } from '../../state/workspaces/workspaces-state.model';
import { WorkspacesSelectors } from '../../state/workspaces/workspaces.selectors';
import { WorkspacesService } from '../../state/workspaces/workspaces.service';

function createSelectItemFromWorkspace(workspace: Workspace): SelectItem<Workspace> {
  return {
    id: String(workspace.id),
    title: workspace.name,
    payload: workspace,
  };
}

function channelNameValidator(): ValidatorFn {
  return (control: FormControl<string>): ValidationErrors | null => {
    const name = control.value.trim();
    if (!name) return null;

    const format = formatChannelName(name);

    if (name !== format) return { channelNameInvalid: true, channelNameSuggestion: format };
    return null;
  };
}

function formatChannelName(name: string): string {
  return name
    .trim()
    .replace(/[^a-z0-9- ]/gi, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
    .toLowerCase();
}

export interface ChannelCreateDialogData {
  predefinedName?: string;
  preselectedWorkspaceId?: number;
  preselectedMemberIds?: number[];
}

export type ChannelCreateDialogResult = Conversation;

@Component({
  selector: 'cc-channel-create-dialog',
  standalone: true,
  imports: [
    AutoAnimateDirective,
    NgScrollbarModule,
    ButtonComponent,
    TranslateModule,
    TextboxComponent,
    SelectComponent,
    SelectItemComponent,
    DropdownTextComponent,
    ReactiveFormsModule,
    TableComponent,
    TrComponent,
    ThComponent,
    TdComponent,
    CheckboxComponent,
    UserAvatarComponent,
    CdkScrollable,
    InfiniteScrollDirective,
  ],
  templateUrl: './channel-create-dialog.component.html',
  styleUrl: './channel-create-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelCreateDialogComponent {
  protected readonly dialogData = injectDialogData<ChannelCreateDialogData>();

  protected readonly dialogRef: DialogRef<ChannelCreateDialogResult | undefined> = inject(
    DialogRef<ChannelCreateDialogResult | undefined>,
  );

  protected readonly teamWorkspaces = select(WorkspacesSelectors.teamWorkspaces);
  protected readonly workspacesSelectOptions = computed(() =>
    this.teamWorkspaces().map((workspace) => createSelectItemFromWorkspace(workspace)),
  );

  protected readonly members = signal<OffsetPagingWrapper<WorkspaceMember>>(EMPTY_PAGING);
  protected readonly membersLoadingStatus = signal<NextPaginatedLoadingStatus>('void');
  protected readonly loadNextMembers$ = new Subject<void>();

  protected readonly form = new FormGroup({
    name: new FormControl<string>('', [Validators.required, channelNameValidator()]),
    workspace: new FormControl<SelectItem<Workspace> | undefined>(undefined, [Validators.required]),
    members: new FormControl<number[]>([], [Validators.required]),
  });

  protected readonly selectedWorkspaceId = toSignal(
    this.form.valueChanges.pipe(
      startWith(this.form.value),
      map((values) => values.workspace?.payload.id),
      distinctUntilChanged(),
    ),
  );

  protected readonly selectedMembers = toSignal(
    this.form.valueChanges.pipe(
      startWith(this.form.value),
      map((values) => values.members),
      distinctUntilChanged(),
    ),
  );

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TextboxType = TextboxType;

  private readonly workspacesService = inject(WorkspacesService);
  private readonly channelsService = inject(ChannelsService);
  private readonly userService = inject(UserService);
  private readonly portal = inject(CdkPortalService);
  private readonly translate = inject(TranslateService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly cdr = inject(ChangeDetectorRef);

  get currentUserId(): number {
    return this.userService.userProfile.id;
  }

  constructor() {
    this.initForm();
    this.initMembers();

    // Clear members list when workspace changes
    effect(() => {
      this.selectedWorkspaceId();
      this.form.controls.members.setValue([]);
    });
  }

  acceptNameSuggestion(): void {
    const suggestion = this.form.controls.name.errors?.channelNameSuggestion;
    if (!suggestion) return;

    this.form.controls.name.setValue(suggestion);
  }

  toggleMember(memberId: number): void {
    const members = this.form.controls.members.value as number[];

    if (members.includes(memberId)) {
      this.form.controls.members.setValue(members.filter((id) => id !== memberId));
    } else {
      this.form.controls.members.setValue([...members, memberId]);
    }
  }

  createChannel(): void {
    if (this.form.invalid) return;

    const name = this.form.controls.name.value.trim();
    const workspace = this.form.controls.workspace.value.payload;
    const memberIds = this.form.controls.members.value;

    this.form.disable();

    this.channelsService
      .createChannel(workspace.id, name, memberIds)
      .pipe(
        take(1),
        tap((conversation) => {
          this.portal.presentToast(
            this.translate.instant('conversations-v4.workspaces.dialogs.createChannel.channelCreated'),
            ToastType.Success,
          );

          this.dialogRef.close(conversation);
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          const errorCodes = (errorResponse?.error?.errors || []).map(
            (error) => error?.errorCode,
          ) as CreateChannelErrorCode[];
          this.handleCreationError(errorCodes);

          return of(undefined);
        }),
      )
      .subscribe();
  }

  private handleCreationError(errorCodes: CreateChannelErrorCode[]): void {
    this.form.enable();

    if (errorCodes.includes(CreateChannelErrorCode.NameAlreadyExists)) {
      this.form.controls.name.setErrors({ channelNameExists: true });
      this.cdr.markForCheck();

      return;
    }

    this.portal.presentToast(
      this.translate.instant('conversations-v4.workspaces.dialogs.createChannel.failedToCreateChannel'),
      ToastType.Error,
    );
  }

  private initForm(): void {
    effectOnceIf(
      () => !!this.dialogData().predefinedName,
      () => {
        this.form.controls.name.setValue(this.dialogData().predefinedName || '');
      },
    );

    effectOnceIf(
      () => this.workspacesSelectOptions().length > 0,
      () => {
        const options = this.workspacesSelectOptions();
        const preselectedOption =
          options.find((option) => option.payload.id === this.dialogData().preselectedWorkspaceId) || options[0];

        this.form.controls.workspace.setValue(preselectedOption);
      },
    );

    effectOnceIf(
      () => !!this.dialogData().preselectedMemberIds?.length,
      () => {
        this.form.controls.members.setValue(this.dialogData().preselectedMemberIds || []);
      },
    );
  }

  private initMembers(): void {
    toObservable(this.selectedWorkspaceId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        // Loading initial members (based on workspace id)
        tap(() => this.membersLoadingStatus.set('loading')),
        switchMap((workspaceId) => this.workspacesService.getWorkspaceMembers(workspaceId, { limit: 30 })),
        map((members) => {
          this.members.set(members);
          this.membersLoadingStatus.set('loaded');
        }),
        // Loading next members
        switchMap(() => this.loadNextMembers$),
        tap(() => this.membersLoadingStatus.set('loading-next')),
        switchMap(() =>
          this.workspacesService.getWorkspaceMembers(this.selectedWorkspaceId(), {
            limit: 30,
            offset: this.members().count,
          }),
        ),
        map((members) => {
          this.members.update((prev) => ({
            ...members,
            data: [...prev.data, ...members.data],
            count: prev.count + members.data.length,
          }));
          this.membersLoadingStatus.set('loaded');
        }),
        catchError((error) => {
          this.membersLoadingStatus.set('error');
          console.error(error);

          return of(EMPTY_PAGING);
        }),
      )
      .subscribe();
  }
}
