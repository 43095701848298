import { Subject } from 'rxjs';

import type {
  ChannelMessageDraft,
  CompactMessage,
  Draft,
  Participants,
  EmailMessageDraft,
  NewEmailDraft,
} from '@clover/conversations-v4/conversation/state/conversation/conversation-state.model';
import type { PendingConversationId } from '@clover/conversations-v4/conversation/state/conversation/conversation.helpers';

export interface ComposersStateModel {
  composers: ComposerInstance[];
}

interface BaseComposerInstance {
  id: string;
  composerType: ComposerType;
  workspaceId: number;
  message: string;
  draft: Draft | undefined;
  draftSaveStatus: DraftSaveStatus;
  activeFileUploads: FileUpload[];
  sendStatus: SendStatus;
}

// This composer type is used when we compose a new email message.
export interface NewEmailComposerInstance extends BaseComposerInstance {
  composerType: 'newEmail';
  messageType: Extract<ComposerMessageType, 'new'>;
  senderAccountId: number;
  participants: Omit<Participants, 'from'>;
  subject: string;
  signature: string;
  draft: NewEmailDraft | undefined;
  presentation: Extract<ComposerPresentation, 'overlay-expanded' | 'overlay-collapsed'>;
}

// This composer type is used when we compose a message as a reply to some existing email conversation.
// Supports internal messages, reply, reply all, and forward message types.
export interface LinkedEmailComposerInstance extends BaseComposerInstance {
  composerType: 'linkedEmail';
  conversationId: string;
  messageType: LinkedEmailComposerMessageType;
  senderAccountId: number;
  participants: Omit<Participants, 'from'>;
  subject: string;
  quote: string;
  signature: string;
  replyToMessage: CompactMessage;
  replyForbidden: boolean;
  draft: EmailMessageDraft | undefined;
  presentation: ComposerPresentation;
}

export type LinkedEmailComposerMessageType = Extract<
  ComposerMessageType,
  'internal' | 'reply' | 'replyAll' | 'forward'
>;

export interface ChannelComposerInstance extends BaseComposerInstance {
  composerType: 'channel';
  messageType: ChannelComposerMessageType;
  conversationId: string;
  replyForbidden: boolean;
  draft: ChannelMessageDraft | undefined;
  presentation: ComposerPresentation;
}

export interface NewDirectMessageComposerInstance extends BaseComposerInstance {
  composerType: 'newDirect';
  messageType: ChannelComposerMessageType;
  participants: Pick<Participants, 'to'>;
  replyForbidden: boolean;
  presentation: ComposerPresentation;
  pendingConversationId: PendingConversationId;
}

export interface DirectMessageComposerInstance extends BaseComposerInstance {
  composerType: 'direct';
  messageType: ChannelComposerMessageType;
  conversationId: string;
  replyForbidden: boolean;
  draft: ChannelMessageDraft | undefined;
  presentation: ComposerPresentation;
}

export type ChannelComposerMessageType = Extract<ComposerMessageType, 'internal'>;

export type ComposerInstance =
  | NewEmailComposerInstance
  | LinkedEmailComposerInstance
  | ChannelComposerInstance
  | NewDirectMessageComposerInstance
  | DirectMessageComposerInstance;

export type DraftSaveStatus = 'void' | 'saving' | 'saved' | 'error';
export type SendStatus = 'void' | 'sending';

export type ComposerType = 'newEmail' | 'linkedEmail' | 'channel' | 'newDirect' | 'direct';
export type ComposerMessageType = 'internal' | 'reply' | 'replyAll' | 'forward' | 'new';
export type ComposerPresentation = 'inline' | 'overlay-expanded' | 'overlay-collapsed';

export interface FileUpload {
  id: string;
  file: File;
  status: 'uploading' | 'error';
  progress: number;
  error?: FileUploadError;
  cancel$: Subject<void>;
}

export enum FileUploadError {
  // TODO (Oleksandr D.): add more error types
  Unknown = 'unknown',
}

export const defaultComposersState: ComposersStateModel = {
  composers: [],
};
