import type { LoadingStatus } from '@clover/core/helpers/loading';

export interface LabelsStateModel {
  labels: Label[];
  loadingStatus: LoadingStatus;
}

export const defaultLabelsState: LabelsStateModel = {
  labels: [],
  loadingStatus: 'void',
};

export interface Label {
  id: number;
  workspaceId: number;
  name: string;
  backgroundColor: string;
  foregroundColor: string;
  system: boolean;
  private: boolean;
  visibleInNavigation: boolean;
}
