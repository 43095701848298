import { HttpClient, HttpEvent } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  type ChannelComposerInstance,
  type DirectMessageComposerInstance,
  type LinkedEmailComposerInstance,
} from '@conversations/composer/state/composers/composers-state.model';
import {
  Draft,
  DraftAttachment,
  DraftMessageType,
  EmailParticipant,
  Participants,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import {
  DraftAttachmentResponse,
  DraftResponse,
  mapDraft,
  mapDraftAttachment,
} from '@conversations/conversation/state/conversation/conversation.service';
import { ConfigService } from '@core/services/config.service';
import { HttpService } from '@core/services/http.service';
import { DynamicValuesService } from '@design/forms/rich-text-editor/extensions/dynamic-values/dynamic-values.service';
import isHTMLEmpty from '@design/forms/rich-text-editor/helpers/is-empty';

import { NewEmailComposerInstance } from './composers-state.model';

export enum QuoteType {
  Reply = 'Reply',
  Forward = 'Forward',
}

interface QuotedContentResponse {
  content: string;
}

interface TraceIdResponse {
  traceId: string;
}

function mapParticipant(participant: EmailParticipant) {
  return {
    name: participant.name,
    email: participant.email,
    logoUrl: participant.avatarUrl,
  };
}

function mapEmailMessageType(type: Omit<DraftMessageType, DraftMessageType.Note>): string {
  if (type === DraftMessageType.NewEmail) return 'New';
  if (type === DraftMessageType.Reply || type === DraftMessageType.ReplyAll) return 'Reply';
  if (type === DraftMessageType.Forward) return 'Forward';

  throw new Error(`Unknown email message type: ${type}`);
}

export interface SaveDraftPayload {
  externalAccountId?: number;
  conversationId?: string;
  replyToMessageId?: string;
  subject?: string;
  content: string;
  quotedContent?: string;
  signatureContent?: string;
  participants?: Omit<Participants, 'from'>;
  type: DraftMessageType;
}

@Injectable({
  providedIn: 'root',
})
export class ComposersService {
  private readonly http = inject(HttpService);
  private readonly angularHttp = inject(HttpClient);
  private readonly dynamicValuesService = inject(DynamicValuesService);

  private get baseUrl(): string {
    return ConfigService.settings.apiUrl;
  }

  getQuotedContent(externalAccountId: number, messageId: string, quoteType: QuoteType): Observable<string> {
    return this.http
      .postV2<QuotedContentResponse>(`/api/stream-conversations/messages/email/quotedContent`, {
        externalAccountId,
        messageId,
        type: quoteType,
      })
      .pipe(map((response) => response.content));
  }

  getDraft(draftId: number): Observable<Draft> {
    return this.http
      .getV2<DraftResponse>(`/api/stream-conversations/drafts/${draftId}`)
      .pipe(map((response) => mapDraft(response)));
  }

  saveDraft(draftId: number | undefined, draft: SaveDraftPayload): Observable<Draft> {
    const requestBody = {
      externalAccountId: draft.externalAccountId,
      streamConversationId: draft.conversationId,
      replyToStreamMessageId: draft.replyToMessageId,
      subject: draft.subject,
      content: draft.content,
      quotedContent: draft.quotedContent,
      signatureContent: draft.signatureContent,
      to: draft.participants && (draft.participants.to || []).map(mapParticipant),
      cc: draft.participants && (draft.participants.cc || []).map(mapParticipant),
      bcc: draft.participants && (draft.participants.bcc || []).map(mapParticipant),
      type: draft.type,
    };

    if (!draftId) {
      return this.http
        .postV2<DraftResponse>(`/api/stream-conversations/drafts`, requestBody)
        .pipe(map((response) => mapDraft(response)));
    }

    return this.http
      .putV2<DraftResponse>(`/api/stream-conversations/drafts/${draftId}`, requestBody)
      .pipe(map((response) => mapDraft(response)));
  }

  uploadAttachment(draftId: number, file: File): Observable<HttpEvent<unknown>> {
    const formData = new FormData();
    formData.append('file', file);

    return this.angularHttp.post(
      `${this.baseUrl}/api/stream-conversations/drafts/${draftId}/attachments/upload`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      },
    );
  }

  removeAttachment(draftId: number, attachmentId: number): Observable<void> {
    return this.http.deleteV2(`/api/stream-conversations/drafts/${draftId}/attachments/${attachmentId}`);
  }

  attachFileFromCloverStorage(
    draftId: number,
    userFileIds: number[],
    companyFileIds: number[],
  ): Observable<DraftAttachment[]> {
    return this.http
      .postV2<DraftAttachmentResponse[]>(`/api/stream-conversations/drafts/${draftId}/attachments/attach`, {
        userFilesIds: userFileIds,
        companyFilesIds: companyFileIds,
      })
      .pipe(map((response) => response.map(mapDraftAttachment)));
  }

  sendDraftEmail(composer: NewEmailComposerInstance | LinkedEmailComposerInstance): Observable<string> {
    const draft = composer.draft;

    if (!draft) throw new Error(`Draft is required to send an email. Missing in composer with id: ${composer.id}`);

    return this.http
      .postV2<TraceIdResponse>(`/api/stream-conversations/messages/asyncEmail`, {
        externalAccountId: composer.senderAccountId,
        conversationId: composer.composerType === 'linkedEmail' ? composer.conversationId : undefined,
        subject: draft.subject,
        body: draft.content,
        signatureContent: isHTMLEmpty(draft.signature) ? undefined : draft.signature,
        to: (draft.participants?.to || []).map(mapParticipant),
        cc: (draft.participants?.cc || []).map(mapParticipant),
        bcc: (draft.participants?.bcc || []).map(mapParticipant),
        replyToMessageId: draft.type !== DraftMessageType.NewEmail ? draft.replyToMessage.id : undefined,
        type: mapEmailMessageType(draft.type),
        messageDraftId: draft.id,
      })
      .pipe(map((response) => response.traceId));
  }

  sendDraftInternalMessage(
    composer: LinkedEmailComposerInstance | ChannelComposerInstance | DirectMessageComposerInstance,
  ): Observable<string> {
    const draft = composer.draft;

    if (!draft)
      throw new Error(`Draft is required to send an internal message. Missing in composer with id: ${composer.id}`);

    return this.http
      .postV2<TraceIdResponse>(`/api/stream-conversations/messages/asyncNote`, {
        conversationId: composer.conversationId,
        text: draft.content,
        messageDraftId: draft.id,
      })
      .pipe(map((response) => response.traceId));
  }

  getPersonalizedSignature(signature: string): string | undefined {
    const personalizedSignature = this.dynamicValuesService.replaceDynamicValues(signature);

    if (isHTMLEmpty(personalizedSignature)) return undefined;
    return personalizedSignature;
  }
}
