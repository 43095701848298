import { computed, inject, Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { select, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ContactType } from '@clover/conversations-v4/conversation/state/contacts/contacts.model';
import { mapWorkspaceMember } from '@clover/conversations-v4/workspaces/state/workspaces/workspaces.service';
import type { User } from '@clover/core/models/user';
import { UserService } from '@clover/core/services/user.service';
import { RouterSelectors } from '@clover/custom-router-state-serializer';
import { ConversationsCategory } from '@conversations/conversations/categories';
import {
  parseExternalAccountParam,
  parseLabelParam,
  parseStatusParam,
} from '@conversations/conversations/conversations.resolver';
import {
  Conversation,
  ConversationPerformer,
  ConversationSource,
  ConversationStatus,
  type ChannelConversation,
  type DirectConversation,
  type DraftEmailConversation,
  type EmailConversation,
} from '@conversations/conversations/state/conversations/conversations-state.model';
import { getWorkspaceIdByAlias } from '@conversations/workspaces/state/workspaces/workspaces-state.helpers';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { getPagingOptionsParams, PagingOptions, PagingWrapper } from '@core/helpers/paging';
import { HttpService } from '@core/services/http.service';
import { environment } from 'environments/environment';

export interface ConversationResponse {
  id: string | undefined;
  workspaceId: number | undefined;
  externalAccountId: number | undefined;
  name: string;
  lastMessage: LastMessageResponse | undefined;
  lastMessageAt: string | undefined;
  createdAt: string;
  updatedAt: string;
  isRead: boolean;
  tagIds: number[];
  isPrioritized: boolean;
  status: ConversationStatus;
  isSnoozed: boolean;
  snoozedUntil: string | undefined;
  hasAttachments: boolean;
  sender: UserResponse | undefined;
  members: UserResponse[];
  type: ConversationSource;
}

interface LastMessageResponse {
  snippet: string;
  sender: UserResponse;
  createdAt: string;
  hasAttachments: boolean;
  isDraft: boolean;
  messageDraftId: number;
}

export interface UserResponse {
  id: number;
  name: string;
  title: string;
  logoUrl: string;
  companyName: string;
  companyLogoUrl: string;
  contactType: ContactType;
}

export interface ConversationMember {
  id: number;
  name: string;
  logoUrl: string;
  contactType: ContactType;
}

export interface ConversationCategoryFilterParams {
  labelIds: number[] | undefined;
  externalAccountId: number | undefined;
  status: ConversationStatus | undefined;
}

export type ConversationsSortingProperty =
  | 'created_at'
  | 'member_count'
  | 'unread_count'
  | 'has_unread'
  | 'last_updated_at';

export function createDraftConversationId(draftId: number): string {
  return `draft-${draftId}`;
}

export function mapConversation(
  c: ConversationResponse,
  currentUser: User,
  privateWorkspaceId?: number,
): Conversation | undefined {
  try {
    if (c.type === ConversationSource.Direct && !privateWorkspaceId) throw new Error('Private workspace ID is missing');

    if (c.type === ConversationSource.Email) return mapEmailConversation(c, currentUser);
    if (c.type === ConversationSource.Chat) return mapChatConversation(c);
    if (c.type === ConversationSource.Direct) return mapDirectConversation(c, privateWorkspaceId);

    return undefined;
  } catch (error) {
    if (!environment.production)
      console.error('Error while mapping conversation', {
        conversation: c,
        error,
      });
    return undefined;
  }
}

function mapEmailConversation(c: ConversationResponse, currentUser: User): EmailConversation | DraftEmailConversation {
  const isExisting = !!c.id;

  return {
    id: isExisting ? c.id : createDraftConversationId(c.lastMessage.messageDraftId),
    type: isExisting ? 'existing' : 'draft',
    source: ConversationSource.Email,
    workspaceId: c.workspaceId,
    externalAccountId: c.externalAccountId,
    draftId: !isExisting && c.lastMessage.messageDraftId,
    subject: c.name,
    initialSender: isExisting
      ? mapConversationPerformer(c.sender)
      : {
          id: currentUser.id.toString(),
          name: currentUser.fullName,
          avatarUrl: currentUser.logoUrl,
          type: ContactType.CloverUser,
        },
    latestMessage: {
      sender: mapConversationPerformer(c.lastMessage.sender),
      messageSnippet: c.lastMessage.snippet,
      draft: c.lastMessage.isDraft,
      createdAt: c.lastMessage.createdAt,
    },
    read: c.isRead,
    status: c.status,
    labelIds: c.tagIds,
    hasAttachments: c.hasAttachments,
    prioritized: c.isPrioritized,
    snoozedUntil: c.isSnoozed ? c.snoozedUntil : undefined,
    members: c.members.map(mapWorkspaceMember),
    createdAt: c.createdAt,
    updatedAt: c.updatedAt,
  };
}

function mapChatConversation(c: ConversationResponse): ChannelConversation {
  return {
    id: c.id,
    type: 'existing',
    source: ConversationSource.Chat,
    workspaceId: c.workspaceId,
    subject: c.name,
    latestMessage: c.lastMessage
      ? {
          sender: mapConversationPerformer(c.lastMessage.sender),
          messageSnippet: c.lastMessage.snippet,
          draft: c.lastMessage.isDraft,
          createdAt: c.lastMessage.createdAt,
        }
      : undefined,
    read: c.isRead,
    status: c.status,
    labelIds: c.tagIds,
    hasAttachments: c.hasAttachments,
    prioritized: c.isPrioritized,
    snoozedUntil: c.isSnoozed ? c.snoozedUntil : undefined,
    members: c.members.map(mapWorkspaceMember),
    createdAt: c.createdAt,
    updatedAt: c.updatedAt,
  };
}

function mapDirectConversation(c: ConversationResponse, privateWorkspaceId: number): DirectConversation {
  return {
    id: c.id,
    type: 'existing',
    source: ConversationSource.Direct,
    workspaceId: privateWorkspaceId,
    subject: c.name,
    latestMessage: c.lastMessage
      ? {
          sender: mapConversationPerformer(c.lastMessage.sender),
          messageSnippet: c.lastMessage.snippet,
          draft: c.lastMessage.isDraft,
          createdAt: c.lastMessage.createdAt,
        }
      : undefined,
    read: c.isRead,
    status: c.status,
    labelIds: c.tagIds,
    hasAttachments: c.hasAttachments,
    prioritized: c.isPrioritized,
    snoozedUntil: c.isSnoozed ? c.snoozedUntil : undefined,
    members: c.members.map(mapWorkspaceMember),
    createdAt: c.createdAt,
    updatedAt: c.updatedAt,
  };
}

export function mapConversationPerformer(user: ConversationMember | undefined): ConversationPerformer | undefined {
  if (!user) return undefined;

  return {
    id: user.id.toString(),
    name: user.name,
    avatarUrl: user.logoUrl,
    type: user.contactType,
  };
}

function getCategoryEndpoint(categoryId: ConversationsCategory): string[] {
  const endpointsMap: Record<ConversationsCategory, string[]> = {
    [ConversationsCategory.Inbox]: ['inbox', 'search'],
    [ConversationsCategory.AssignedToMe]: ['assignedToMe', 'search'],
    [ConversationsCategory.Mentions]: ['mentions', 'search'], // Not implemented yet
    [ConversationsCategory.Sent]: ['sent', 'search'],
    [ConversationsCategory.Drafts]: ['drafts'],
    [ConversationsCategory.Prioritized]: ['prioritized', 'search'],
    [ConversationsCategory.Snoozed]: ['snoozed', 'search'],
    [ConversationsCategory.AllMail]: ['all-mail', 'search'],
    [ConversationsCategory.Spam]: ['spam', 'search'],
    [ConversationsCategory.Trash]: ['trash', 'search'],
    [ConversationsCategory.DirectMessages]: ['direct', 'search'],
    [ConversationsCategory.Channels]: ['chat', 'search'],
  };

  return endpointsMap[categoryId];
}

@Injectable({
  providedIn: 'root',
})
export class ConversationsService {
  private readonly http = inject(HttpService);
  private readonly router = inject(Router);
  private readonly serializer = inject(UrlSerializer);
  private readonly store = inject(Store);
  private readonly userService = inject(UserService);

  private readonly privateWorkspace = select(WorkspacesSelectors.privateWorkspace);
  private readonly privateWorkspaceId = computed(() => this.privateWorkspace().id);

  getConversations(
    workspaceId: number,
    categoryId: ConversationsCategory,
    filterParams: ConversationCategoryFilterParams,
    pagingOptions?: PagingOptions<ConversationsSortingProperty>,
  ): Observable<PagingWrapper<Conversation>> {
    const categoryEndpointSegments = getCategoryEndpoint(categoryId);

    const urlTree = this.router.createUrlTree(['api', 'stream-conversations', ...categoryEndpointSegments], {
      queryParams: {
        workspaceId,
        tagIds: filterParams.labelIds,
        externalAccountId: filterParams.externalAccountId,
        status: filterParams.status,
        ...getPagingOptionsParams(pagingOptions),
      },
    });

    const path = this.serializer.serialize(urlTree);
    return this.http.getV2<PagingWrapper<ConversationResponse>>(path).pipe(
      map((response) => ({
        ...response,
        data: response.data
          .map((c) => mapConversation(c, this.userService.userProfile, this.privateWorkspaceId()))
          .filter((c) => c !== undefined),
      })),
    );
  }

  getConversation(conversationId: string): Observable<Conversation> {
    return this.http
      .getV2<ConversationResponse>(`api/stream-conversations/${conversationId}`)
      .pipe(map((c) => mapConversation(c, this.userService.userProfile, this.privateWorkspaceId())));
  }

  checkExistenceInCurrentView(
    conversationId: string | undefined = undefined,
    draftId: number | undefined = undefined,
  ): Observable<Conversation | undefined> {
    if (!conversationId && !draftId) return of(undefined);

    const { workspaceAlias, categoryId, status, label, account } = this.store.selectSnapshot(
      RouterSelectors.state,
    ).params;

    const workspaces = this.store.selectSnapshot(WorkspacesSelectors.workspaces);
    const workspaceId = getWorkspaceIdByAlias(workspaces, workspaceAlias);
    const categoryEndpointSegments = getCategoryEndpoint(categoryId);
    if (!categoryEndpointSegments) return of(undefined);

    const filterParams: ConversationCategoryFilterParams = {
      labelIds: parseLabelParam(label),
      externalAccountId: parseExternalAccountParam(account),
      status: parseStatusParam(status),
    };

    const urlTree = this.router.createUrlTree(['api', 'stream-conversations', ...categoryEndpointSegments], {
      queryParams: {
        workspaceId,
        conversationId,
        draftId,
        tagIds: filterParams.labelIds,
        externalAccountId: filterParams.externalAccountId,
        status: filterParams.status,
        limit: 1,
      },
    });

    const path = this.serializer.serialize(urlTree);
    return this.http.getV2<PagingWrapper<ConversationResponse>>(path).pipe(
      map((response) => {
        return response.data[0]
          ? mapConversation(response.data[0], this.userService.userProfile, this.privateWorkspaceId())
          : undefined;
      }),
    );
  }
}
