<div [ccAutoAnimate]>
  @if (editorActive()) {
    <cc-composer-recipients-selector-editor
      (recipientsChange)="handleRecipientsChange($event)"
      [recipients]="recipients()"
      [contactType]="contactType()"
      [recipientTypes]="recipientTypes()"
    />
  } @else {
    <cc-composer-recipients-selector-preview
      (click)="editorActive.set(true); $event.stopPropagation()"
      [recipients]="recipients()"
      [recipientTypes]="recipientTypes()"
    />
  }
</div>
