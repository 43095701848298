<div
  [ccTooltip]="'conversations-v4.composer.recipientSelector.editRecipients' | translate"
  [tooltipAlignment]="TooltipAlignment.Start"
  class="composer-recipients-selector-preview"
>
  @if (hasRecipients) {
    <div
      #items
      (ccResize)="recalculateHiddenRecipients()"
      class="composer-recipients-selector-preview__items"
    >
      @for (group of groups; track group.type) {
        @for (recipient of group.recipients; track recipient.email; let first = $first; let last = $last) {
          <span
            [attr.data-type]="group.type"
            class="composer-recipients-selector-preview__items-item"
          >
            @if (first) {
              <span class="composer-recipients-selector-preview__items-item-group-title">
                @switch (group.type) {
                  @case ('to') {
                    {{ 'conversations-v4.composer.recipientSelector.to' | translate }}:&nbsp;
                  }
                  @case ('cc') {
                    {{ 'conversations-v4.composer.recipientSelector.cc' | translate }}:&nbsp;
                  }
                  @case ('bcc') {
                    {{ 'conversations-v4.composer.recipientSelector.bcc' | translate }}:&nbsp;
                  }
                }
              </span>
            }
            @if (!first) {
              <span class="composer-recipients-selector-preview__items-item-separator">,&nbsp;</span>
            }
            <cc-composer-recipients-selector-preview-item [recipient]="recipient">
            </cc-composer-recipients-selector-preview-item>
          </span>
        }
      }
    </div>
    @if (hiddenRecipientsString) {
      <div class="composer-recipients-selector-preview__hidden-recipients">
        {{ hiddenRecipientsString }}
      </div>
    }
  } @else {
    <div class="composer-recipients-selector-preview__placeholder">
      @if (recipientTypes().includes('to') && recipientTypes().includes('cc') && recipientTypes().includes('bcc')) {
        {{ 'conversations-v4.composer.recipientSelector.previewPlaceholder' | translate }}
      } @else {
        {{ 'conversations-v4.composer.recipientSelector.previewPlaceholderGeneric' | translate }}
      }
    </div>
  }
</div>
