import { ChangeDetectionStrategy, Component, inject, model, output, signal, type OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, of, tap } from 'rxjs';

import { AutoAnimateDirective } from '@clover/core/directives/auto-animate.directive';
import type { LoadingStatus } from '@clover/core/helpers/loading';
import { SubnavItemComponent } from '@design/navigation/subnav-item/subnav-item.component';

import type { WorkflowFolder } from '../workflow-library.model';
import { WorkflowLibraryService } from '../workflow-library.service';

@UntilDestroy()
@Component({
  selector: 'cc-workflow-picker-dialog-folders',
  standalone: true,
  imports: [SubnavItemComponent, AutoAnimateDirective],
  templateUrl: './workflow-picker-dialog-folders.component.html',
  styleUrl: './workflow-picker-dialog-folders.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowPickerDialogFoldersComponent implements OnInit {
  selectedFolderId = model<number | undefined>(undefined);
  foldersPopulated = output<void>();

  protected readonly folders = signal<WorkflowFolder[] | undefined>(undefined);
  protected readonly loadingStatus = signal<LoadingStatus>('void');

  private readonly workflowLibraryService = inject(WorkflowLibraryService);

  ngOnInit(): void {
    this.loadFolders();
  }

  selectFolder(folderId: number): void {
    this.selectedFolderId.set(folderId);
  }

  private loadFolders(): void {
    this.loadingStatus.set('loading');

    this.workflowLibraryService
      .getFolders()
      .pipe(
        untilDestroyed(this),
        tap((folders) => {
          this.folders.set(folders);
          this.loadingStatus.set('loaded');
          this.foldersPopulated.emit();
          this.selectedFolderId.update((id) => {
            const hasFolder = folders.some((folder) => folder.id === id);
            return hasFolder ? id : folders[0]?.id;
          });
        }),
        catchError(() => {
          this.loadingStatus.set('error');
          return of(undefined);
        }),
      )
      .subscribe();
  }
}
