<div class="profile-dropdown__body">
  <div></div>
  <div>
    <div class="profile-dropdown__user-info">
      <a
        (click)="dismiss.emit()"
        [routerLink]="['/network/company/' + currentUser?.companyId]"
        class="profile-dropdown__user-info_company"
      >
        {{ userCompany?.name }}
      </a>

      <user-logo
        class="header_user-logo"
        hideStatus="true"
        [user]="currentUser"
      />

      <div class="profile-dropdown__user-info_name">
        {{ currentUser?.fullName }}
      </div>

      <div class="profile-dropdown__user-info_email">
        {{ currentUser?.username }}
      </div>
    </div>
  </div>
</div>

@if (availableUsers?.length > 0) {
  <div class="profile-dropdown__available-companies">
    @for (user of availableUsers; track user) {
      <div
        [class.selected]="user.userId === selectedUserId"
        [class.disabled]="isImpersonating"
        (click)="selectUser(user)"
      >
        <company-logo [company]="user"></company-logo>
        <span>{{ user.companyName }}</span>
        @if (user.userId === currentUser.id) {
          <span>
            {{ 'common.strings.primary' | translate }}
          </span>
        }
      </div>
    }
  </div>
}

<div class="profile-dropdown__buttons">
  <button
    [appFocusable]
    class="dropdown-item"
    [routerLink]="['/settings/profile']"
  >
    {{ 'header.editProfile' | translate }}
  </button>
  @if (isImpersonationAllowed && !isSwitchedProfile) {
    <button
      [appFocusable]
      class="dropdown-item"
      (click)="onImpersonateClick()"
    >
      {{ 'header.impersonate' | translate }}
    </button>
  }
  <button
    [appFocusable]
    class="dropdown-item"
    (click)="signOut()"
  >
    {{ 'header.signOut' | translate }}
  </button>
</div>
