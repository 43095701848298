import { type Routes } from '@angular/router';

import { PermissionsGuard } from '@clover/core/guards/permissions.guard';
import { NetworkV2Component } from '@network/network-v2.component';

export const NETWORK_BASE_URL = 'network';

export const networkPageRoutes: Routes = [
  {
    path: '',
    component: NetworkV2Component,
    children: [
      {
        path: '',
        redirectTo: 'accounts',
        pathMatch: 'full',
      },
      {
        path: 'company',
        loadChildren: () => import('./company/routes').then(({ companyRoutes }) => companyRoutes),
        data: {
          permissions: ['Company_ViewCompanyProfile'],
        },
        canActivate: [PermissionsGuard],
      },
      {
        path: 'accounts',
        loadChildren: () => import('./network/accounts/routes').then(({ accountsRoutes }) => accountsRoutes),
        data: {
          permissions: ['CompanyConnections_View'],
        },
        canActivate: [PermissionsGuard],
      },
      {
        path: 'lists',
        loadChildren: () => import('./network/lists/routes').then(({ listsRoutes }) => listsRoutes),
        data: {
          permissions: ['CompanyList_View'],
        },
        canActivate: [PermissionsGuard],
      },
      {
        path: 'invites',
        loadChildren: () => import('./network/invites/routes').then(({ invitesRoutes }) => invitesRoutes),
        data: {
          permissions: ['CompanyInvitations_View'],
        },
        canActivate: [PermissionsGuard],
      },
      // {
      //   path: 'shared-contacts',
      //   component: ContactsComponent,
      // },
      // {
      //   path: 'private-contacts',
      //   component: ContactsComponent,
      // },
      // {
      //   path: 'other-contacts',
      //   component: ContactsComponent,
      // },
      // {
      //   path: 'merge-and-fix',
      //   component: MergeAndFixComponent,
      // },
      // {
      //   path: 'trash',
      //   component: TrashComponent,
      // },
    ],
  },
];
