import {
  MessageType,
  type Message,
} from '@clover/conversations-v4/conversation/state/conversation/conversation-state.model';

import type { AssistantThreadConversationContext } from '../assistant.entities';

export function createConversationContextMessage(context: AssistantThreadConversationContext): string {
  const { subject, messages } = context;

  const messagesInfo = messages
    .sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    })
    .map(formatMessage);

  return JSON.stringify(
    {
      subject: subject,
      messages: messagesInfo,
    },
    null,
    0,
  );
}

function formatMessage(message: Message): Record<string, unknown> {
  const { type, content, sender, participants, systemMetadata } = message;

  switch (type) {
    case MessageType.Text:
      return {
        messageType: 'text',
        messageContent: content,
        messageSender: {
          name: sender.name,
        },
      };

    case MessageType.Email:
      return {
        messageType: 'email',
        messageContent: content,
        messageParticipants: {
          from: {
            name: sender.name || participants.from.name,
            email: participants.from.email,
          },
          to: participants.to.map((participant) => ({
            name: participant.name,
            email: participant.email,
          })),
          cc: participants.cc.map((participant) => ({
            name: participant.name,
            email: participant.email,
          })),
          bcc: participants.bcc.map((participant) => ({
            name: participant.name,
            email: participant.email,
          })),
        },
      };

    case MessageType.System:
      return {
        messageType: 'system',
        messageContent: content,
        messageSystemMetadata: {
          actionType: systemMetadata.type,
          actionPerformer: systemMetadata?.performer?.name,
          actionTarget: systemMetadata?.assigneeUser?.name,
        },
      };
  }
}
