import type { LoadingStatus } from '@clover/core/helpers/loading';

export interface WorkspacesStateModel {
  workspaces: Workspace[];
  externalAccounts: ExternalAccount[];
  workspacePickerVisibility: 'visible' | 'hidden' | 'overlay';
  loadingStatus: LoadingStatus;
}

export const defaultWorkspacesState: WorkspacesStateModel = {
  workspaces: [],
  externalAccounts: [],
  workspacePickerVisibility: 'visible',
  loadingStatus: 'void',
};

export interface Workspace {
  id: number;
  name: string;
  ownerType: WorkspaceOwnerType;
  ownerId: number;
}

export enum WorkspaceOwnerType {
  User = 'user',
  Team = 'team',
}

export interface ExternalAccount {
  id: number;
  workspaceId: number;
  avatarUrl: string | undefined;
  name: string;
  email: string;
  providerType: ProviderType;
  lastActiveAt: string;
  status: ExternalAccountStatus;
  syncedThreads: number | undefined;
}

export enum ProviderType {
  Google = 'Google',
  Microsoft = 'Microsoft',
}

export enum ExternalAccountStatus {
  Initializing = 'Initializing',
  Expired = 'Expired',
  Disconnected = 'Disconnected',
  Disconnecting = 'Disconnecting',
  Syncing = 'Syncing',
  Active = 'Active',
  Failed = 'Failed',
}

export interface ExternalAccountState {
  workspaceId: string;
  actionType: ExternalAccountActionType;
}

export enum ExternalAccountActionType {
  Connect = 'Connect',
  Disconnect = 'Disconnect',
  Reconnect = 'Reconnect',
}

export enum ExternalAccountErrorCode {
  AlreadyConnected = 'account_is_already_connected_by_another_user',
  Disconnecting = 'account_disconnection_is_in_progress',
}

export interface WorkspaceMember {
  id: number;
  name: string;
  avatarUrl: string;
}
