import { computed, inject, Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { select } from '@ngxs/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import type { WorkspaceMember } from '@clover/conversations-v4/workspaces/state/workspaces/workspaces-state.model';
import { WorkspacesSelectors } from '@clover/conversations-v4/workspaces/state/workspaces/workspaces.selectors';
import {
  mapWorkspaceMember,
  type WorkspaceMemberResponse,
} from '@clover/conversations-v4/workspaces/state/workspaces/workspaces.service';
import { UserService } from '@clover/core/services/user.service';
import {
  CompactMessage,
  ConversationDetails,
  ConversationFolder,
  Draft,
  DraftAttachment,
  DraftMessageType,
  EmailParticipant,
  Participants,
  Message,
  MessageAttachment,
  MessageType,
  SystemMessageType,
  SystemMetadata,
  type ChannelConversationDetails,
  type ChannelMessageDraft,
  type DirectConversationDetails,
  type EmailConversationDetails,
  type EmailMessageDraft,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import {
  ConversationPerformer,
  ConversationStatus,
} from '@conversations/conversations/state/conversations/conversations-state.model';
import {
  mapConversationPerformer,
  UserResponse,
} from '@conversations/conversations/state/conversations/conversations.service';
import { LabelResponse } from '@conversations/workspaces/state/labels/labels.service';
import {
  getOffsetPagingOptionsParams,
  getPagingOptionsParams,
  PagingOptions,
  PagingWrapper,
  type OffsetPagingOptions,
  type OffsetPagingWrapper,
} from '@core/helpers/paging';
import { HttpService } from '@core/services/http.service';

import {
  createPendingDirectConversation,
  mapExistingDirectConversationToPending,
  type PendingConversationId,
} from './conversation.helpers';
import { ContactType } from '../contacts/contacts.model';

interface ConversationDetailsResponse {
  id: string;
  name: string;
  workspaceId: number;
  externalAccountId: number | undefined;
  lastEmailMessage: MessageResponse | undefined;
  lastMessage: MessageResponse | undefined;
  lastMessageDraft: DraftResponse | undefined;
  tags: LabelResponse[];
  createdAt: string;
  updatedAt: string;
  assignee: WorkspaceMemberResponse | undefined;
  members: WorkspaceMemberResponse[];
  isPrioritized: boolean;
  isSnoozed: boolean;
  status: ConversationStatus;
  snoozedUntil: string | undefined;
  isSpam: boolean;
  isTrash: boolean;
  isInbox: boolean;
  isArchive: boolean;
  isNoReply: boolean;
}

export interface MessageResponse {
  id: string;
  cid: string;
  workspaceId: number;
  externalAccountId: number;
  messageType: MessageType;
  text: string;
  snippet: string;
  quotedContent: string | undefined;
  sender: UserResponse | undefined;
  attachments: MessageAttachmentResponse[] | undefined;
  attachedTasks: AttachedTaskResponse[] | undefined;
  hasAttachments: boolean;
  metadata: MessageMetadataResponse;
  drafts: DraftResponse[] | undefined;
  replyToMessageInfo: CompactMessageResponse | undefined;
  createdAt: string;
}

export interface MessageAttachmentResponse {
  fileId: string;
  name: string;
  contentType: string;
  size: number;
}

export interface DraftAttachmentResponse {
  id: number;
  name: string;
  contentType: string;
  size: number;
}

interface AttachedTaskResponse {
  taskId: number;
}

interface MessageMetadataResponse {
  from: EmailParticipantResponse[];
  to: EmailParticipantResponse[];
  cc: EmailParticipantResponse[];
  bcc: EmailParticipantResponse[];
  messageSystemType: SystemMessageType | undefined;
  assigneeUser: UserResponse | undefined;
  performer: UserResponse | undefined;
  members: UserResponse[];
}

interface EmailParticipantResponse {
  name: string;
  email: string;
  logoUrl: string | undefined;
}

export interface DraftResponse {
  id: number;
  workspaceId: number;
  externalAccountId: number;
  streamConversationId: string | undefined;
  subject: string;
  content: string;
  quotedContent: string;
  signatureContent: string;
  attachments: DraftAttachmentResponse[] | undefined;
  attachedTasks: AttachedTaskResponse[] | undefined;
  to: EmailParticipantResponse[];
  cc: EmailParticipantResponse[];
  bcc: EmailParticipantResponse[];
  type: DraftMessageType;
  replyToMessageInfo: CompactMessageResponse | undefined;
  updatedAt: string;
  createdAt: string;
}

interface CompactMessageResponse {
  streamMessageId: string;
  messageType: MessageType;
  snippet: string;
  metadata: MessageMetadataResponse;
  sender: UserResponse;
  createdAt: string;
}

export type MessagesSortingProperty = 'created_at';

const systemSender: ConversationPerformer = {
  id: 'system',
  name: 'Clover',
  avatarUrl: 'assets/svg/common/clover.svg',
  type: ContactType.CloverUser,
};

function mapConversationDetails(details: ConversationDetailsResponse, privateWorkspaceId: number): ConversationDetails {
  const isEmailConversation = !!details.externalAccountId;
  const isDirectConversation = !isEmailConversation && !details.workspaceId;

  if (isEmailConversation) return mapEmailConversationDetails(details);
  if (isDirectConversation) return mapDirectConversationDetails(details, privateWorkspaceId);

  return mapChannelConversationDetails(details);
}

function mapEmailConversationDetails(details: ConversationDetailsResponse): EmailConversationDetails {
  return {
    id: details.id,
    type: 'email',
    subject: details.name,
    workspaceId: details.workspaceId,
    externalAccountId: details.externalAccountId,
    labelIds: details.tags.map((tag) => tag.id),
    lastDraft: mapDraft(details.lastMessageDraft) as EmailMessageDraft,

    // TODO (Oleksandr D.): Remove this mapping chain when BE returns compact message in the response.
    lastMessage: transformMessageToCompactMessage(mapMessage(details.lastMessage)),
    lastReplyableMessage: transformMessageToCompactMessage(mapMessage(details.lastEmailMessage)),

    replyForbidden: details.isNoReply || false,
    folder: mapFolder(details),
    status: details.status,
    assignee: details.assignee ? mapWorkspaceMember(details.assignee) : undefined,
    members: details.members.map(mapWorkspaceMember),
    prioritized: details.isPrioritized,
    snoozedUntil: details.snoozedUntil,
    createdAt: details.createdAt,
    updatedAt: details.updatedAt,
  };
}

function mapChannelConversationDetails(details: ConversationDetailsResponse): ChannelConversationDetails {
  return {
    id: details.id,
    type: 'channel',
    subject: details.name,
    workspaceId: details.workspaceId,
    labelIds: details.tags.map((tag) => tag.id),
    lastDraft: mapDraft(details.lastMessageDraft) as ChannelMessageDraft,

    // TODO (Oleksandr D.): Remove this mapping chain when BE returns compact message in the response.
    lastMessage: details.lastMessage && transformMessageToCompactMessage(mapMessage(details.lastMessage)),

    replyForbidden: details.isNoReply || false,
    status: details.status,
    members: details.members.map(mapWorkspaceMember),
    prioritized: details.isPrioritized,
    snoozedUntil: details.snoozedUntil,
    createdAt: details.createdAt,
    updatedAt: details.updatedAt,
  };
}

function mapDirectConversationDetails(
  details: ConversationDetailsResponse,
  privateWorkspaceId: number,
): DirectConversationDetails {
  return {
    id: details.id,
    type: 'direct',
    subject: details.name,
    workspaceId: privateWorkspaceId,
    labelIds: details.tags.map((tag) => tag.id),
    lastDraft: mapDraft(details.lastMessageDraft) as ChannelMessageDraft,

    // TODO (Oleksandr D.): Remove this mapping chain when BE returns compact message in the response.
    lastMessage: details.lastMessage && transformMessageToCompactMessage(mapMessage(details.lastMessage)),

    replyForbidden: details.isNoReply || false,
    status: details.status,
    members: details.members.map(mapWorkspaceMember),
    prioritized: details.isPrioritized,
    snoozedUntil: details.snoozedUntil,
    createdAt: details.createdAt,
    updatedAt: details.updatedAt,
  };
}

export function mapMessage(message: MessageResponse): Message {
  const conversationId = mapConversationId(message.cid);

  return {
    id: message.id,
    conversationId,
    type: message.messageType,
    state: 'sent',
    content: message.text,
    snippet: message.snippet,
    quote: message.quotedContent || '',
    sender: message.messageType !== MessageType.System ? mapConversationPerformer(message.sender) : systemSender,
    participants: mapEmailMetadata(message.metadata),
    systemMetadata: message.messageType === MessageType.System ? mapSystemMetadata(message.metadata) : undefined,
    tasksIds: message.attachedTasks?.map((task) => task.taskId) || [],
    attachments: message.hasAttachments ? message.attachments?.map(mapMessageAttachment) || [] : [],
    drafts: message.drafts?.map((draft) => mapDraft(draft, message) as EmailMessageDraft) || [],
    replyToMessage: message.replyToMessageInfo
      ? mapCompactMessage(message.replyToMessageInfo, conversationId)
      : undefined,
    createdAt: message.createdAt,
    updatedAt: message.createdAt,
  };
}

function mapConversationId(cid: string): string {
  return cid.split('messaging:')[1]; // TODO: Remove this when conversationId is available in the response.
}

function mapEmailMetadata(metadata: MessageMetadataResponse): Participants | undefined {
  if (!metadata) return undefined;

  return {
    from: metadata.from.map(mapParticipant)[0],
    to: metadata.to.map(mapParticipant),
    cc: metadata.cc.map(mapParticipant),
    bcc: metadata.bcc.map(mapParticipant),
  };
}

function mapSystemMetadata(metadata: MessageMetadataResponse): SystemMetadata | undefined {
  if (!metadata?.messageSystemType) return undefined;

  return {
    type: metadata.messageSystemType,
    performer: metadata.performer ? mapConversationPerformer(metadata.performer) : undefined,
    assigneeUser: metadata.assigneeUser ? mapConversationPerformer(metadata.assigneeUser) : undefined,
    members: metadata.members.map(mapConversationPerformer),
  };
}

export function mapDraft(draft: DraftResponse | undefined, baseMessage?: MessageResponse): Draft | undefined {
  if (!draft) return undefined;

  // Removing drafts from base message to avoid circular references.
  baseMessage = baseMessage && {
    ...baseMessage,
    drafts: undefined,
  };

  return {
    id: draft.id,
    externalAccountId: draft.externalAccountId,
    conversationId: draft.streamConversationId,
    subject: draft.subject,
    type: draft.type,
    content: draft.content,
    quote: draft.quotedContent,
    signature: draft.signatureContent,
    participants:
      draft.type === DraftMessageType.Note
        ? undefined
        : {
            to: draft.to.map(mapParticipant),
            cc: draft.cc.map(mapParticipant),
            bcc: draft.bcc.map(mapParticipant),
          },
    tasksIds: draft.attachedTasks?.map((task) => task.taskId) || [],
    attachments: draft.attachments?.map(mapDraftAttachment) || [],
    replyToMessage:
      draft.replyToMessageInfo && draft.streamConversationId
        ? mapCompactMessage(draft.replyToMessageInfo, draft.streamConversationId)
        : baseMessage
          ? transformMessageToCompactMessage(mapMessage(baseMessage))
          : undefined,
    createdAt: draft.createdAt,
    updatedAt: draft.updatedAt,
  };
}

function mapParticipant(participant: EmailParticipantResponse): EmailParticipant {
  return {
    name: participant.name,
    email: participant.email,
    avatarUrl: participant.logoUrl || undefined,
  };
}

export function mapMessageAttachment(attachment: MessageAttachmentResponse): MessageAttachment {
  return {
    id: attachment.fileId,
    name: attachment.name,
    size: attachment.size,
    mimeType: attachment.contentType,
  };
}

export function mapDraftAttachment(attachment: DraftAttachmentResponse): DraftAttachment {
  return {
    id: attachment.id,
    name: attachment.name,
    size: attachment.size,
    mimeType: attachment.contentType,
  };
}

function mapFolder(details: ConversationDetailsResponse): ConversationFolder {
  if (details.isInbox) return ConversationFolder.Inbox;
  if (details.isArchive) return ConversationFolder.Archive;
  if (details.isTrash) return ConversationFolder.Trash;
  if (details.isSpam) return ConversationFolder.Spam;

  // This shouldn't happen, but just in case all flags are false, we default to Inbox.
  return ConversationFolder.Inbox;
}

function mapCompactMessage(
  compactMessage: CompactMessageResponse,
  conversationId: string, // BE cannot provide conversationId in the compact message response, so we need to pass it manually.
): CompactMessage {
  return {
    id: compactMessage.streamMessageId,
    conversationId,
    type: compactMessage.messageType,
    snippet: compactMessage.snippet,
    participants: mapEmailMetadata(compactMessage.metadata),
    sender: mapConversationPerformer(compactMessage.sender),
    createdAt: compactMessage.createdAt,
  };
}

export function transformMessageToCompactMessage(message: Message): CompactMessage {
  return {
    id: message.id,
    conversationId: message.conversationId,
    type: message.type,
    snippet: message.snippet,
    participants: message.participants,
    sender: message.sender,
    createdAt: message.createdAt,
  };
}

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  private readonly http = inject(HttpService);
  private readonly router = inject(Router);
  private readonly serializer = inject(UrlSerializer);

  private readonly userService = inject(UserService);

  private readonly privateWorkspace = select(WorkspacesSelectors.privateWorkspace);
  private readonly privateWorkspaceId = computed(() => this.privateWorkspace().id);

  getConversationDetails(conversationId: string): Observable<ConversationDetails> {
    return this.http
      .getV2<ConversationDetailsResponse>(`/api/stream-conversations/${conversationId}/details`)
      .pipe(map((response) => mapConversationDetails(response, this.privateWorkspaceId())));
  }

  getNewDirectConversationDetails(
    id: PendingConversationId,
    memberIds: number[] = [],
  ): Observable<DirectConversationDetails> {
    if (!memberIds.length) return of(createPendingDirectConversation(id, this.privateWorkspaceId(), []));

    const urlTree = this.router.createUrlTree(['api', 'stream-conversations', 'direct', 'details'], {
      queryParams: {
        membersIds: memberIds,
      },
    });

    const path = this.serializer.serialize(urlTree);
    return forkJoin({
      detailsResponse: this.http.getV2<ConversationDetailsResponse>(path).pipe(catchError(() => of(undefined))),
      members: this.getPendingDirectConversationMembers(memberIds),
    }).pipe(
      map(({ detailsResponse, members }) => {
        if (!detailsResponse) return createPendingDirectConversation(id, this.privateWorkspaceId(), members);

        const details = mapDirectConversationDetails(detailsResponse, this.privateWorkspaceId());
        return mapExistingDirectConversationToPending(id, details);
      }),
    );
  }

  getMessages(
    conversationId: string,
    pagingOptions?: PagingOptions<MessagesSortingProperty>,
    fromDateIso?: string,
    toDateIso?: string,
  ): Observable<PagingWrapper<Message>> {
    const urlTree = this.router.createUrlTree(['api', 'stream-conversations', conversationId, 'messages', 'search'], {
      queryParams: {
        from: fromDateIso,
        to: toDateIso,
        ...getPagingOptionsParams(pagingOptions),
      },
    });

    const path = this.serializer.serialize(urlTree);
    return this.http
      .getV2<PagingWrapper<MessageResponse>>(path)
      .pipe(map((response) => ({ ...response, data: response.data.map(mapMessage) })));
  }

  getMessage(conversationId: string, messageId: string): Observable<Message> {
    return this.http
      .getV2<MessageResponse>(`/api/stream-conversations/${conversationId}/messages/${messageId}`)
      .pipe(map(mapMessage));
  }

  createDirectConversation(message: string, memberIds: number[]): Observable<DirectConversationDetails> {
    return this.http
      .postV2<{ id: string }>(`/api/stream-conversations/direct`, {
        message,
        membersUserIds: memberIds,
      })
      .pipe(switchMap(({ id }) => this.getConversationDetails(id) as Observable<DirectConversationDetails>));
  }

  getConversationMembers(
    conversationId: string,
    pagingOptions?: OffsetPagingOptions<never>,
  ): Observable<OffsetPagingWrapper<WorkspaceMember>> {
    const urlTree = this.router.createUrlTree(['api', 'stream-conversations', conversationId, 'members'], {
      queryParams: {
        ...getOffsetPagingOptionsParams(pagingOptions),
      },
    });

    const path = this.serializer.serialize(urlTree);
    return this.http
      .getV2<OffsetPagingWrapper<WorkspaceMemberResponse>>(path)
      .pipe(map((response) => ({ ...response, data: response.data.map(mapWorkspaceMember) })));
  }

  private getPendingDirectConversationMembers(memberIds: number[]): Observable<WorkspaceMember[]> {
    memberIds = [...new Set([...memberIds, this.userService.userProfile.id])];

    return of(
      memberIds.map((id) => ({
        id,
        name: '', // TODO: Map to real member name when available.
        avatarUrl: '',
      })),
    );
  }
}
