@defer {
  <div class="channel-create-dialog">
    <div class="channel-create-dialog__header">
      <div class="channel-create-dialog__header-title">
        <div class="channel-create-dialog__header-title-text">
          {{ 'conversations-v4.workspaces.dialogs.createChannel.title' | translate }}
        </div>
      </div>

      <div class="channel-create-dialog__header-description">
        {{ 'conversations-v4.workspaces.dialogs.createChannel.description' | translate }}
      </div>
    </div>

    <div
      class="channel-create-dialog__content--wrapper"
      [ccAutoAnimate]
    >
      <ng-scrollbar
        class="channel-create-dialog__content--scrollbar"
        (scrolled)="loadNextMembers$.next()"
        [infiniteScrollDistance]="4"
        [infiniteScrollThrottle]="0"
        [infiniteScrollUpDistance]="4"
        [scrollWindow]="false"
        cdkScrollable
        infiniteScroll
      >
        <div class="channel-create-dialog__content">
          <ng-container [formGroup]="form">
            <app-textbox
              [errorMessage]="form.controls.name.invalid && form.controls.name.dirty && nameErrorTemplate"
              formControlName="name"
              [type]="TextboxType.Text"
              [required]="true"
              [label]="'conversations-v4.workspaces.dialogs.createChannel.name.title' | translate"
              [placeholder]="'conversations-v4.workspaces.dialogs.createChannel.name.placeholder' | translate"
              width="100%"
            />

            <ng-template #nameErrorTemplate>
              @if (form.controls.name.errors['required']) {
                {{ 'conversations-v4.workspaces.dialogs.createChannel.name.requiredError' | translate }}
              } @else if (form.controls.name.errors['channelNameInvalid']) {
                {{ 'conversations-v4.workspaces.dialogs.createChannel.name.formatError' | translate }}
                <br />
                <span class="cc-channel-name-suggestion">
                  {{ 'conversations-v4.workspaces.dialogs.createChannel.name.didYouMean' | translate }}
                  <span
                    class="cc-channel-name-suggestion__name"
                    (click)="acceptNameSuggestion()"
                  >
                    {{ form.controls.name.errors['channelNameSuggestion'] }}</span
                  >?
                </span>
              } @else if (form.controls.name.errors['channelNameExists']) {
                {{ 'conversations-v4.workspaces.dialogs.createChannel.name.uniqueError' | translate }}
              }
            </ng-template>

            <cc-select
              formControlName="workspace"
              [required]="true"
              [placeholder]="'conversations-v4.workspaces.dialogs.createChannel.workspace.placeholder' | translate"
            >
              <ng-container ccTitle>
                {{ 'conversations-v4.workspaces.dialogs.createChannel.workspace.title' | translate }}
              </ng-container>
              <ng-container ccOptions>
                <cc-dropdown-text>
                  <ng-container slot="title">
                    {{ 'conversations-v4.workspaces.dialogs.createChannel.workspace.teamWorkspaces' | translate }}
                  </ng-container>
                </cc-dropdown-text>

                @for (option of workspacesSelectOptions(); track option.id) {
                  <cc-select-item
                    [id]="option.id"
                    [value]="option.title"
                    [payload]="option.payload"
                  />
                }
              </ng-container>
            </cc-select>
          </ng-container>

          <div [ccAutoAnimate]>
            @if (['loaded', 'loading-next'].includes(membersLoadingStatus())) {
              <cc-table ccPadding="small">
                <ng-container slot="cc-table-title">
                  <div class="company-contacts__table-title">
                    {{ 'conversations-v4.workspaces.dialogs.createChannel.members.title' | translate }}
                  </div>
                </ng-container>

                <ng-container slot="cc-table">
                  <thead>
                    <tr>
                      <th ccTh>
                        {{ 'conversations-v4.workspaces.dialogs.createChannel.members.name' | translate }}
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    @for (member of members().data; track member.id) {
                      <tr
                        ccTr
                        (click)="toggleMember(member.id)"
                        [ccClickable]="true"
                        [ccDisabled]="member.id === currentUserId || form.disabled"
                      >
                        <td ccTd>
                          <div class="name-cell">
                            <app-checkbox
                              class="name-cell__checkbox"
                              [checked]="member.id === currentUserId || selectedMembers().includes(member.id)"
                            />

                            <cc-user-avatar
                              [id]="member.id"
                              [src]="member.avatarUrl"
                              [name]="member.name"
                              [size]="24"
                            />

                            <div>
                              {{ member.name }}
                            </div>
                          </div>
                        </td>
                      </tr>
                    }
                  </tbody>
                </ng-container>
              </cc-table>
            }
          </div>
        </div>
      </ng-scrollbar>
    </div>

    <div class="channel-create-dialog__actions">
      <app-button
        (click)="dialogRef.close()"
        [type]="ButtonType.Secondary"
        [size]="ButtonSize.Small"
      >
        {{ 'common.buttons.cancel' | translate }}
      </app-button>

      <app-button
        (click)="createChannel()"
        [disabled]="form.invalid || form.disabled"
        [type]="ButtonType.Primary"
        [size]="ButtonSize.Small"
      >
        {{ 'conversations-v4.workspaces.dialogs.createChannel.createChannel' | translate }}
      </app-button>
    </div>
  </div>
}
