// src/classes/utils.ts
function digSet(obj, value, ...keys) {
  const base = obj || {};
  let current = base;
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!current[key] || typeof current[key] !== "object") {
      current[key] = {};
    }
    current = current[key];
  }
  const finalKey = keys[keys.length - 1];
  current[finalKey] = value;
  return base;
}

// src/classes/FlatfileRecord.ts
var propExists = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);
var FlatfileRecord = class {
  constructor(raw) {
    this._info = [];
    this._config = {};
    this.mutated = Object.assign({}, raw.rawData);
    this.data = Object.assign({}, raw.rawData);
    this.metadata = Object.assign({}, raw.metadata);
    this._rowId = raw.rowId;
  }
  get rowId() {
    return this._rowId;
  }
  get originalValue() {
    return this.data;
  }
  get value() {
    return this.mutated;
  }
  /**
   * Make the entire record or specific fields readonly by a user.
   *
   * @example ```js
   * record.setReadOnly() // make entire record readonly
   * ```
   *
   * @example ```js
   * record.setReadOnly('firstname', 'lastname') // make two fields readonly
   * ```
   * @param fields
   */
  setReadOnly(...fields) {
    if (!fields.length) {
      this._config = digSet(this._config, true, "readonly");
    }
    fields.forEach((field) => {
      this._config = digSet(this._config, true, "fields", field, "readonly");
    });
  }
  /**
   * Make the entire record or specific fields writable by a user.
   *
   * @example ```js
   * record.setWritable() // make entire record writable
   * ```
   *
   * @example ```js
   * record.setWritable('firstname', 'lastname') // make two fields writable
   * ```
   * @param fields
   */
  setWritable(...fields) {
    if (!fields.length) {
      this._config = digSet(this._config, false, "readonly");
    }
    fields.forEach((field) => {
      this._config = digSet(this._config, false, "fields", field, "readonly");
    });
  }
  /**
   * Return the current state of the record as simple key value pairs.
   */
  get obj() {
    return Object.fromEntries(
      Object.entries(this.mutated).map(([key, value]) => [
        key,
        typeof value === "object" && value && "value" in value ? value.value : value
      ])
    );
  }
  verifyField(field, data) {
    return propExists(data || this.data, field);
  }
  isLinkedField(field) {
    const fieldValue = this.mutated[field];
    return !!fieldValue && typeof fieldValue === "object" && propExists(fieldValue, "value") && propExists(fieldValue, "links");
  }
  set(field, value) {
    if (!this.verifyField(field)) {
      console.error(`Record does not have field "${field}".`);
      return this;
    }
    const isLinked = this.isLinkedField(field);
    if (isLinked) {
      const fieldValue = this.mutated[field];
      if (!!fieldValue && typeof fieldValue === "object" && propExists(fieldValue, "value") && fieldValue.value === value) {
        return;
      } else {
        Object.defineProperty(this.mutated, field, {
          value: {
            value
          }
        });
      }
    } else {
      Object.defineProperty(this.mutated, field, {
        value
      });
    }
    return this;
  }
  setMetadata(data) {
    Object.defineProperty(this, "metadata", { value: data });
    return this;
  }
  setLinkedValue(linkedFieldKey, childKey, value) {
    if (!this.verifyField(linkedFieldKey)) {
      return this;
    }
    Object.assign(this.mutated, { [`${linkedFieldKey}::${childKey}`]: value });
    return this;
  }
  get(field) {
    if (this.verifyField(field)) {
      const value = this.mutated[field];
      if (!!value && typeof value === "object" && propExists(value, "value")) {
        return value.value;
      }
      return value;
    }
    return null;
  }
  getMetadata() {
    return this.metadata;
  }
  getLinks(field) {
    if (this.verifyField(field)) {
      const fieldValue = this.mutated[field];
      if (fieldValue && typeof fieldValue === "object") {
        const { links } = fieldValue;
        if (links) {
          return links;
        }
      }
    }
    console.error("Field has no links.");
    return null;
  }
  getLinkedValue(linkedFieldKey, childKey) {
    var _a;
    if (this.verifyField(linkedFieldKey)) {
      return (_a = this.mutated[`${linkedFieldKey}::${childKey}`]) != null ? _a : null;
    }
    return null;
  }
  addInfo(fields, message, listItem) {
    return this.pushInfoMessage(fields, message, "info", "other", listItem);
  }
  /**
   * @alias addInfo
   */
  addComment(fields, message, listItem) {
    return this.addInfo(fields, message, listItem);
  }
  addError(fields, message, listItem) {
    return this.pushInfoMessage(fields, message, "error", "other", listItem);
  }
  addWarning(fields, message, listItem) {
    return this.pushInfoMessage(fields, message, "warn", "other", listItem);
  }
  pushInfoMessage(fields, message, level, stage, listItem) {
    fields = Array.isArray(fields) ? fields : [fields];
    fields.forEach((field) => {
      if (this.verifyField(field)) {
        let path = void 0;
        if (listItem) {
          const fieldValue = this.get(field);
          if (fieldValue && typeof fieldValue === "object") {
            const index = fieldValue.findIndex((value) => value === listItem);
            path = `$.value[${index}]`;
          }
        }
        this._info.push({
          field,
          message,
          level,
          stage,
          path
        });
      }
    });
    return this;
  }
  compute(field, transformation, message) {
    this.set(field, transformation(this.get(field), this));
    if (message) {
      this.addComment(field, message);
    }
    return this;
  }
  computeIfPresent(field, transformation, message) {
    if (this.get(field)) {
      this.compute(field, transformation, message);
    }
    return this;
  }
  validate(field, validator, message) {
    if (!validator(this.get(field), this)) {
      this.addError(field, message);
    }
    return this;
  }
  toJSON() {
    return {
      row: {
        rawData: this.mutated,
        rowId: this.rowId,
        metadata: this.metadata
      },
      config: this._config,
      info: this._info
    };
  }
};

// src/classes/FlatfileRecords.ts
var FlatfileRecords = class {
  constructor(rawRecords) {
    this._records = rawRecords.map(
      (rawRecord) => new FlatfileRecord(rawRecord)
    );
  }
  get records() {
    return this._records;
  }
  toJSON() {
    return this.records.map((record) => record.toJSON());
  }
};

// src/classes/FlatfileSession.ts
var FlatfileSession = class {
  constructor(payload) {
    this.payload = payload;
  }
  get workspaceId() {
    return this.payload.workspaceId;
  }
  get workbookId() {
    return this.payload.workbookId;
  }
  get schemaId() {
    return this.payload.schemaId;
  }
  get schemaSlug() {
    return this.payload.schemaSlug;
  }
  get uploads() {
    return this.payload.uploads;
  }
  get endUser() {
    return this.payload.endUser;
  }
  get rows() {
    return this.payload.rows;
  }
  get env() {
    return this.payload.env;
  }
  get envSignature() {
    return this.payload.envSignature;
  }
};
export {
  FlatfileRecord,
  FlatfileRecords,
  FlatfileSession
};
