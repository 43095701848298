import type { HttpErrorResponse } from '@angular/common/http';
import { inject, Injector } from '@angular/core';
import { ResolveFn, Router } from '@angular/router';
import { catchError, from, map, of, switchMap, tap } from 'rxjs';

import type { LoginData } from '@clover/core/models/loginData';
import { ToastrService } from '@clover/core/services/toastr.service';

import { AuthService, SsoLoginErrorCode } from '../auth.service';

export const ssoResolver: ResolveFn<boolean> = (route) => {
  const token = parseTokenFromFragment(route.fragment);
  if (!token) return false;

  const authService = inject(AuthService);
  const router = inject(Router);
  const injector = inject(Injector);

  return authService.verifySSOToken(token).pipe(
    switchMap((loginData: LoginData) => {
      return from(authService.handleSuccessfullLogin(loginData)).pipe(
        tap((loginSuccess) => !loginSuccess && router.navigate(['/', 'login'])),
      );
    }),
    map(() => false),
    catchError((errorResponse) => {
      handleTokenVerificationError(injector, errorResponse);
      router.navigate(['/', 'login']);

      return of(false);
    }),
  );
};

function parseTokenFromFragment(fragment: string = ''): string | undefined {
  const params = new URLSearchParams(fragment);
  return params.get('id_token') || undefined;
}

function handleTokenVerificationError(injector: Injector, errorResponse: HttpErrorResponse): void {
  const errorCodes: string[] =
    errorResponse?.error?.errors?.map((error: { errorCode: string }) => error?.errorCode) || [];

  const toastr = injector.get(ToastrService);

  if (errorCodes.includes(SsoLoginErrorCode.UserNotFound)) {
    toastr.error('signIn.errors.ssoUserNotFound');
  }
}
