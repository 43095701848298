import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  output,
  ViewEncapsulation,
  input,
  computed,
  untracked,
  effect,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

import { ComposerHtmlEditorComponent } from '@conversations/composer/composer-quote-input/composer-html-editor/composer-html-editor.component';
import { SaveDraft, SetQuote } from '@conversations/composer/state/composers/composers.actions';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

import type { ComposerInstance } from '../state/composers/composers-state.model';

@Component({
  selector: 'cc-composer-quote-input',
  standalone: true,
  templateUrl: './composer-quote-input.component.html',
  styleUrls: ['./composer-quote-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [ReactiveFormsModule, ComposerHtmlEditorComponent, ButtonComponent, TooltipDirective, TranslateModule],
})
export class ComposerQuoteInputComponent {
  readonly composer = input.required<ComposerInstance>();
  readonly quote = input<string>(undefined);

  focusIn = output<void>();

  protected readonly composerId = computed(() => this.composer().id);
  protected readonly draftId = computed(() => this.composer().draft?.id || undefined);

  private readonly draftId$ = toObservable(this.draftId);

  protected collapsed = true;
  protected quoteFormControl = new FormControl<string>(undefined);

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TooltipAlignment = TooltipAlignment;

  private readonly store = inject(Store);
  private readonly destroyRef = inject(DestroyRef);

  @HostBinding('class.cc-expanded')
  get expanded(): boolean {
    return !this.collapsed;
  }

  constructor() {
    this.initUpdateHandler();

    effect(() => {
      const quote = this.quote();

      untracked(() => {
        this.quoteFormControl.setValue(quote, { emitEvent: false });
      });
    });
  }

  private initUpdateHandler(): void {
    this.draftId$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(() =>
          this.quoteFormControl.valueChanges.pipe(
            distinctUntilChanged(),
            tap((quote) => this.store.dispatch(new SetQuote(this.composerId(), quote))),
            debounceTime(300),
            switchMap(() => this.store.dispatch(new SaveDraft(this.composerId()))),
          ),
        ),
      )
      .subscribe();
  }
}
