import { createSelector, Selector } from '@ngxs/store';

import { getAllowedMessageTypesByReplyToMessage } from '@conversations/composer/state/composers/composers-state.helpers';
import {
  ComposerInstance,
  ComposerMessageType,
  ComposersStateModel,
} from '@conversations/composer/state/composers/composers-state.model';
import { ComposersState } from '@conversations/composer/state/composers/composers.state';
import { ExternalAccount } from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';

export class ComposersSelectors {
  @Selector([ComposersState])
  static composers(state: ComposersStateModel): ComposerInstance[] {
    return state.composers;
  }

  @Selector([ComposersSelectors.composers])
  static overlayComposers(composers: ComposerInstance[]): ComposerInstance[] {
    return composers.filter((composer) => ['overlay-expanded', 'overlay-collapsed'].includes(composer.presentation));
  }

  static composerById(composerId: string) {
    return createSelector([ComposersSelectors.composers], (composers: ComposerInstance[]) => {
      return composers.find((composer) => composer.id === composerId);
    });
  }

  static composerByConversationId(conversationId: string) {
    return createSelector([ComposersSelectors.composers], (composers: ComposerInstance[]) => {
      return composers.find(
        (composer) =>
          composer.composerType !== 'newEmail' &&
          composer.composerType !== 'newDirect' &&
          composer.conversationId === conversationId,
      );
    });
  }

  static composerByPendingConversationId(pendingConversationId: string) {
    return createSelector([ComposersSelectors.composers], (composers: ComposerInstance[]) => {
      return composers.find(
        (composer) => composer.composerType === 'newDirect' && composer.pendingConversationId === pendingConversationId,
      );
    });
  }

  static composerByDraftId(draftId: number) {
    return createSelector([ComposersSelectors.composers], (composers: ComposerInstance[]) => {
      return composers.find((composer) => composer.draft?.id === draftId);
    });
  }

  static allowedMessageTypes(composerId: string) {
    return createSelector(
      [ComposersSelectors.composerById(composerId), WorkspacesSelectors.externalAccounts],
      (composer: ComposerInstance, externalAccounts: ExternalAccount[]): ComposerMessageType[] => {
        if (!composer) return [];

        if (composer.composerType === 'newEmail') return ['new'];
        if (composer.composerType === 'channel') return ['internal'];
        if (composer.composerType === 'newDirect') return ['internal'];
        if (composer.composerType === 'direct') return ['internal'];

        return getAllowedMessageTypesByReplyToMessage(
          composer.replyToMessage,
          externalAccounts.find((account) => account.id === composer.senderAccountId).email,
        );
      },
    );
  }
}
