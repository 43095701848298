import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface ScrollToMessageEvent {
  messageId: string | number;
  messageType: 'message' | 'draft' | 'pending';
  reason: 'new-message-arrived' | 'jumped-to-message';
  highlight?: boolean; // Default: false
  behaviour?: 'smooth' | 'instant'; // Default: 'smooth'
}

@Injectable({
  providedIn: 'root',
})
export class ConversationScrollService {
  private _scrollToMessage$ = new Subject<ScrollToMessageEvent>();

  get scrollToMessage$(): Observable<ScrollToMessageEvent> {
    return this._scrollToMessage$.asObservable();
  }

  triggerScrollToMessageEvent(event: ScrollToMessageEvent): void {
    event = {
      highlight: false,
      behaviour: 'smooth',
      ...event,
    };

    this._scrollToMessage$.next(event);
  }
}
