import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';

export function withOrderedList() {
  return [
    OrderedList.configure({
      HTMLAttributes: {
        style: ['margin: 0', 'padding-inline-start: 1em', 'padding-left: 1em'].join('; '),
      },
    }),
    ListItem,
  ];
}
