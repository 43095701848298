import { Component, type OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { PAGE_URL } from '@core/constants/page-url';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { RegistrationStatuses, type User } from '@core/models/user';
import { ToastrService } from '@core/services/toastr.service';
import { UserService } from '@core/services/user.service';

import { CompanyTypeAnswers } from './company-type-answers';
import { LegacyLoaderComponent } from '../../../core/components/loader/loader.component';
import { AuthService } from '../../auth.service';

@UntilDestroy()
@Component({
  selector: 'app-company-type-select',
  templateUrl: './company-type-select.component.html',
  styleUrls: ['./company-type-select.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective, LegacyLoaderComponent, ButtonLoadingDirective, TranslateModule],
})
export class CompanyTypeSelectComponent implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  private readonly userService = inject(UserService);
  private readonly toastr = inject(ToastrService);
  public isLoaded = false;
  public isSendingAnswer = false;

  public selectedType: string;

  private user: User;

  public get companyTypeOptions() {
    return CompanyTypeAnswers;
  }

  public ngOnInit(): void {
    this.user = this.userService.userProfile;
    if (!this.user) {
      this.router.navigate([PAGE_URL.LOGIN]);
      return;
    }

    if (this.user.registrationStatus !== RegistrationStatuses.companyTypeSelection) {
      this.router.navigate(['/']);
      return;
    }

    this.isLoaded = true;
  }

  public continue(): void {
    this.isSendingAnswer = true;
    this.authService
      .setLookingForCompanyType({
        companyType: this.selectedType,
      })
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.userService.profile$.next(res);
          this.router.navigate([PAGE_URL.COMPANY_SELECTION]);
        }),
        catchError((err) => {
          this.toastr.displayServerErrors(err);
          return of(err);
        }),
        finalize(() => (this.isSendingAnswer = false)),
      )
      .subscribe();
  }
}
