import { CdkTrapFocus } from '@angular/cdk/a11y';
import { CdkScrollableModule } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChildren,
  DestroyRef,
  ElementRef,
  HostListener,
  inject,
  Input,
  QueryList,
  Renderer2,
  viewChild,
  ViewChild,
  type AfterViewInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';
import { map, startWith, tap } from 'rxjs/operators';

import { ResizeDirective, ResizeEvent } from '@core/directives/resize.directive';
import { DOCUMENT } from '@core/helpers/global-objects';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';

@Component({
  selector: 'cc-dropdown',
  standalone: true,
  imports: [CdkScrollableModule, NgScrollbarModule, CdkTrapFocus, ResizeDirective],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements AfterViewInit {
  @Input()
  width = '240px';

  @Input()
  maxHeight = 'auto';

  @Input()
  shadow = true;

  @Input()
  fakeFocus = false; // This should be disabled in autocomplete dropdowns, to prevent the input from losing focus.

  @ContentChildren(DropdownActionComponent)
  actions: QueryList<DropdownActionComponent>;

  @ViewChild('dropdownContent', { static: true, read: ElementRef })
  dropdownContent: ElementRef<HTMLDivElement> | null = null;

  private readonly scrollbarRef = viewChild.required(NgScrollbar);

  private readonly document = inject(DOCUMENT);
  private readonly renderer = inject(Renderer2);
  private readonly destroyRef = inject(DestroyRef);

  hasChildDropdownActive$ = computed(() => {
    return this.actions.some((action) => action.childDropdownVisible$());
  });

  ngAfterViewInit(): void {
    this.listenForFocusRequests();
  }

  captureFocus(): void {
    if (!this.actions) return;

    const selectableItems = this.getFocusableItems();
    const focusedItem =
      selectableItems.find(
        (item) => item.dataset['active'] || (item.dataset['selectable'] && item.dataset['selected']),
      ) || selectableItems[0];

    if (focusedItem) this.focusItem(focusedItem);
  }

  @HostListener('document:keydown.Enter', ['$event'])
  @HostListener('document:keydown.Tab', ['$event'])
  @HostListener('document:keydown.Shift.Tab', ['$event'])
  selectFocused(event: KeyboardEvent): void {
    if (this.hasChildDropdownActive$()) return;
    event.preventDefault();

    const focusedItem = this.getFocusedItem();
    if (focusedItem) focusedItem.click();
  }

  @HostListener('document:keydown.ArrowDown', ['$event'])
  focusNext(event: KeyboardEvent): void {
    if (this.hasChildDropdownActive$()) return;
    event.preventDefault();

    return this.focusSiblingItem('next');
  }

  @HostListener('document:keydown.ArrowUp', ['$event'])
  focusPrevious(event: KeyboardEvent): void {
    if (this.hasChildDropdownActive$()) return;
    event.preventDefault();

    return this.focusSiblingItem('prev');
  }

  focusSiblingItem(direction: 'next' | 'prev'): void {
    const selectableItems = this.getFocusableItems();
    if (selectableItems.length === 0) return;

    const focusedItemIndex = this.getFocusedItemIndex();
    if (focusedItemIndex === -1) return this.focusItem(selectableItems[0]);

    let nextFocusedItemIndex = -1;
    switch (direction) {
      case 'next':
        nextFocusedItemIndex = (focusedItemIndex + 1) % selectableItems.length;
        break;
      case 'prev':
        nextFocusedItemIndex = focusedItemIndex === 0 ? selectableItems.length - 1 : focusedItemIndex - 1;
        break;
    }

    this.focusItem(selectableItems[nextFocusedItemIndex]);
  }

  handleDropdownResize(event: ResizeEvent) {
    const { newRect, oldRect } = event;

    const previouslyVisible = oldRect?.width > 0 && oldRect?.height > 0;
    const nowVisible = newRect.width > 0 && newRect.height > 0;

    if (!previouslyVisible && nowVisible) this.captureFocus();
  }

  private focusItem(item: HTMLElement): void {
    if (!item) return;
    if (!this.fakeFocus) return item.focus();

    const isFirst = item === this.getFocusableItems()[0];
    isFirst ? this.scrollbarRef().scrollTo({ top: 0, duration: 0 }) : item.scrollIntoView({ block: 'nearest' });

    Array.from(this.document.querySelectorAll('.cc-fake-focused')).forEach((item) => {
      this.renderer.removeClass(item, 'cc-fake-focused');
      if (item instanceof HTMLElement) item.blur();
    });

    this.renderer.addClass(item, 'cc-fake-focused');
  }

  private getFocusedItemIndex(): number {
    const selectableItems = this.getFocusableItems();

    if (!this.fakeFocus) {
      const focusedElement = this.document.activeElement;
      return selectableItems.findIndex((item) => item === focusedElement);
    }

    return selectableItems.findIndex((item) => item.classList.contains('cc-fake-focused'));
  }

  private getFocusableItems(): HTMLElement[] {
    return Array.from(this.dropdownContent.nativeElement.querySelectorAll('.cc-focusable')) as HTMLElement[];
  }

  private getFocusedItem(): HTMLElement | null {
    const focusedItemIndex = this.getFocusedItemIndex();
    const selectableItems = this.getFocusableItems();

    return selectableItems[focusedItemIndex] || null;
  }

  private listenForFocusRequests(): void {
    let requestFocusSubscriptions = [];

    this.actions.changes
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        startWith(this.actions),
        map((actions: QueryList<DropdownActionComponent>) => {
          return actions ? actions.toArray() : [];
        }),
        tap((actionsList: DropdownActionComponent[]) => {
          requestFocusSubscriptions.forEach((subscription) => subscription.unsubscribe());
          requestFocusSubscriptions = [];

          actionsList.forEach((action) => {
            requestFocusSubscriptions.push(
              action.requestFocus.subscribe((itemToFocus) => {
                this.focusItem(itemToFocus);
              }),
            );
          });
        }),
      )
      .subscribe();
  }
}
